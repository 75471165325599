import React, { useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import ViewDayIcon from '@material-ui/icons/ViewDay';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import GridOnIcon from '@material-ui/icons/GridOn';

import { useDataSourceContext } from '@/components/DataSources';
import useDirectInsightsGrid from './useDirectInsightsGrid';
import DirectInsightsQuickSelection from './DirectInsightsQuickSelection';

import { useHasPermission } from '@/components/Permission';
import { EmptyStateHandleHasFeedback, PageHeader } from '@/components/App';
import { Loader, Grid, Alert } from '@/components/Layout';
import { DirectInsightsChartCard } from '@/components/Charts';
import { ActionButton, Typography } from '@/components/Layout';

import { findMatrixOrLikert } from './directInsightsUtils';

import { useSessionStorage } from '@/hooks';
import { useEFM } from '@/components/Ajax';

const breakpoints = { lg: 481, xs: 480 };
const cols = { lg: 12, xs: 3 };

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles(theme => ({
	close: {
		padding: theme.spacing(0.5),
	},
	gridLayout: {
		'& .react-grid-placeholder': {
			background: theme.palette.primary.main,
		},
	},
	fabIcon: {
		marginRight: theme.spacing(1),
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	iconMargin: {
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(1),
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: theme.zIndex.drawer + 1,
	},
	stickToAppBar: {
		position: 'absolute',
		width: '100%',
		marginLeft: theme.spacing(-3),
	},
	info: {
		marginBottom: theme.spacing(2),
	},
	filters: {
		marginBottom: theme.spacing(2),
	},
}));

function ChartsDateFilter({ periodFilter, setPeriodFilter = () => {} }) {
	const { t } = useTranslation();
	const classes = useStyles();

	const filterButtons = [
		{
			key: 'day',
			label: t`reporting-insights-chartfilter-day`,
			icon: <ViewDayIcon />,
		},
		{
			key: 'week',
			label: t`reporting-insights-chartfilter-week`,
			icon: <ViewWeekIcon />,
		},
		{
			key: 'month',
			label: t`reporting-insights-chartfilter-month`,
			icon: <GridOnIcon />,
		},
	];

	return (
		<Grid
			container
			className={classes.filters}
			direction="row"
			alignItems="center"
			spacing={2}
		>
			<Grid item>
				<Typography
					fontWeight="medium"
					variant="body2"
				>
					{t`reporting-insights-chartfilter-title`}
				</Typography>
			</Grid>
			{filterButtons.map((button, index) => {
				return (
					<Grid
						item
						key={index}
					>
						<ActionButton
							startIcon={button.icon}
							color={periodFilter === button.key ? 'primary' : 'default'}
							onClick={e => setPeriodFilter(button.key)}
							dataTrackEvent={`direct_insights_charts_data_filter_${button.key}`}
							disableTextTransform
						>
							{t(button.label)}
						</ActionButton>
					</Grid>
				);
			})}
		</Grid>
	);
}

export default function DirectInsightsGrid() {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const history = useHistory();
	const { datasource } = useDataSourceContext();
	const { datasourceId } = useParams();
	const hasEditPermission = useHasPermission();

	const [checkedSurvey] = useEFM(`/application/ajax/target-has-feedback`, {
		survey_id: datasourceId,
	});

	const [periodFilter, setPeriodFilter] = useSessionStorage(
		`filter-${datasourceId}`,
		'week'
	);

	const surveysLoading = datasource?.stale?.surveys?.loading ?? true;
	const selectedDatasource = datasource.api.getSource(datasourceId);
	const sourceFound = Object.values(selectedDatasource).length > 0;
	const surveyHasMatrixOrLikert = findMatrixOrLikert(
		datasource.api.getFieldArray(datasourceId)
	);

	useEffect(() => {
		if (!sourceFound && !surveysLoading) {
			history.push(`/reporting/direct-insights`);
		}
	}, [sourceFound, surveysLoading, history]);

	const layout = useDirectInsightsGrid({
		datasourceId: datasourceId,
	});

	const translatedSource =
		selectedDatasource?.survey_type === 'import'
			? t`reporting-insights-import`
			: t`reporting-insights-form`;

	return (
		<>
			<PageHeader
				documentTitle={selectedDatasource?.name}
				title={
					sourceFound ? (
						<DirectInsightsQuickSelection selectedDatasource={selectedDatasource} />
					) : (
						''
					)
				}
				backTo={{
					pathname: '/reporting/direct-insights',
					state: {
						from: 'list',
					},
				}}
				backTooltip={t`reporting-dashboard-pageheader-back_tooltip`}
			/>

			<ChartsDateFilter
				periodFilter={periodFilter}
				setPeriodFilter={setPeriodFilter}
			/>

			{surveysLoading && <Loader overlay />}
			{!surveysLoading && checkedSurvey?.hasFeedback === false && (
				<Alert
					severity="info"
					variant="filled"
					title={t`reporting-insights-alert-no_feedback-title`}
					className={classes.info}
				>
					{t`reporting-insights-alert-no_feedback-text`}
				</Alert>
			)}
			{!surveysLoading && surveyHasMatrixOrLikert && (
				<Alert
					severity="info"
					variant="filled"
					title={t`reporting-insights-alert-matrix_likert-title`}
					className={classes.info}
				>
					{t`reporting-insights-alert-matrix_likert-text`}
				</Alert>
			)}
			{!surveysLoading && layout.lg.length === 0 && (
				<EmptyStateHandleHasFeedback
					className={classes.emptyState}
					image={<img src={`/assets/img/r/emptystate/dashboard_no_charts.gif`} />}
					primary={t('reporting-insights-emptystate_default_grid-title', {
						source: translatedSource,
					})}
					secondary={t('reporting-insights-emptystate_default_grid-text', {
						source: translatedSource,
					})}
					action={
						<ActionButton
							variant="contained"
							color="primary"
							dataTrackEvent="dashboard_emptystate_create_form"
							disabled={!hasEditPermission}
							link
							to={
								selectedDatasource?.survey_type === 'import'
									? `/data-collection/dataset/import/${selectedDatasource?.id}`
									: `/data-collection/survey/edit/${selectedDatasource?.id}`
							}
						>
							{selectedDatasource?.survey_type === 'import'
								? t`reporting-insights-emptystate_default_grid-button-edit_dataset`
								: t`reporting-insights-emptystate_default_grid-button-edit_form`}
						</ActionButton>
					}
				/>
			)}
			{!surveysLoading && layout.lg.length > 0 && (
				<ResponsiveGridLayout
					className={classes.gridLayout}
					layouts={{ ...layout }}
					breakpoints={breakpoints}
					cols={cols}
					margin={[theme.spacing(3), theme.spacing(3)]}
					containerPadding={[0, 0]}
					isDraggable={false}
					isResizable={false}
					rowHeight={theme.spacing(8)}
					measureBeforeMount
					useCSSTransforms={false}
					isDroppable={false}
				>
					{layout.lg.map(chart => {
						return (
							<div key={chart.i}>
								<DirectInsightsChartCard
									chartId={chart.i}
									chartSettings={chart.chartSettings}
									periodFilter={periodFilter}
								/>
							</div>
						);
					})}
				</ResponsiveGridLayout>
			)}
		</>
	);
}
