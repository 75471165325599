import React from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import { surveyTypes } from '@/components/Survey';

import UpgradeButton from './UpgradeButton';

import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Typography,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	action: {
		height: '100%',
	},
	actionMargin: {
		margin: theme.spacing(1),
	},
}));

const UpsellCard = React.forwardRef(function UpsellCard(
	{ children, product, title, size },
	ref
) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Card ref={ref}>
			<CardHeader
				avatar={
					product && surveyTypes ? (
						<Avatar src={surveyTypes.find(type => type.type === product)?.img} />
					) : null
				}
				title={
					<Typography
						variant={size === 'small' ? 'subtitle2' : 'subtitle1'}
						fontWeight="medium"
					>
						{title}
					</Typography>
				}
				disableTypography
			/>
			<CardContent>{children}</CardContent>
			<CardActions className={classes.actionMargin}>
				<UpgradeButton
					size={size}
					mt={2}
					upgradeSubject={t(`add_to_package_explainer-subject_${product}`)}
					label={t`app_general-upgrade_overlay-button_contact`}
					data-track-event="upgrade_from_overlay"
				/>
			</CardActions>
		</Card>
	);
});

export default UpsellCard;
