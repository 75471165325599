import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { format, isValid, formatDistance } from '@/utils/date';

function isDateOnly(date = '') {
	return /^\d{2}-\d{2}-\d{4}$/.test(date) || /^\d{4}-\d{2}-\d{2}$/.test(date);
}

function parseDateString(date = '') {
	if (isDateOnly(date)) {
		//we need to force a time otherwise calling new Date
		//will convert the date with using the client timezone
		//which may add or substract a day from the actual date
		//in certain timezones
		return `${date}T12:00`;
	}
	//safari has an issue with date containing a space after the date if it contains a time
	return date.replace(/ /g, 'T');
}

function parseDateProp(date, isUnixTimestamp) {
	if (date instanceof Date) {
		return date;
	}

	date = typeof date === 'string' ? parseDateString(date) : date;

	if (isUnixTimestamp) {
		return Number(date) * 1000;
	}

	return date;
}

export default function FormattedDate({
	variant = 'date',
	date,
	withTime,
	unixTimestamp,
	...props
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();

	const dateObj = new Date(parseDateProp(date, unixTimestamp));

	if (!isValid(dateObj)) return t`Invalid date`;

	if (variant === 'distance') {
		return formatDistance(dateObj, new Date());
	}

	return format(dateObj, `${app.date.format}${withTime ? ' HH:mm' : ''}`);
}
