import React, { useState } from 'react';

import { makeStyles } from '@/styles';
import Box from './Box';
import Collapse from './Collapse';
import ActionButton from './ActionButton';

const useStyles = makeStyles(theme => ({
	button: {
		justifyContent: 'space-between',
		// fontWeight: theme.typography.body1.fontWeight,
		fontSize: theme.typography.body1.fontSize,
		marginLeft: '-6px',
		width: 'calc(100% + 12px)',
	},
}));

export default function CollapsibleBox({
	children,
	label,
	disabled,
	ActionButtonProps = {},
	startCollapsed = false,
	collapseButtonAfter = false,
	exposeCollapsed = () => {},
	...props
}) {
	const [collapsed, setCollapsed] = useState(startCollapsed);

	return (
		<Box
			width="100%"
			{...props}
		>
			{!collapseButtonAfter && (
				<CollapseButton
					collapsed={collapsed}
					disabled={disabled}
					label={label}
					ActionButtonProps={ActionButtonProps}
					setCollapsed={setCollapsed}
					exposeCollapsed={exposeCollapsed}
				/>
			)}
			<Collapse in={!collapsed}>{children}</Collapse>
			{collapseButtonAfter && (
				<CollapseButton
					collapsed={collapsed}
					disabled={disabled}
					label={label}
					ActionButtonProps={ActionButtonProps}
					setCollapsed={setCollapsed}
					exposeCollapsed={exposeCollapsed}
				/>
			)}
		</Box>
	);
}

function CollapseButton({
	collapsed,
	disabled,
	label,
	ActionButtonProps,
	setCollapsed,
	exposeCollapsed,
}) {
	const classes = useStyles();

	return (
		<ActionButton
			action={collapsed ? 'expand_more' : 'expand_less'}
			fullWidth
			onClick={() => {
				setCollapsed(prev => !prev);
				exposeCollapsed(!collapsed);
			}}
			color="inherit"
			disableTextTransform
			className={classes.button}
			disabled={disabled}
			{...ActionButtonProps}
		>
			{label}
		</ActionButton>
	);
}
