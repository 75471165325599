import React, { Component } from 'react';
import { FeedbackDetails, useGetFeedbackDetails } from '@/components/Feedback';
import { useParams } from 'react-router-dom';
export default function FeedbackDetailPage() {
	const { feedbackId } = useParams();
	const [data = {}, loading, error, loadData] = useGetFeedbackDetails(feedbackId, true);
	return (
		<FeedbackDetails
			loading={loading}
			feedback_id={feedbackId}
			details={data}
			loadData={loadData}
			isPage
		/>
	);
}
