import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ActivityIcon from '@material-ui/icons/FiberManualRecord';

import { useSnackbar } from 'notistack';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import { ContainerQuery } from 'react-container-query';

import { useAppContext } from '@/components/AppContext';
import { AppBarActions, PageHeader } from '@/components/App';
import {
	LayoutWithSidebar,
	constants,
	EmptyState,
	Loader,
	EditableContent,
	ActionIconButton,
	ActionButton,
	Alert,
	Box,
	TextField,
	MenuItem,
	Grid,
	Typography,
	Link,
	SplitButton,
} from '@/components/Layout';
import { useMjolnir, useEFM } from '@/components/Ajax';
import { ProductNotInPlanDialog } from '@/components/Billing';
import { useOnboardingContext } from '@/components/Onboarding';
import { useTimeTravel } from '@/hooks';
import { tryParse } from '@/utils';
import { DeploymentExplainer } from '@/components/Explainer';
import useSaveDeployment from './useSaveDeployment';
import {
	useHasPermission,
	permissionSubjects,
	useHandleNoAccessRedirect,
} from '@/components/Permission';

import { DeploymentEditorProvider } from './DeploymentEditorContext';
import DeploymentEditorSidebarContent from './DeploymentEditorSidebarContent';
import DeploymentEditorItem from './DeploymentEditorItem';
import DeploymentCodeDialog from './DeploymentCodeDialog';
import DeploymentAssistantDialog from './DeploymentAssistantDialog';
import { ruleBase, conditionBase, innerConditionBase } from './deploymentUtils';
import DeploymentPublishDialog from './DeploymentPublishDialog';

const useStyles = makeStyles(theme => ({
	deploymentAreaWrap: {
		minWidth: '95%',
		maxWidth: '95%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '7.5%',
		paddingRight: '7.5%',
	},
	deploymentAreaWrapSm: {
		paddingLeft: '5%',
		paddingRight: '5%',
	},
	deploymentAreaWrapXs: {
		paddingLeft: '0',
		paddingRight: '0',
	},
	deploymentArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	spaceChildren: {
		display: 'flex',
		'& > *': {
			marginRight: theme.spacing(0.5),
		},
	},
	textAlignRight: {
		textAlign: 'right',
	},
}));

function reducer(state, action) {
	switch (action.type) {
		case 'init_deployment':
			Object.assign(state, action.payload);
			return;

		case 'add_rule':
			const newRuleDestinationIndex =
				typeof action.payload.destinationIndex === 'number'
					? action.payload.destinationIndex
					: state.rules.length;
			state.rules.splice(newRuleDestinationIndex, 0, action.payload.newRule);
			return;

		case 'move_rule':
			state.rules.splice(
				action.payload.destinationIndex,
				0,
				state.rules.splice(action.payload.sourceIndex, 1)[0]
			);
			return;

		case 'delete_rule':
			state.rules.splice(action.payload.index, 1);
			return;

		case 'set_rule_option':
			set(state.rules, action.payload.path, action.payload.value);
			return;

		case 'unset_rule_option':
			unset(state.rules, action.payload.path);
			return;

		case 'add_to_rule_array':
			get(state.rules, action.payload.path).push(action.payload.value);
			return;

		case 'remove_from_rule_array':
			get(state.rules, action.payload.path).splice(action.payload.index, 1);
			return;

		case 'add_condition':
			state.rules[action.payload.ruleIndex].if.push(
				conditionBase(action.payload.editorType)
			);
			return;

		case 'delete_condition':
			state.rules[action.payload.ruleIndex].if.splice(action.payload.conditionIndex, 1);
			return;

		case 'update_name':
			state.name = action.payload.value;
			return;

		case 'set_published':
			state.published = Boolean(action.payload);
			return;

		default:
			return;
	}
}

function simpleReducer(state, action) {
	switch (action.type) {
		case 'set_selected':
			state.selectedRule = action.payload.id;
			state.activeTab = 1;
			return;

		case 'set_tab':
			state.activeTab = action.payload;
			return;

		case 'set_survey_map':
			state.surveyMapBySurveyKey = action.payload.surveys.reduce(
				(objectBySurveyKey, currentProject) => {
					[...currentProject.web_surveys, ...currentProject.sdk_surveys].forEach(
						survey => {
							objectBySurveyKey[survey.survey_key] = survey;
						}
					);

					return objectBySurveyKey;
				},
				{}
			);
			return;

		case 'set_edit_name':
			state.editName = action.payload;
			return;

		case 'set_code_dialog':
			state.codeDialogOpen = action.payload;
			return;

		case 'set_assistant_dialog':
			state.assistantDialogOpen = action.payload;
			return;

		case 'set_publish_dialog':
			state.publishDialogOpen = action.payload;
			return;

		default:
			return;
	}
}

export default function DeploymentEditor({
	AppBarActionsContainer,
	deploymentId: deploymentIdFromProps,
	editorType: editorTypeFromProps,
	type,
	inDrawer,
	...props
}) {
	const { app } = useAppContext();
	const { deploymentId: deploymentIdFromUrl, editorType: editorTypeFromUrl } =
		useParams();
	const history = useHistory();
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.deployments,
	});
	const deploymentId = deploymentIdFromProps ?? deploymentIdFromUrl;
	const editorType = editorTypeFromProps ?? editorTypeFromUrl;

	const { t } = useTranslation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();

	const [data, loading] = useMjolnir(
		`/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${deploymentId}`
	);

	useHandleNoAccessRedirect({
		groups: data?.deployment?.permission_groups,
		to: '/data-collection/deployment',
	});

	useEffect(() => {
		if (
			!loading &&
			data?.deployment?.type &&
			data?.deployment?.type !== editorTypeFromUrl &&
			!editorTypeFromProps
		) {
			history.push(
				`/data-collection/deployment/edit/${deploymentId}/${data.deployment.type}`
			);
		}
	}, [data, loading, editorTypeFromProps, editorTypeFromUrl, deploymentId, history]);
	const [allSurveysPerProject, loadingSurveys] = useEFM(
		'/survey/ajax/get-surveys-from-organisation'
	);
	const { state, dispatch, timeline, doUndo, doRedo } = useTimeTravel(reducer, {
		rules: [],
	});
	const [showProductNotAllowedDialog, setShowProductNotAllowedDialog] = useState(false);

	useEffect(() => {
		if (data.deployment) {
			//we want to map old arrays with stringvalues to array with objects
			const deployment = produce(data.deployment, draft => {
				if (draft.rules) {
					draft.rules.forEach((rule, ruleIndex) => {
						rule.if.forEach((ruleIf, ruleIfIndex) => {
							if (ruleIf.referrer?.length) {
								ruleIf.referrer.forEach((referrer, referrerIndex) => {
									if (typeof referrer === 'string') {
										const newBase = innerConditionBase('referrer');
										newBase.value = referrer.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
										draft.rules[ruleIndex].if[ruleIfIndex].referrer[referrerIndex] =
											newBase;
									}
								});
							}

							//check if deployment contains old setups with location, cookies, js vars and html content
							//if so normalize them to the current setup
							[
								{ type: 'location', value: ruleIf.location },
								{ type: 'cookie', value: ruleIf.cookie },
								{ type: 'js', value: ruleIf.js },
								{ type: 'css_selector', value: ruleIf.css_selector },
							].forEach(item => {
								const { value = [], type } = item;

								//if locations are defined and the first entry is not an array ->
								//it must be an old setup where the first entry is an object -> check to make sure
								//if so map array of objects to array of nested arrays with objects (OR to AND | OR)
								if (value.length > 0 && !Array.isArray(value[0]) && value[0]?.value) {
									draft.rules[ruleIndex].if[ruleIfIndex][type] = value.map(obj => {
										const base = innerConditionBase(type);
										return [
											{
												...base,
												...obj,
											},
										];
									});
								}
							});
						});
					});
				}
			});

			dispatch({
				type: 'init_deployment',
				timelineProps: {
					skipTimeline: true,
				},
				payload: deployment,
			});
		}
	}, [JSON.stringify(data)]);

	useEffect(() => {
		if (editorType) {
			setShowProductNotAllowedDialog(!app.api.packageProductAllowed(editorType));
		}
	}, [editorType, app]);

	const [simpleState, dispatchSimple] = useImmerReducer(simpleReducer, {
		selectedRule: null,
		activeTab: 0,
		surveyMapBySurveyKey: {},
		editorType,
		codeDialogOpen: false,
	});

	useEffect(() => {
		if (Array.isArray(allSurveysPerProject.surveys)) {
			dispatchSimple({
				type: 'set_survey_map',
				payload: allSurveysPerProject,
			});
		}
	}, [allSurveysPerProject]);

	const onboarding = useOnboardingContext();

	function onDragEnd(result) {
		const { source, destination, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === 'deployment-area') {
			if (source.droppableId === destination.droppableId) {
				dispatch({
					type: 'move_rule',
					payload: {
						destinationIndex: destination.index,
						sourceIndex: source.index,
					},
				});
			} else if (source.droppableId !== destination.droppableId) {
				const survey = simpleState.surveyMapBySurveyKey[draggableId];
				const advanced = tryParse(survey.advanced, {});
				const isSdk = survey.campaign === 'sdk' || advanced.sdk_survey;

				const newRule = ruleBase({
					surveyKey: survey.survey_key,
					domain: app.domain,
					editorType,
					surveyType: isSdk ? 'sdk' : 'web',
					surveyFormat: survey.survey_format ?? 'form',
				});

				dispatch({
					type: 'add_rule',
					payload: {
						newRule: newRule,
						destinationIndex: destination.index,
					},
				});

				if (app.session.onboarding_trial) {
					try {
						selectRule(newRule.id);
					} catch (e) {}
				}
			}
		}
	}

	function selectRule(id, index) {
		dispatchSimple({
			type: 'set_selected',
			payload: {
				id: simpleState.selectedRule !== id ? id : null,
			},
		});
	}

	const { postForm, loading: saveDeploymentLoading } = useSaveDeployment({
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${deploymentId}`,
		data: state,
		deploymentId: deploymentId,
		onSuccess: response => {
			if (response.code !== 200) {
				enqueueSnackbar(t`data_collection-deployments-snackbar-save_error`);
				return;
			}
			dispatchSimple({
				type: 'set_publish_dialog',
				payload: false,
			});
			enqueueSnackbar(t`data_collection-deployments-snackbar-saved_and_deployed`);
			if (app.session.onboarding_trial) {
				onboarding.dispatch({
					type: 'set_trial_step',
					payload: {
						url: `/data-collection/deployment/list`,
						from: location.pathname,
					},
				});
			}
		},
	});

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<DeploymentEditorProvider
				value={{
					state: {
						deployment: { ...state },
						surveysByProject: allSurveysPerProject?.surveys ?? [],
						...simpleState,
						loadingSurveys,
					},
					api: {
						dispatch,
						dispatchSimple,
					},
				}}
			>
				{!inDrawer && (
					<PageHeader
						title={
							<>
								{t`Edit deployment`}
								{' - '}
								<EditableContent
									value={state.name}
									display="inline-flex"
									onChange={value =>
										dispatch({
											type: 'update_name',
											payload: {
												value: value,
											},
										})
									}
									editable={hasEditPermission}
									showIconAlways
								>
									{state.name}
								</EditableContent>
							</>
						}
						documentTitle={`${t`Edit deployment`} ${state.name}`}
						backTo={!app.session.onboarding_trial ? '/data-collection/deployment' : null}
						backTooltip={t`To deployments`}
					/>
				)}
				<AppBarActions container={AppBarActionsContainer}>
					{!app.session.onboarding_trial && (
						<>
							<ActionIconButton
								onClick={doUndo}
								action="undo"
								disabled={timeline.past.length === 0}
								tooltip={t`Undo`}
								dataTrackEvent="deployment_editor-undo_button"
							/>

							<ActionIconButton
								onClick={doRedo}
								action="redo"
								disabled={timeline.future.length === 0}
								tooltip={t`Redo`}
								dataTrackEvent="deployment_editor-redo_button"
							/>
						</>
					)}

					<>
						<Box textAlign="center">
							<Box
								className={classes.spaceChildren}
								data-onboarding="deploy-buttons"
								mb={0.25}
							>
								{editorType === 'web' && !app.session.onboarding_trial && (
									<ActionButton
										action="assistant"
										tooltip={t`data_collection-deployment_editor-assistant_button-tooltip`}
										onClick={e =>
											dispatchSimple({
												type: 'set_assistant_dialog',
												payload: true,
											})
										}
										dataTrackEvent="deployment_editor_open_deployment_assistant_dialog"
									>
										{t`data_collection-deployment_editor-assistant_button-label`}
									</ActionButton>
								)}
								<SplitButton
									action="publish"
									className={classes.saveButton}
									loading={saveDeploymentLoading}
									disabled={
										!hasEditPermission ||
										(app.session.onboarding_trial && state.rules.length === 0)
									}
									data-test-element="deployment-editor-save-button"
									data-track-event={
										app.session.onboarding_trial
											? 'onboarding_trial_step_deployment_save_deployment'
											: 'deployment-editor-save-button'
									}
									options={
										app.session.onboarding_trial
											? [
													{
														label: t`data_collection-deployment-editor-header_trial-button_save`,
														onClick: postForm,
													},
											  ]
											: [
													{
														label: t`data_collection-deployment_editor-publish_button`,
														onClick: () =>
															dispatchSimple({
																type: 'set_publish_dialog',
																payload: true,
															}),
													},
													{
														label: t`data_collection-deployment_editor-get_code-label`,
														dataTrackEvent: 'deployment_editor-get_code_button',
														onClick: () => {
															dispatchSimple({
																type: 'set_code_dialog',
																payload: true,
															});
														},
													},
											  ]
									}
								/>
							</Box>
						</Box>
						<Popover
							open={Boolean(simpleState.saveMenuAnchor)}
							anchorEl={simpleState.saveMenuAnchor}
							onClose={() =>
								dispatchSimple({
									type: 'set_save_menu_anchor',
									payload: null,
								})
							}
						>
							<Box p={2}>
								<Typography variant="h6">{t`Publish deployment`}</Typography>
								<Typography
									variant="subtitle1"
									color="textSecondary"
								>{t`Saving your deployment means publishing your changes to your live website.`}</Typography>

								<Box mt={2}>
									<Button
										variant="contained"
										color="secondary"
										onClick={() => {
											postForm();
											dispatchSimple({
												type: 'set_save_menu_anchor',
												payload: null,
											});
										}}
										data-test-element="deployment-editor-publish-button"
										data-track-event={
											editorType === 'sdk'
												? 'deployment_in_app_saved'
												: 'deployment_web_saved'
										}
									>
										{t`Save and publish`}
									</Button>
									<Button
										onClick={() =>
											dispatchSimple({
												type: 'set_save_menu_anchor',
												payload: null,
											})
										}
									>
										{t`Cancel`}
									</Button>
								</Box>
							</Box>
						</Popover>
					</>
				</AppBarActions>

				<ContainerQuery
					query={{
						sm: { maxWidth: 900 },
					}}
				>
					{topMatches => (
						<LayoutWithSidebar
							editor
							drawerOpen
							persistentDrawer
							drawerWidth={
								topMatches.sm
									? constants.deploymentEditorDrawerWidthSm
									: constants.deploymentEditorDrawerWidth
							}
							drawerContent={<DeploymentEditorSidebarContent />}
							DrawerProps={{
								'data-onboarding': 'deployment-sidebar',
							}}
						>
							<ContainerQuery
								query={{
									xs: { maxWidth: 400 },
									sm: { maxWidth: 600 },
								}}
							>
								{matches => (
									<div
										data-onboarding="build-area"
										data-test-element="deployment-editor-build-area"
										className={`${classes.deploymentAreaWrap} ${
											matches.sm ? classes.deploymentAreaWrapSm : ''
										} ${matches.xs ? classes.deploymentAreaWrapXs : ''}`}
									>
										<Box mb={1}>
											<Grid
												container
												direction="row"
												alignItems="center"
											>
												<Grid
													item
													xs={12}
													className={classes.textAlignRight}
												>
													{app.session.onboarding_trial && <DeploymentExplainer />}
												</Grid>

												{!app.session.onboarding_trial && (
													<>
														<Grid
															item
															xs
														>
															<Alert variant="default">
																{editorType === 'sdk'
																	? t`The forms in your deployment will be initialised in your app if one of their condition sets match when it's event is fired.`
																	: t`The forms in your deployment will be initialised on your website if one of their condition sets match.`}{' '}
																{t`Change the status to quickly disable the entire deployment.`}
															</Alert>
														</Grid>
														<Grid item>
															<TextField
																label={t`Status`}
																value={state.published ? 'active' : 'inactive'}
																select
																size="small"
																onChange={e => {
																	dispatch({
																		type: 'set_published',
																		payload: e.target.value === 'active',
																	});
																	enqueueSnackbar(
																		`${t`Deployment set to`} ${
																			e.target.value === 'active'
																				? t`active`
																				: t`inactive`
																		}. ${t`Save your deployment to publish the changes`}.`
																	);
																}}
																disabled={!hasEditPermission}
																SelectProps={{
																	classes: {
																		root: classes.selectRow,
																	},
																}}
																data-onboarding="deploy-status"
															>
																<MenuItem value="active">
																	<ListItemIcon>
																		<ActivityIcon color="secondary" />
																	</ListItemIcon>
																	<ListItemText primary={t`Active`} />
																</MenuItem>
																<MenuItem value="inactive">
																	<ListItemIcon>
																		<ActivityIcon color="error" />
																	</ListItemIcon>
																	<ListItemText primary={t`Inactive`} />
																</MenuItem>
															</TextField>
														</Grid>
													</>
												)}
											</Grid>
										</Box>

										<Droppable droppableId="deployment-area">
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
													className={`${classes.deploymentArea} ${
														matches.small ? classes.deploymentAreaSmall : ''
													} ${state.rules.length === 0 ? classes.centerContent : ''}`}
												>
													{state.rules.length === 0 &&
														!snapshot.isDragging &&
														!snapshot.isDraggingOver &&
														!loading && (
															<Box
																height="100%"
																width="100%"
																position="absolute"
																top={0}
																left={0}
																right={0}
																bottom={0}
															>
																<EmptyState
																	primary={t`data_collection-deployments-emptystate_detail-title`}
																	secondary={t`data_collection-deployments-emptystate_detail-text`}
																/>
															</Box>
														)}
													{loading && <Loader empty={state.rules.length === 0} />}
													{state.rules.map((rule, index) => {
														const form =
															simpleState.surveyMapBySurveyKey[rule.then[0].args[0]];

														return (
															<DeploymentEditorItem
																onClick={e => selectRule(rule.id, index)}
																key={rule.id}
																form={form}
																rule={rule}
																index={index}
																loading={loading || loadingSurveys}
																selected={simpleState.selectedRule === rule.id}
																onDelete={() => {
																	dispatch({
																		type: 'delete_rule',
																		payload: {
																			index,
																			ruleId: rule.id,
																		},
																	});
																	enqueueSnackbar(
																		`${
																			form ? form.name : t`(Deleted form)`
																		} ${t`removed from deployment`}`
																	);
																}}
															/>
														);
													})}

													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</div>
								)}
							</ContainerQuery>
						</LayoutWithSidebar>
					)}
				</ContainerQuery>
				<DeploymentCodeDialog
					id={deploymentId}
					variant={editorType}
					open={simpleState.codeDialogOpen}
					onClose={() =>
						dispatchSimple({
							type: 'set_code_dialog',
							payload: false,
						})
					}
				/>

				<DeploymentPublishDialog
					open={simpleState.publishDialogOpen}
					loading={saveDeploymentLoading}
					editorType={editorType}
					onClose={() =>
						dispatchSimple({
							type: 'set_publish_dialog',
							payload: false,
						})
					}
					onSubmit={postForm}
				/>

				<DeploymentAssistantDialog
					name={data.deployment?.name}
					token={data.deployment?.token}
					open={simpleState.assistantDialogOpen}
					onClose={() =>
						dispatchSimple({
							type: 'set_assistant_dialog',
							payload: false,
						})
					}
				/>

				<ProductNotInPlanDialog
					product={editorType}
					open={showProductNotAllowedDialog}
					backTo="/data-collection/deployment"
				/>
			</DeploymentEditorProvider>
		</DragDropContext>
	);
}
