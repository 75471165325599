import React, { useMemo, useEffect } from 'react';

import { useDataSourceContext, DataSourceAvatar } from '../DataSources';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListSubheader from '@material-ui/core/ListSubheader';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	empty: {
		color: theme.palette.text.hint,
	},
	subheader: {
		backgroundColor: '#fff',
	},
}));

export default function DataSourceSelect({
	children,
	label,
	emptyLabel,
	renderValueTextOnly,
	withAllData,
	dataTestElement = null,
	...props
}) {
	const { t } = useTranslation();
	const { datasource } = useDataSourceContext();
	const classes = useStyles();

	useEffect(() => {
		datasource.api.loadSurveys();
	}, []);

	const options = useMemo(() => {
		return [
			{
				label: t`app_general-datasource_select-label_forms`,
				arr: datasource.forms.asArray,
			},
			{
				label: t`app_general-datasource_select-label_datasets`,
				arr: datasource.datasets.asArray,
			},
			{
				label: t`app_general-datasource_select-label_integrations`,
				arr: datasource.integrations.asArray,
			},
		].map(dataSourceType => {
			return dataSourceType.arr.length > 0
				? [
						<ListSubheader className={classes.subheader}>
							{dataSourceType.label}
						</ListSubheader>,
						...dataSourceType.arr.map(dataSourceItem => {
							return (
								<MenuItem
									value={dataSourceItem.id}
									data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
								>
									<ListItemAvatar>
										<DataSourceAvatar {...dataSourceItem} />
									</ListItemAvatar>
									<ListItemText
										primary={dataSourceItem.name}
										secondary={dataSourceItem.description}
									/>
								</MenuItem>
							);
						}),
				  ]
				: [];
		});
	}, [
		JSON.stringify(datasource.forms.byKey),
		JSON.stringify(datasource.datasets.byKey),
		JSON.stringify(datasource.integrations.byKey),
	]);

	return (
		<FormControl fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select
				classes={{ select: classes.select }}
				data-test-element={dataTestElement}
				{...(renderValueTextOnly && {
					renderValue: value =>
						Array.isArray(value)
							? value.map(v => datasource.api.getField(v).system_var).join(', ')
							: datasource.api.getField(value).system_var,
				})}
				displayEmpty={Boolean(emptyLabel)}
				{...props}
			>
				{emptyLabel && (
					<MenuItem
						value=""
						data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
					>
						<ListItemText
							primary={emptyLabel}
							// primaryTypographyProps={{
							// 	className:classes.empty
							// }}
						/>
					</MenuItem>
				)}
				{withAllData && (
					<MenuItem
						value={'ALL_DATA'}
						data-test-element={dataTestElement ? dataTestElement + 'Option' : null}
					>
						<ListItemAvatar>
							<DataSourceAvatar allData />
						</ListItemAvatar>
						<ListItemText
							primary={t`app_general-datasource_select-all_data_primary`}
							secondary={t`app_general-datasource_select-all_data_secondary`}
						/>
					</MenuItem>
				)}
				{options}
			</Select>
			{props.helperText && (
				<FormHelperText error={props.error}>{props.helperText}</FormHelperText>
			)}
		</FormControl>
	);
}
