import React, { useReducer } from 'react';
import produce from 'immer';

const UNDO = Symbol('UNDO');
const REDO = Symbol('REDO');
const RESET = Symbol('RESET');
const RESET_TO = Symbol('RESET_TO');
const REMOVE_UNDO = Symbol('REMOVE_UNDO');
const ASYNC_INITIAL = Symbol('ASYNC_INITIAL');

export default function useTimeTravel(reducer, initialState) {
	const timeline = {
		past: [],
		present: initialState,
		future: [],
	};
	const proxiedReducer = (tl, action) => {
		const { proxiedAction, proxiedPayload } = action;

		if (proxiedAction === UNDO) return _doUndo(tl);
		if (proxiedAction === REDO) return _doRedo(tl);
		if (proxiedAction === RESET) return _doReset(tl);
		if (proxiedAction === RESET_TO) return _doResetTo(tl, proxiedPayload);
		if (proxiedAction === REMOVE_UNDO) return _doRemoveUndo(tl);
		if (proxiedAction === ASYNC_INITIAL) return _setAsyncInitial(tl, proxiedPayload);
		// else
		const newState = produce(tl.present, draft => reducer(draft, action));
		if (action.timelineProps && action.timelineProps.skipTimeline) {
			return _applyNewPresentWithOutTimeTravel(tl, newState);
		}
		return _addNewPresent(tl, newState);
	};
	const [_timeline, _dispatch] = useReducer(proxiedReducer, timeline);
	return {
		state: _timeline.present,
		timeline: _timeline,
		dispatch: _dispatch,
		doUndo: () => _dispatch({ proxiedAction: UNDO }),
		doRedo: () => _dispatch({ proxiedAction: REDO }),
		doReset: () => _dispatch({ proxiedAction: RESET }),
		doResetTo: resetToStep =>
			_dispatch({ proxiedAction: RESET_TO, proxiedPayload: resetToStep }),
		doRemoveUndo: () => _dispatch({ proxiedAction: REMOVE_UNDO }),
		setAsyncInitial: asyncInitialPresent =>
			_dispatch({ proxiedAction: ASYNC_INITIAL, proxiedPayload: asyncInitialPresent }),
	};
}

function _addNewPresent(timeline, newPresent) {
	return produce(timeline, draft => {
		draft.past.push(draft.present);
		draft.present = newPresent;
		draft.future = [];
	});
}

function _applyNewPresentWithOutTimeTravel(timeline, newPresent) {
	return produce(timeline, draft => {
		draft.present = newPresent;
	});
}

function _doUndo(timeline) {
	return produce(timeline, draft => {
		if (!draft.past.length) return;
		const newPresent = draft.past.pop();
		draft.future.unshift(draft.present);
		draft.present = newPresent;
	});
}
function _doRedo(timeline) {
	return produce(timeline, draft => {
		if (!draft.future.length) return;
		const newPresent = draft.future.shift();
		draft.past.push(draft.present);
		draft.present = newPresent;
	});
}

function _doReset(timeline) {
	return produce(timeline, draft => {
		if (!draft.past.length) return;
		const newPresent = draft.past.shift();
		draft.future = [...draft.past, draft.present, ...draft.future];
		draft.present = newPresent;
		draft.past = [];
	});
}

function _doResetTo(timeline, { index = 0 }) {
	return produce(timeline, draft => {
		if (index >= draft.past.length || draft.past.length === 0) return;
		const newPresent = draft.past[index];
		draft.future = [];
		draft.present = newPresent;
		draft.past.splice(index, draft.past.length);
		//draft.past = [...draft.past.splice(0, index)];
	});
}

function _doRemoveUndo(timeline) {
	return produce(timeline, draft => {
		if (!draft.past.length) return;
		draft.past.pop();
	});
}

function _setAsyncInitial(timeline, newPresent) {
	return produce(timeline, draft => {
		draft.past = [];
		draft.present = newPresent;
		draft.future = [];
	});
}
