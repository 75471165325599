import React, { useState, Fragment, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '@/components/AppContext';
import {
	SystemElementCardBase,
	AjaxFormDialog,
	ActionButton,
	ActionIconButton,
	Grid,
	Chip,
	Box,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from '@/components/Layout';
import { useOnboardingContext } from '@/components/Onboarding';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionSubjects,
	useIsInPermissionGroup,
} from '@/components/Permission';
import { DeleteIcon, AccessGroupIcon, DeploymentAssistantIcon } from '@/components/Icons';

import DeploymentCodeDialog from './DeploymentCodeDialog';
import DeploymentEditorDrawer from './DeploymentEditorDrawer';
import DeploymentCode from './DeploymentCode';
import DeploymentAccessDialog from './DeploymentAccessDialog';
import DeploymentAssistantDialog from './DeploymentAssistantDialog';

export default function DeploymentCard({
	name,
	type = 'web',
	published,
	rules = [],
	permission_groups = [],
	id,
	token,
	editInDrawer,
	loadDeployments = () => {},
	showCode,
	...props
}) {
	const { app } = useAppContext();
	const onboarding = useOnboardingContext();

	const hasAccessPermission = useIsInPermissionGroup(permission_groups);
	const hasEditPermission = useHasPermission({
		subject: permissionSubjects.deployments,
	});
	const hasEditAccessPermission = hasAccessPermission && hasEditPermission;

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [codeDialogOpen, setCodeDialogOpen] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [deploymentAccessOpen, setDeploymentAccessOpen] = useState(false);
	const [deploymentAssistantOpen, setDeploymentAssistantOpen] = useState(false);

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const editDeployment = () => {
		if (editInDrawer) {
			setDrawerOpen(true);
		} else {
			if (app.session.onboarding_trial) {
				onboarding.dispatch({
					type: 'set_trial_step',
					payload: {
						url: `/data-collection/deployment/edit/${id}${
							type === 'sdk' ? '/sdk' : '/web'
						}`,
					},
				});
			}
			history.push(
				`/data-collection/deployment/edit/${id}${type === 'sdk' ? '/sdk' : '/web'}`
			);
		}
	};
	const isOn = () => typeof published === 'undefined' || published;

	if (deleted) return null;

	return (
		<Fragment>
			{!deleted && (
				<SystemElementCardBase
					img={`/assets/img/r/deployment_${type === 'sdk' ? 'sdk' : 'web'}.svg`}
					title={name}
					info={
						props.size !== 'small'
							? [
									{
										label: t`data_collection-deployment_list-deployment_card-type_label`,
										value: t(
											`data_collection-deployment_list-deployment_card-type_${type}`
										),
									},
									{
										label: t`data_collection-deployment_list-deployment_card-status_label`,
										value: (
											<Chip
												size="small"
												label={t(
													`data_collection-deployment_list-deployment_card-status_${
														isOn() ? 'on' : 'off'
													}`
												)}
											/>
										),
									},
									{
										label: t`data_collection-deployment_list-deployment_card-forms_in_deployment`,
										value: rules.length,
									},
							  ]
							: null
					}
					actions={
						<Fragment>
							<Grid
								container
								direction={props.size === 'small' ? 'column' : 'row'}
							>
								<Grid
									item
									xs
								>
									<NoPermissionTooltip restricedPermission={!hasAccessPermission}>
										{!app.session.onboarding_trial && (
											<ActionIconButton
												disabled={!hasAccessPermission}
												data-test-element="deployment-card-edit-button"
												onClick={editDeployment}
												action={hasEditPermission ? 'edit' : 'view'}
												tooltip={
													hasEditPermission
														? t`data_collection-deployment_list-deployment_card-tooltip_edit`
														: t`data_collection-deployment_list-deployment_card-tooltip_view`
												}
												data-onboarding="edit-deployment"
											/>
										)}
										{app.session.onboarding_trial && (
											<ActionButton
												variant="contained"
												color="primary"
												size="small"
												onClick={editDeployment}
												data-onboarding="edit-deployment"
												data-test-element="edit_deployment_button"
											>
												{t`data_collection-deployment_list-deployment_card-edit`}
											</ActionButton>
										)}
									</NoPermissionTooltip>
									{!showCode && (
										<ActionIconButton
											onClick={() => setCodeDialogOpen(true)}
											action="code"
											tooltip={t`data_collection-deployment_list-deployment_card-tooltip_get_code`}
											data-onboarding="show-deployment-code"
											data-track-event="deployment_card_open_deployment_code_dialog"
										/>
									)}
								</Grid>
								{props.size !== 'small' && !showCode && !app.session.onboarding_trial && (
									<Grid item>
										<NoPermissionTooltip>
											<ActionIconButton
												onClick={e => setMenuAnchor(e.target)}
												action="more"
												tooltip={t`data_collection-deployment_list-deployment_card-tooltip_options`}
												data-test-element="deployment-card-more-button"
											/>
										</NoPermissionTooltip>

										<Menu
											open={Boolean(menuAnchor)}
											anchorEl={menuAnchor}
											onClose={() => setMenuAnchor(null)}
										>
											{type === 'web' && (
												<MenuItem
													onClick={() => setDeploymentAssistantOpen(true)}
													data-track-event="deployment_card_open_deployment_assistant_dialog"
												>
													<ListItemIcon>
														<DeploymentAssistantIcon />
													</ListItemIcon>
													<ListItemText
														primary={t`data_collection-deployment_card-menu-deployment_assistant`}
													/>
												</MenuItem>
											)}
											<MenuItem
												onClick={() => setDeploymentAccessOpen(true)}
												disabled={!hasEditAccessPermission}
											>
												<ListItemIcon>
													<AccessGroupIcon />
												</ListItemIcon>
												<ListItemText
													primary={t`data_collection-deployment_card-menu-manage_access`}
												/>
											</MenuItem>
											<MenuItem
												onClick={() => setDeleteDialogOpen(true)}
												dataTrackEvent={
													type === 'sdk'
														? 'deployment_in_app_deleted'
														: 'deployment_web_deleted'
												}
												disabled={
													app.session.onboarding_trial || !hasEditAccessPermission
												}
												data-test-element="deployment-card-delete-button"
											>
												<ListItemIcon>
													<DeleteIcon />
												</ListItemIcon>
												<ListItemText
													primary={t`data_collection-deployment_card-menu-delete`}
												/>
											</MenuItem>
										</Menu>
									</Grid>
								)}
							</Grid>
						</Fragment>
					}
					alignItems={showCode ? 'top' : null}
					{...props}
				>
					{showCode && (
						<Box
							mt={2}
							data-onboarding="deploy-wrap"
						>
							<DeploymentCode
								id={id}
								variant={type}
							/>
						</Box>
					)}
				</SystemElementCardBase>
			)}
			<DeploymentCodeDialog
				variant={type}
				open={codeDialogOpen}
				onClose={() => setCodeDialogOpen(false)}
				id={id}
			/>
			<AjaxFormDialog
				AjaxFormProps={{
					url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${id}`,
					method: 'delete',
					type: 'mjolnir',
					onSuccess: r => {
						if (r.code == 200) {
							loadDeployments();
							setDeleted(true);
							enqueueSnackbar(
								t(
									'data_collection-deployment_list-deployment_card-delete_deployment_snackbar',
									{ name: name }
								)
							);
						}
					},
				}}
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
				dataTestElement="deployment-delete-dialog-submit-button"
				title={t`data_collection-deployment_list-deployment_card-delete_deployment_title`}
				text={t(
					'data_collection-deployment_list-deployment_card-delete_deployment_text',
					{ name: name }
				)}
			/>

			{editInDrawer && (
				<DeploymentEditorDrawer
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					deploymentId={id}
					editorType={type === 'sdk' ? 'sdk' : 'web'}
				/>
			)}

			<DeploymentAccessDialog
				open={deploymentAccessOpen}
				onClose={() => {
					setDeploymentAccessOpen(false);
					setMenuAnchor(null);
					loadDeployments();
				}}
				id={id}
				name={name}
				permissionGroups={permission_groups}
			/>

			<DeploymentAssistantDialog
				open={deploymentAssistantOpen}
				onClose={() => {
					setDeploymentAssistantOpen(false);
					setMenuAnchor(null);
				}}
				id={id}
				name={name}
				token={token}
			/>
		</Fragment>
	);
}
