import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Divider from '@material-ui/core/Divider';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import Slide from '@material-ui/core/Slide';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { ActionButton, ActionIconButton } from '@/components/Layout';

import { withAppContext } from '../AppContext';
import { EFM } from '../../api';

import produce from 'immer';
import get from 'lodash.get';
import { DateTimePicker } from '@material-ui/pickers';

import Note from './Note';
import FollowersDialog from './FollowersDialog';
import { AddCommentBar } from '../Conversations';

import { UserAvatarGroup } from '../Users';
import { withTranslation } from 'react-i18next';

import { getHours, getMinutes, format } from '../../utils/date';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withSnackbar } from 'notistack';

const styles = theme => ({
	postNoteAction: {
		padding: theme.spacing(0, 3, 1),
	},
	followers: {
		padding: theme.spacing(1, 3),
	},
	innerNote: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		'&:last-of-type': {
			paddingBottom: theme.spacing(3),
		},
	},
	borderedCard: {},
	buttonRow: {
		paddingTop: theme.spacing(1),
	},
});

class NotesCard extends Component {
	static defaultProps = {
		isElevated: true,
		notes: [],
	};

	state = {
		addedFollowers: [],
		notes: Array.isArray(this.props.notes)
			? this.props.notes.map((n, i) => {
					return Object.assign({}, n, { edit: false });
			  })
			: [],
	};

	componentDidUpdate(prevProps) {
		if (prevProps.notes.length !== this.props.notes.length) {
			this.setState({
				notes: this.props.notes.map((n, i) => {
					return Object.assign({}, n, { edit: false });
				}),
			});
		}
	}

	updateNote = (id, o = {}) => {
		const { notes } = this.state;
		let index = notes.findIndex(n => id == n.id);
		this.setState(
			produce(draft => {
				Object.assign(draft.notes[index], o);
			}),
			s => {
				if (o.save) {
					EFM.post('/application/notes/edit', {
						note_id: id,
						note: notes[index].text,
					}).then(r => {});
				}
			}
		);
	};

	removeNote = noteId => {
		const { notes } = this.state;
		let index = notes.findIndex(n => noteId == n.id);
		this.setState(
			produce(draft => {
				draft.notes.splice(index, 1);
			}),
			() => {
				EFM.post('/application/notes/delete', { note_id: noteId });
			}
		);
	};

	addNote = (note, fn = () => {}) => {
		const {
			app: { users },
			feedback_id,
		} = this.props;
		this.setState(
			produce(draft => {
				draft.notes.push({
					text: note.text,
					user_id: users.current.id,
					user_firstname: get(users, 'current.firstname', ''),
					user_lastname: get(users, 'current.lastname', ''),
					created: '___NOW___',
					id: Math.random(),
				});
			}),
			() => {
				this.setState({ newNote: '' });
				EFM.post('/application/notes/add', {
					note: note.text,
					feedback_id: feedback_id,
				}).then(r => {
					if (r.code === 200) {
						this.setState(
							produce(draft => {
								draft.notes[draft.notes.length - 1].id = r.note_id;
							})
						);
					}
				});
			}
		);
	};

	setReminder = (id, date) => {
		const { app, enqueueSnackbar, t } = this.props;

		EFM.post('/application/notes/remind', {
			note_id: id,
			date: format(date, app.date.format),
			hour: getHours(date),
			minutes: getMinutes(date),
			action: 'set',
		}).then(r => {
			if (r.code === 200) {
				this.updateNote(id, { reminder: format(date, app.date.format + ' HH:MM') });
				enqueueSnackbar(
					t(`You'll be reminded about this note at {{date}}`, {
						date: format(date, app.date.format),
					})
				);
			}
		});
	};

	removeReminder = id => {
		const { t, enqueueSnackbar } = this.props;
		EFM.post('/application/notes/remind', {
			note_id: id,
			action: 'remove',
		}).then(r => {
			if (r.code === 200) {
				this.updateNote(id, { reminder: null });
				enqueueSnackbar(t`Reminder removed`);
			}
		});
	};

	render() {
		const {
			isElevated,
			classes,
			app: { users },
			note_followers = [],
			feedback_id,
			dataTrackEvent,
			t,
		} = this.props;
		const {
			notes,
			newNote,
			textareaFocus,
			showFollowersDialog,
			addedFollowers = [],
		} = this.state;

		let cardProps = !isElevated ? { elevation: 0, className: classes.borderedCard } : {};
		const allFollowers = [...note_followers, ...addedFollowers];
		const followers = allFollowers.reduce((unique, currentFollower) => {
			if (!unique.some(obj => obj.user_id === currentFollower.user_id)) {
				unique.push(currentFollower);
			}
			return unique;
		}, []);

		return (
			<div>
				<Card
					{...cardProps}
					data-onboarding="notes-card"
				>
					<CardHeader
						titleTypographyProps={{ variant: 'subtitle2' }}
						title={t('Notes')}
					/>

					<div>
						<TransitionGroup>
							{notes.map((note, index) => {
								return (
									<Slide
										key={index}
										//transitionLeave={true}
										direction="up"
										mountOnEnter
										unmountOnExit
									>
										<Note
											{...note}
											updateNote={this.updateNote}
											removeNote={this.removeNote}
											index={index}
											allNotes={notes}
											setReminder={this.setReminder}
											removeReminder={this.removeReminder}
										/>
									</Slide>
								);
							})}
						</TransitionGroup>
					</div>

					{
						//<Divider />
					}
					<div className={classes.postNoteAction}>
						<AddCommentBar
							onChange={e => this.setState({ newNote: e.target.value })}
							label={t`Add note`}
							value={newNote}
							onSave={() => this.addNote({ text: newNote })}
							actionBoxProps={{
								p: 0,
							}}
							dataTrackEvent={dataTrackEvent}
							leftBottomGridContent={
								<Fragment>
									{followers.length === 0 && (
										<ActionButton
											action="add"
											onClick={e => this.setState({ showFollowersDialog: true })}
											color="primary"
											title={t('Add followers')}
											size="small"
											variant="text"
										>
											{t(`Followers`)}
										</ActionButton>
									)}
									{followers.length > 0 && (
										<Fragment>
											<Typography
												align="right"
												variant="caption"
												color="textSecondary"
												className={classes.followerCaption}
											>
												{t('Followers')}
											</Typography>
											<Grid
												container
												spacing={0}
											>
												<UserAvatarGroup
													stack={followers}
													size="small"
													direction="rtl"
													tooltip
												/>
												<ActionIconButton
													action="add"
													onClick={e => this.setState({ showFollowersDialog: true })}
													color="primary"
													title={t('Add followers')}
													size="small"
													edge="true"
												/>
											</Grid>
										</Fragment>
									)}
								</Fragment>
							}
						/>
					</div>
				</Card>

				{
					// <Grid className={classes.postNoteAction}>
					// 							<Grid item xs>
					// 								<Grid container wrap="nowrap" spacing={1}>
					// 									<Grid item>
					// 										<UserAvatar current />
					// 									</Grid>
					// 									<Grid item xs>
					// 										<TextField
					// 											label={t('Add note')}
					// 											placeholder={t('Start typing...')}
					// 											multiline
					// 											onChange={e => {
					// 												this.setState({newNote:e.target.value})
					// 											}}
					// 											fullWidth
					// 											value={newNote}
					// 											onFocus={e => {this.setState({textareaFocus:true})}}
					// 											onBlur={e => {this.setState({textareaFocus:false})}}
					// 										/>
					// 									</Grid>
					// 								</Grid>
					// 							</Grid>
					// 							<Grid item xs>
					// 								<Grid container direction="row" alignItems="center"  className={classes.buttonRow}>
					// 									<Grid item xs>
					// 										<Grid container direction="row" alignItems="center">
					// 										</Grid>
					// 									</Grid>
					// 									<Grid item>
					// 										{
					// 											newNote || textareaFocus ?
					// 											<Button
					// 												variant="contained"
					// 												color="primary"
					// 												onClick={e => {
					// 													this.addNote({
					// 														text:this.state.newNote
					// 													})
					// 												}}
					// 											>
					// 												{t('Save')}
					// 											</Button>
					// 											: null
					// 										}
					// 									</Grid>
					// 								</Grid>
					// 							</Grid>
					// 						</Grid>
				}

				<FollowersDialog
					feedback_id={feedback_id}
					open={Boolean(showFollowersDialog)}
					close={e => this.setState({ showFollowersDialog: false })}
					onAdd={ids =>
						this.setState(
							produce(draft => {
								draft.addedFollowers.push(...ids.map(id => ({ user_id: id })));
							})
						)
					}
				/>
			</div>
		);
	}
}

export default withAppContext(
	withSnackbar(withStyles(styles)(withTranslation()(NotesCard)))
);
