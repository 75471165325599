import React, { Fragment, useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import DeleteIcon from '@material-ui/icons/Delete';
import InboxIcon from '@material-ui/icons/Inbox';
import GetIcon from '@material-ui/icons/GetApp';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { DataSourceCard, useDataSourceContext } from '../DataSources';
import { Grid, Loader, AjaxFormDialog, ActionIconButton } from '../Layout';
import { useEFM } from '../Ajax';
import { useAppContext } from '../AppContext';
import { FeedbackQuickViewDrawer } from '../Feedback';
import { useHasPermission, NoPermissionTooltip } from '../Permission';

const useStyles = makeStyles(theme => ({
	loader: {
		marginLeft: theme.spacing(0.5),
		verticalAlign: 'middle',
	},
}));

export default function DataSetCard(props) {
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const hasEditPermission = useHasPermission();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [quickViewOpen, setQuickView] = useState(false);
	const [deleteOpen, setDelete] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [importStatus, loadingStatus] = useEFM(
		'/survey/ajax/get-import-status',
		{ survey_id: props.id },
		false
	);

	const quickViewFilters = useMemo(() => {
		const field = datasource.api
			.getFieldArray(props.id)
			.find(field => field.import_var.toLowerCase() === 'survey');
		return field
			? [{ identifier: field.id, value: [props.name], type: 'datafield' }]
			: [];
	}, [datasource.api.getFieldArray(props.id).length]);

	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Fragment>
			<Box
				height="100%"
				width="100%"
				flexDirection="column"
				display="flex"
				clone
			>
				<DataSourceCard
					{...props}
					variant="large"
					info={[
						{ label: t`Fields`, value: datasource.api.getFieldArray(props.id).length },
						{
							label: t`Import status`,
							value: loadingStatus ? (
								<Loader
									circular
									size={16}
									className={classes.loader}
								/>
							) : (
								t(importStatus.status)
							),
						},
						{
							label: t`Imported`,
							value: loadingStatus ? (
								<Loader
									circular
									size={16}
									className={classes.loader}
								/>
							) : (
								importStatus.count
							),
						},
					]}
					actions={
						<Fragment>
							<Grid container>
								<Grid
									item
									xs
								>
									<NoPermissionTooltip>
										<ActionIconButton
											action="add"
											component={Link}
											tooltip={t`Add data to dataset`}
											to={`/data-collection/dataset/import/${props.id}`}
											disabled={!hasEditPermission}
										/>
									</NoPermissionTooltip>
								</Grid>
								<Grid item>
									<ActionIconButton
										action="more"
										onClick={e => setMenuAnchor(e.currentTarget)}
										tooltip={t`Options`}
										data-test-element="datasetEditOptions"
									/>
								</Grid>
							</Grid>

							<Menu
								open={Boolean(menuAnchor)}
								anchorEl={menuAnchor}
								onClose={() => setMenuAnchor(null)}
							>
								<MenuItem onClick={() => setQuickView(true)}>
									<ListItemIcon>
										<InboxIcon />
									</ListItemIcon>
									<ListItemText primary={t`View feedback`} />
								</MenuItem>

								<MenuItem
									component="a"
									href={`/survey/import/generate-template/${props.id}`}
									download
									data-test-element="datasetDownload"
									data-track-event="dataset_dataset_download_template"
								>
									<ListItemIcon>
										<GetIcon />
									</ListItemIcon>
									<ListItemText primary={t`Download template`} />
								</MenuItem>

								<MenuItem
									onClick={() => setDelete(true)}
									data-test-element="datasetDelete"
									disabled={!hasEditPermission}
								>
									<ListItemIcon>
										<DeleteIcon />
									</ListItemIcon>
									<ListItemText primary={t`Delete`} />
								</MenuItem>
							</Menu>
						</Fragment>
					}
				/>
			</Box>

			<FeedbackQuickViewDrawer
				open={quickViewOpen}
				onClose={e => setQuickView(false)}
				customFilters={quickViewFilters}
				surveyId={props.id}
			/>

			<AjaxFormDialog
				AjaxFormProps={{
					valid: () => hasEditPermission,
					url: '/survey/ajax/delete-survey',
					data: { surveyId: props.id },
					onSuccess: () => {
						datasource.api.loadSurveys(0);
						enqueueSnackbar(`Dataset deleted`);
					},
				}}
				open={deleteOpen}
				onClose={() => setDelete(false)}
				title={t`Delete dataset`}
				dataTrackEvent="dataset_dataset_deleted"
				content={
					<Fragment>
						{t`Do you want to permanently delete this dataset?`}{' '}
						<strong>{t`You won't be able to undo this.`}</strong>
					</Fragment>
				}
			/>
		</Fragment>
	);
}
