import React from 'react';

import { useAppContext } from '@/components/AppContext';
import { useAjaxForm } from '@/components/Ajax';

export default function useSaveDeployment({ data, deploymentId, onSuccess }) {
	const { app } = useAppContext();

	return useAjaxForm({
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}/${deploymentId}`,
		type: 'mjolnir',
		data: data,
		onSuccess,
	});
}
