import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CustomerCard, ContactWithCustomerCard } from '../Customer';
import { FeedbackDetailsWebhookCard, WebHookContextProvider } from '../WebHook';
import { NotesCard } from '../Notes';
import { ActionProgressCard, ActionAddDialog } from '../Actions';
import FeedbackHighlights from './FeedbackHighlights';
import FeedbackMeta from './FeedbackMeta';
import FeedbackSummary from './FeedbackSummary';
import FeedbackDeleteDialog from './FeedbackDeleteDialog';
import FeedbackShareDialog from './FeedbackShareDialog';
import useDataFieldOrder from './useDataFieldOrder';
import FeedbackWebHookDialog from './FeedbackWebHookDialog';
import { useDataSourceContext } from '../DataSources';
import ShareMenu from './ShareMenu';
import { AddTagsDialog } from '../Tags';
import { AppBarActions, PageHeader } from '../App';
import { Loader, ActionIconButton, Grid, Card } from '../Layout';
import { TagChip } from '../Tags';
import { AjaxForm } from '../Ajax';
import { ruleTests } from '@/components/Validation';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
} from '../Permission';

const useStyles = makeStyles(theme => ({
	fillWidth: {
		width: '100%',
	},
	fillHeight: {
		flex: '1',
	},
	leftColumnFromExp: {
		borderRight: '1px solid ' + theme.palette.divider,
		'& > *:not(:last-child)': {
			borderBottom: '1px solid ' + theme.palette.divider,
		},
	},
	rightColumnFromExp: {
		overflow: 'hidden',
		'& > *:not(:last-child)': {
			borderBottom: '1px solid ' + theme.palette.divider,
		},
	},
	leftColumn: {
		'& > *:not(:last-child)': {
			marginBottom: theme.spacing(3),
		},
	},
	rightColumn: {
		'& > *:not(:last-child)': {
			marginBottom: theme.spacing(3),
		},
	},
	tags: {
		marginBottom: `${theme.spacing(1)}px!important`,
	},
}));

const ConditionalCard = ({ condition, children }) => {
	return condition ? (
		<Card style={{ width: '100%' }}>
			<Grid container>{children}</Grid>
		</Card>
	) : (
		children
	);
};

function getOptimisticUpdatedWebhooks(feedback_id) {
	let hooks = [];
	try {
		let sentHook = sessionStorage.getItem(`sent_webhook_${feedback_id}`);
		if (sentHook) {
			hooks.push(JSON.parse(sentHook));
		}
	} catch (e) {}
	return hooks;
}

export default function FeedbackDetails({
	details = {},
	campaign,
	fromExpansionPanel,
	feedback_id,
	isPage,
	loadData = () => {},
	loading,
}) {
	const classes = useStyles();
	const { datasource } = useDataSourceContext();
	const { t } = useTranslation();

	const hasInboxEditPermission = useHasPermission();
	const hasActionEditPermission = useHasPermission({
		module: permissionModules.actions,
		subject: permissionSubjects.action_management,
	});
	const location = useLocation();

	const [actionDialogOpen, setActionDialogOpen] = useState(false);
	const [tagsDialogOpen, setTagsDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [shareDialogOpen, setShareDialogOpen] = useState(false);
	const [replyDialogOpen, setReplyDialogOpen] = useState(false);
	const [shareMenuAnchor, setShareMenuAnchor] = useState(null);
	const [mousePos, setMousePos] = useState({});
	const [selectedHook, setSelectedHook] = useState(false);

	const feedbackList = location.state && location.state.feedbackList;
	const currentItemIndex =
		feedbackList && feedbackList.findIndex(item => item.feedback_id === feedback_id);

	const dataFieldOrder = useDataFieldOrder(details?.feedback?.survey_id);
	const detailProps = {
		...details,
		dataFieldOrder,
		campaign,
		survey_id: details?.feedback?.survey_id ?? '',
		isElevated: fromExpansionPanel ? false : true,
		translatedFeedback: datasource.api.getSource(details?.feedback?.survey_id)
			.translate_into_lang,
	};

	const optimisticWebhooks = getOptimisticUpdatedWebhooks(feedback_id);
	const webhooks = [...get(detailProps, 'webhooks', []), ...optimisticWebhooks];
	const hasScreenshot = detailProps.image_vars
		? Object.keys(detailProps.image_vars).length > 0
		: false;

	return (
		<WebHookContextProvider>
			<>
				{isPage && (
					<PageHeader
						title={`${t`Feedback by`} ${get(details, 'customer.full_name', '')}`}
						backTo="/reporting/inbox"
						backTooltip={t`To feedback inbox`}
					/>
				)}
				{isPage && (
					<AppBarActions>
						<>
							{feedbackList && (
								<>
									<ActionIconButton
										tooltip={t`Previous item`}
										action="previous"
										link
										disabled={
											typeof currentItemIndex !== 'number' ||
											!feedbackList[currentItemIndex - 1]
										}
										to={{
											pathname: `/reporting/inbox/detail/${get(
												feedbackList[currentItemIndex - 1],
												'feedback_id',
												''
											)}`,
											state: { feedbackList },
										}}
									/>

									<ActionIconButton
										tooltip={t`Next item`}
										action="next"
										link
										disabled={
											typeof currentItemIndex !== 'number' ||
											!feedbackList[currentItemIndex + 1]
										}
										to={{
											pathname: `/reporting/inbox/detail/${get(
												feedbackList[currentItemIndex + 1],
												'feedback_id',
												''
											)}`,
											state: { feedbackList },
										}}
									/>
								</>
							)}

							{details.hasEmailAddress && (
								<NoPermissionTooltip>
									<ActionIconButton
										tooltip={t('Reply')}
										action="reply"
										onClick={e => {
											e.stopPropagation();
											setReplyDialogOpen(true);
										}}
										disabled={!hasInboxEditPermission}
									/>
								</NoPermissionTooltip>
							)}

							<ActionIconButton
								action="share"
								tooltip={t`Share`}
								onClick={e => {
									e.stopPropagation();
									const { clientX, clientY } = e;
									setMousePos({ clientX, clientY });
									setShareMenuAnchor(e.target);
								}}
							/>

							<NoPermissionTooltip
								module={permissionModules.actions}
								subject={permissionSubjects.action_management}
							>
								<ActionIconButton
									tooltip={t('Add action')}
									action="add_action"
									onClick={e => {
										e.stopPropagation();
										setActionDialogOpen(true);
									}}
									disabled={!hasActionEditPermission}
								/>
							</NoPermissionTooltip>

							<NoPermissionTooltip>
								<ActionIconButton
									action="tags"
									tooltip={t`Add tags`}
									onClick={e => {
										e.stopPropagation();
										setTagsDialogOpen(true);
									}}
									disabled={!hasInboxEditPermission}
								/>
							</NoPermissionTooltip>

							<NoPermissionTooltip>
								<ActionIconButton
									tooltip={t('Delete item')}
									action="delete"
									onClick={e => {
										e.stopPropagation();
										setDeleteDialogOpen(true);
									}}
									disabled={!hasInboxEditPermission}
								/>
							</NoPermissionTooltip>
						</>

						<ShareMenu
							shareMenuAnchor={shareMenuAnchor}
							mouseX={mousePos.clientX}
							mouseY={mousePos.clientY}
							feedbackId={feedback_id}
							allowShareEmail
							closeMenu={() => {
								setShareMenuAnchor(null);
								setMousePos({});
							}}
							setShareDialogOpen={() => {
								setShareMenuAnchor(null);
								setMousePos({});
								setShareDialogOpen(true);
							}}
							setWebHookDialogOpen={webhookId => {
								setSelectedHook(webhookId);
							}}
						/>
					</AppBarActions>
				)}

				<div
					className={classes.fillWidth}
					data-onboarding="feedback-details"
					data-test-element="feedback-details"
				>
					{Object.keys(details).length === 0 || loading ? (
						<Loader empty />
					) : (
						<Grid
							container
							spacing={isPage ? 3 : null}
							className={classes.fillWidth}
						>
							<Grid
								item
								md={4}
								sm={12}
								xs={12}
								className={`${
									fromExpansionPanel ? classes.leftColumnFromExp : classes.leftColumn
								}`}
							>
								{(details?.customer?.full_name !== 'unknown' ||
									ruleTests.email(details?.customer?.email)) && (
									<CustomerCard
										avatarSize="medium"
										customer={details.customer}
										link
										isElevated={!fromExpansionPanel}
									/>
								)}
								{get(detailProps, 'emails', []).length > 0 && (
									<ContactWithCustomerCard {...detailProps} />
								)}
								{webhooks.length > 0 && (
									<FeedbackDetailsWebhookCard
										isElevated={detailProps.isElevated}
										webhooks={webhooks}
									/>
								)}
								{get(detailProps, 'actionsPaginator.data', []).length > 0 && (
									<ActionProgressCard
										{...detailProps}
										actions={get(detailProps, 'actionsPaginator.data', [])}
									/>
								)}
								<NotesCard
									{...detailProps}
									dataTrackEvent="inbox_feedback_add_note"
									feedback_id={feedback_id}
								/>
							</Grid>
							<Grid
								item
								md={8}
								sm={12}
								xs={12}
								className={`${
									fromExpansionPanel ? classes.rightColumnFromExp : classes.rightColumn
								}`}
								container
								direction="column"
							>
								{isPage && get(details, 'tags.current', []).length > 0 && (
									<div className={classes.tags}>
										{details.tags.current.map(tag => (
											<AjaxForm
												valid={() => hasInboxEditPermission}
												url="/application/tags/delete-by-id"
												data={{
													ids: tag.id,
													feedbackId: feedback_id,
												}}
												onSuccess={loadData}
												key={'form' + tag.id}
											>
												{({ postForm }) => (
													<TagChip
														key={tag.id}
														{...tag}
														label={tag.name}
														onDelete={hasInboxEditPermission ? postForm : null}
													/>
												)}
											</AjaxForm>
										))}
									</div>
								)}
								{(detailProps.highlights || hasScreenshot) && (
									<Grid
										item
										data-onboarding="feedback-highlights"
									>
										<FeedbackHighlights
											hasScreenshot={hasScreenshot}
											{...detailProps}
											isPage={isPage}
										/>
									</Grid>
								)}

								<Grid
									item
									container
									className={classes.fillHeight}
								>
									<ConditionalCard condition={!fromExpansionPanel}>
										<Grid
											item
											xs={12}
											sm={6}
											className={classes.leftColumnFromExp}
											data-onboarding="feedback-summary"
										>
											<FeedbackSummary {...detailProps} />
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											data-onboarding="feedback-meta"
										>
											<FeedbackMeta {...detailProps} />
										</Grid>
									</ConditionalCard>
								</Grid>
							</Grid>
						</Grid>
					)}

					<AddTagsDialog
						open={tagsDialogOpen}
						onClose={e => setTagsDialogOpen(false)}
						feedback_ids={[feedback_id]}
						onSuccess={loadData}
						dataTrackEvent="inbox_feedback_tags_added"
					/>
					<FeedbackDeleteDialog
						open={deleteDialogOpen}
						close={e => setDeleteDialogOpen(false)}
						feedback_ids={[feedback_id]}
						onSuccess={() => {}}
					/>
					<ActionAddDialog
						feedback_id={feedback_id}
						open={actionDialogOpen}
						onClose={e => setActionDialogOpen(false)}
						onSuccess={loadData}
					/>
					<FeedbackShareDialog
						open={shareDialogOpen || replyDialogOpen}
						onClose={e => {
							setShareDialogOpen(false);
							setReplyDialogOpen(false);
						}}
						feedback_id={feedback_id}
						replyTo={details.hasEmailAddress}
						reply={replyDialogOpen}
						details={details}
						onSuccess={loadData}
					/>
					<FeedbackWebHookDialog
						open={Boolean(selectedHook)}
						onClose={e => setSelectedHook(null)}
						selectedHook={selectedHook}
						feedback_ids={[feedback_id]}
						onSuccess={loadData}
					/>
				</div>
			</>
		</WebHookContextProvider>
	);
}
