import React, { useContext, useEffect, useRef, useState } from 'react';

import Switch from '@material-ui/core/Switch';

import DeleteIcon from '@material-ui/icons/Delete';
import InboxIcon from '@material-ui/icons/Inbox';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShareIcon from '@material-ui/icons/Share';
import NameIcon from '@material-ui/icons/Info';
import IntegrationsIcon from '@material-ui/icons/CallMerge';
import MetricsIcon from '@material-ui/icons/BarChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ViewIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@/styles';

import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';

import { DataSourceCard, DataSourceContext } from '@/components/DataSources';
import { useAppContext } from '@/components/AppContext';
import {
	ActionIconButton,
	AjaxFormDialog,
	Loader,
	RenderConditional,
	Grid,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Box,
	Link,
	Typography,
} from '@/components/Layout';
import { UserName } from '@/components/Users';
import { ValidatedField } from '@/components/Validation';
import { useAjaxForm } from '@/components/Ajax';
import { getFormType } from './surveyUtils';
import SurveyMetricsDrawer from './SurveyMetricsDrawer';
import useNumberOfCompletes from './useNumberOfCompletes';
import { copyToClipboard, tryParse } from '@/utils';
import { FeedbackQuickViewDrawer } from '@/components/Feedback';
import { useIsInViewport } from '@/hooks';
import { UpgradeOverlay } from '@/components/Billing';
import {
	NoPermissionTooltip,
	permissionModules,
	permissionSubjects,
	permissionTypes,
	useHasPermission,
} from '@/components/Permission';
import SurveyCopyDialog from './SurveyCopyDialog';

const useStyles = makeStyles(theme => ({
	loader: {
		marginLeft: theme.spacing(0.5),
		verticalAlign: 'middle',
	},
	activity: {
		marginRight: theme.spacing(0.5),
		verticalAlign: 'middle',
	},
}));

export default function SurveyListCard({
	name,
	title,
	deployments = [],
	triggers = [],
	sdk_type,
	survey_format,
	...props
}) {
	const advanced = tryParse(props.advanced);
	const map = tryParse(props.map);
	const trigger = tryParse(props.trigger);

	const campaign = props.campaign ? props.campaign : advanced.sdk_survey ? 'sdk' : 'web';

	const { datasource } = useContext(DataSourceContext);
	const { app } = useAppContext();

	const [menuAnchor, setMenuAnchor] = useState(null);
	const [quickViewOpen, setQuickView] = useState(false);
	const [metricsOpen, setMetricsOpen] = useState(false);
	const [deleteOpen, setDelete] = useState(false);
	const [newName, setNewName] = useState('');
	const [changeNameOpen, setChangeNameOpen] = useState(false);
	const [copyFormOpen, setCopyFormOpen] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const cardRef = useRef(null);
	const { wasInViewport } = useIsInViewport(cardRef.current);

	const hasBuildEditPermission = useHasPermission({
		subject: permissionSubjects.feedback_forms_build,
	});
	const hasBuildOrDesignEditPermission = useHasPermission({
		permission: permissionTypes.edit,
		subject: [
			permissionSubjects.feedback_forms_build,
			permissionSubjects.feedback_forms_design,
		],
	});

	const hasFeedbackViewPermission = useHasPermission({
		permission: permissionTypes.view,
		module: permissionModules.reporting,
		subject: permissionSubjects.feedback_inbox,
	});

	const hasDeploymentViewPermission = useHasPermission({
		permission: permissionTypes.view,
		subject: permissionSubjects.deployments,
	});

	const hasIntegrationViewPermission = useHasPermission({
		permission: permissionTypes.view,
		module: permissionModules.integrations,
		subject: permissionSubjects.webhooks_apps,
	});

	const hasBuildOrDesignViewPermission = useHasPermission({
		permission: permissionTypes.view,
		subject: [
			permissionSubjects.feedback_forms_build,
			permissionSubjects.feedback_forms_design,
		],
	});

	const [completes, completesLoading] = useNumberOfCompletes(props.id, !wasInViewport);

	useEffect(() => {
		setNewName(name);
	}, [name]);

	//:(
	const isLocked = () => {
		if (map.locked === 'false') return false;
		if (map.locked === 'true') return true;

		return Boolean(map.locked);
	};

	const lockedBy = Number(map.userid || map.userId);
	const lockedNotByCurrent = isLocked() && lockedBy !== app.users.current.id;

	const { postForm: lockForm, loading } = useAjaxForm({
		url: '/survey/ajax/save-formlock',
		data: {
			id: props.id,
			locked: !isLocked(),
		},
		onSuccess: () => {
			datasource.api.updateSurvey(props.id);
			enqueueSnackbar(
				!isLocked()
					? t(
							`data_collection-feedback_forms-survey_list_card-form_lock-snackbar_locked`,
							{ name: name }
					  )
					: t(
							`data_collection-feedback_forms-survey_list_card-form_lock-snackbar_unlocked`,
							{ name: name }
					  )
			);
		},
	});

	function getEditUrl(step) {
		const url = type =>
			`/data-collection/survey/edit/${props.id}/${type}/detail${step ? '/' + step : ''}`;

		return url(campaign);
	}

	const getChannel = () => {
		if (campaign === 'sdk') {
			const sdkFormType =
				sdk_type === 'webview' || survey_format === 'conversational'
					? 'webview'
					: 'native';
			return t(
				`data_collection-feedback_forms-survey_list_card-channel-${campaign}_${sdkFormType}`
			);
		}
		return t(`data_collection-feedback_forms-survey_list_card-channel-${campaign}`);
	};

	return (
		<>
			<UpgradeOverlay
				message={t(`data_collection-survey_list_card-product_not_allowed-${campaign}`)}
				needsUpgrade={!app.api.packageProductAllowed(campaign)}
				height="100%"
			>
				<Box
					height="100%"
					width="100%"
					flexDirection="column"
					display="flex"
					clone
				>
					<DataSourceCard
						title={name}
						{...props}
						survey_format={survey_format}
						campaign={campaign}
						variant="large"
						info={[
							{
								label: t`data_collection-feedback_forms-survey_list_card-type-label`,
								value: t(
									`data_collection-feedback_forms-survey_list_card-type-${survey_format}`
								),
							},
							{
								label: t`data_collection-feedback_forms-survey_list_card-channel-label`,
								value: getChannel(),
							},
							{
								label: t`data_collection-feedback_forms-survey_list_card-display_type-label`,
								value:
									campaign === 'sdk'
										? t`data_collection-feedback_forms-survey_list_card-display_type-modal`
										: getFormType(trigger, props.modal).typeLabel,
							},
							{
								label: t`data_collection-feedback_forms-survey_list_card-form_elements-label`,
								value: map.layout && map.layout.length,
							},
							{
								label: t`data_collection-feedback_forms-survey_list_card-completes-label`,
								value: completesLoading ? (
									<Loader
										circular
										size={16}
										className={classes.loader}
									/>
								) : (
									completes
								),
							},
							{
								label: t`data_collection-survey_list_card-in_deployments-label`,
								value: (
									<Typography
										TooltipProps={{
											interactive: true,
										}}
										tooltip={
											hasDeploymentViewPermission && deployments?.length > 0 ? (
												<>
													<Typography
														display="block"
														variant="caption"
														fontWeight="medium"
														mb={2}
													>
														{t(
															`data_collection-survey_list_card-in_deployments-tooltip${
																deployments?.length === 0 ? '-not_active' : ''
															}`
														)}
													</Typography>
													{hasDeploymentViewPermission &&
														deployments?.map(deployment => (
															<Box key={deployment.id}>
																<Link
																	color="inherit"
																	to={`/data-collection/deployment/edit/${deployment.id}`}
																	variant="caption"
																>
																	{deployment.name}
																</Link>
															</Box>
														))}
												</>
											) : null
										}
									>
										{deployments?.length}
									</Typography>
								),
							},
							...(hasBuildEditPermission
								? [
										{
											label: t`data_collection-feedback_forms-survey_list_card-form_lock-label`,
											value: (
												<RenderConditional
													condition={isLocked()}
													title={
														<>
															{t`data_collection-feedback_forms-survey_list_card-form_lock-locked`}{' '}
															<UserName user_id={lockedBy} />
														</>
													}
												>
													<Switch
														size="small"
														edge="end"
														disabled={loading || lockedNotByCurrent}
														checked={isLocked()}
														onClick={e => {
															lockForm();
														}}
													/>
												</RenderConditional>
											),
										},
								  ]
								: []),
						]}
						actions={
							<>
								<Grid container>
									<Grid
										item
										xs
									>
										<NoPermissionTooltip
											subject={[
												permissionSubjects.feedback_forms_build,
												permissionSubjects.feedback_forms_design,
											]}
										>
											<ActionIconButton
												link={!isLocked()}
												to={getEditUrl()}
												action={isLocked() ? 'lock' : 'edit'}
												badge={
													hasBuildOrDesignViewPermission &&
													!hasBuildOrDesignEditPermission ? (
														<ViewIcon fontSize="small" />
													) : null
												}
												BadgeProps={{
													anchorOrigin: {
														vertical: 'bottom',
														horizontal: 'right',
													},
													color: 'default',
												}}
												tooltip={
													!isLocked() ? (
														t`data_collection-feedback_forms-survey_list_card-edit_form_button`
													) : (
														<>
															{t`data_collection-feedback_forms-survey_list_card-form_lock-locked_button`}
															<UserName
																showSelfAsYou
																user_id={lockedBy}
															/>
														</>
													)
												}
												disabled={!hasBuildOrDesignViewPermission || isLocked()}
												data-test-element="survey-list-card-edit-button"
											/>
										</NoPermissionTooltip>

										<ActionIconButton
											action="view"
											link
											to={`/data-collection/survey/preview/${props.survey_key}`}
											tooltip={t`data_collection-feedback_forms-survey_list_card-view_form_button`}
										/>
									</Grid>
									<Grid item>
										<ActionIconButton
											onClick={e => setMenuAnchor(e.currentTarget)}
											action="more"
											tooltip={t`data_collection-feedback_forms-survey_list_card-options_tooltip`}
											data-test-element="survey-list-card-more-button"
										/>
									</Grid>
								</Grid>

								<Menu
									open={Boolean(menuAnchor)}
									anchorEl={menuAnchor}
									onClose={() => setMenuAnchor(null)}
								>
									<MenuItem
										onClick={() => setQuickView(true)}
										disabled={!hasFeedbackViewPermission}
									>
										<ListItemIcon>
											<InboxIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-view_feedback`}
										/>
									</MenuItem>
									{advanced.logMetrics && (
										<MenuItem onClick={() => setMetricsOpen(true)}>
											<ListItemIcon>
												<MetricsIcon />
											</ListItemIcon>
											<ListItemText
												primary={t`data_collection-feedback_forms-list-cardmenu-view_metrics`}
											/>
										</MenuItem>
									)}

									<MenuItem
										onClick={() => setCopyFormOpen(true)}
										disabled={!hasBuildEditPermission}
										data-track-event="copy_form_dialog"
									>
										<ListItemIcon>
											<FileCopyIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-copy_form`}
										/>
									</MenuItem>

									<MenuItem
										onClick={() => setChangeNameOpen(true)}
										disabled={!hasBuildEditPermission}
									>
										<ListItemIcon>
											<NameIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-change_name`}
										/>
									</MenuItem>

									<MenuItem
										component={RouterLink}
										to={getEditUrl('integration')}
										disabled={
											!hasBuildOrDesignViewPermission && !hasIntegrationViewPermission
										}
									>
										<ListItemIcon>
											<IntegrationsIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-integrations`}
										/>
									</MenuItem>

									<MenuItem
										onClick={e =>
											copyToClipboard(props.survey_key).then(() => {
												enqueueSnackbar(
													t`data_collection-feedback_forms-list-cardmenu-copy_form_key-snackbar`
												);
											})
										}
									>
										<ListItemIcon>
											<VpnKeyIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-copy_form_key`}
										/>
									</MenuItem>

									<MenuItem
										component={RouterLink}
										to={`/reporting/direct-insights/${props.id}`}
									>
										<ListItemIcon>
											<BarChartIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-view_insights`}
										/>
									</MenuItem>

									<MenuItem
										component={RouterLink}
										to={getEditUrl('deploy')}
										disabled={
											!hasBuildOrDesignViewPermission && !hasDeploymentViewPermission
										}
									>
										<ListItemIcon>
											<ShareIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-deploy_share`}
										/>
									</MenuItem>

									<MenuItem
										onClick={() => setDelete(true)}
										disabled={!hasBuildEditPermission}
										data-test-element="survey-list-card-delete-button"
									>
										<ListItemIcon>
											<DeleteIcon />
										</ListItemIcon>
										<ListItemText
											primary={t`data_collection-feedback_forms-list-cardmenu-delete`}
										/>
									</MenuItem>
								</Menu>
							</>
						}
						ref={cardRef}
					/>
				</Box>
			</UpgradeOverlay>

			<FeedbackQuickViewDrawer
				open={quickViewOpen}
				onClose={e => setQuickView(false)}
				surveyId={props.id}
			/>
			{advanced.logMetrics && (
				<SurveyMetricsDrawer
					open={metricsOpen}
					onClose={e => setMetricsOpen(false)}
					name={name}
					isSdk={campaign === 'sdk' || advanced.sdk_survey}
					{...props}
				/>
			)}

			<AjaxFormDialog
				AjaxFormProps={{
					url: '/survey/ajax/update-survey-name',
					data: {
						surveyId: props.id,
						name: newName,
					},
					valid: () => newName,
					onSuccess: () => {
						datasource.api.loadSurveys(0);
						enqueueSnackbar(
							`data_collection-feedback_forms-survey_list-dialog_change_name-snackbar_form_updated`
						);
						setChangeNameOpen(false);
					},
				}}
				open={changeNameOpen}
				onClose={() => setChangeNameOpen(false)}
				title={t`data_collection-feedback_forms-survey_list-dialog_change_name-title`}
				submit={t`data_collection-feedback_forms-survey_list-dialog_change_name-button_submit`}
				content={
					<>
						<ValidatedField
							label={t`data_collection-feedback_forms-survey_list-dialog_change_name-input_label`}
							value={newName}
							onChange={e => setNewName(e.target.value)}
							errorMessage={t`data_collection-feedback_forms-survey_list-dialog_change_name-error_message`}
							fullWidth
							rules={[['required', true]]}
							showErrorsAfterBlur
						/>
					</>
				}
			/>

			<AjaxFormDialog
				AjaxFormProps={{
					url: '/survey/ajax/delete-survey',
					data: { surveyId: props.id },
					onSuccess: () => {
						datasource.api.loadSurveys(0);
						enqueueSnackbar(
							t`data_collection-feedback_forms-survey_list-dialog_delete-snackbar`
						);
						setDelete(false);
					},
				}}
				dataTestElement="survey-delete-dialog-submit-button"
				open={deleteOpen}
				onClose={() => setDelete(false)}
				title={t`data_collection-feedback_forms-survey_list-dialog_delete-title`}
				dataTrackEvent={
					campaign && campaign === 'email'
						? 'form_mail_deleted'
						: advanced.sdk_survey
						? 'form_in_app_deleted'
						: 'form_web_deleted'
				}
				content={
					<Typography>
						<Trans
							i18nKey="data_collection-feedback_forms-survey_list-dialog_delete-text"
							components={{
								bold: <Typography fontWeight="medium" />,
							}}
						/>
					</Typography>
				}
			/>
			<SurveyCopyDialog
				open={copyFormOpen}
				surveyId={props.id}
				surveyName={name}
				onClose={() => {
					setCopyFormOpen(false);
					setMenuAnchor(null);
				}}
			/>
		</>
	);
}
