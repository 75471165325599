import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ActionButton from '../Layout/ActionButton';
import ContactDialog from '../Layout/ContactDialog';
import UpgradeDialog from './UpgradeDialog';
import { useAppContext } from '../AppContext';

export default function UpgradeButton({
	label,
	dataTrackEvent = '',
	upgradeSubject,
	...props
}) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { app } = useAppContext();

	const defaultLabel = app.package.is_stripe_customer
		? t`upgrade_button-upgrade_label`
		: t`upgrade_button-contact_label`;

	const showUpgrade = app.package.is_stripe_customer && open;
	const showContact = !app.package.is_stripe_customer && open;

	return (
		<>
			<ActionButton
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
				dataTrackEvent={dataTrackEvent}
				{...props}
			>
				{label ?? defaultLabel}
			</ActionButton>

			<UpgradeDialog
				open={showUpgrade}
				onClose={() => setOpen(false)}
			/>

			<ContactDialog
				mailTo="success@mopinion.com"
				subject={upgradeSubject ?? t`upgrade_button-subject_general`}
				open={showContact}
				onClose={() => setOpen(false)}
				dataTrackEvent={`${dataTrackEvent}_contact_success`}
			/>
		</>
	);
}
