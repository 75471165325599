import React from 'react';

import { useTranslation } from 'react-i18next';

import { DateRangeFilter } from '../AppFilters';
import { DrawerWithAppBar } from '../App';
import { ActionIconButton } from '../Layout';
import FeedbackList from './FeedbackList';
import {
	HasPermissionWithEmptyState,
	permissionSubjects,
	permissionModules,
} from '../Permission';

export default function FeedbackQuickViewDrawer({
	customFilters = [],
	surveyId,
	FeedbackListProps = {},
	...props
}) {
	const { t } = useTranslation();
	return (
		<DrawerWithAppBar
			ToolbarProps={{
				text: t`Feedback quick view`,
			}}
			ToolbarContent={
				<>
					<ActionIconButton
						link
						to={{
							pathname: '/reporting/inbox',
							state: {
								customFilters,
								surveyId,
								...FeedbackListProps,
							},
						}}
						tooltip={t`View in inbox`}
						action="inbox"
					/>

					<DateRangeFilter />
				</>
			}
			{...props}
		>
			<HasPermissionWithEmptyState
				permission="view"
				module={permissionModules.reporting}
				subject={permissionSubjects.feedback_inbox}
			>
				<FeedbackList
					noFilters
					noPersist
					inDrawer
					customFilters={customFilters}
					surveyId={surveyId}
					{...FeedbackListProps}
				/>
			</HasPermissionWithEmptyState>
		</DrawerWithAppBar>
	);
}
