import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import {
	SimpleDialog,
	Typography,
	Grid,
	Box,
	SelectionCard,
	CollapsibleBox,
} from '@/components/Layout';
import { useAjaxForm } from '@/components/Ajax';
import { ValidatedField } from '@/components/Validation';
import { AddToPackageExplainer } from '@/components/Explainer';
import { UpgradeOverlay } from '@/components/Billing';
import { UpsellCard } from '@/components/Billing';
import DeploymentAccess from './DeploymentAccess';

const deploymentTypeOptions = [
	{
		type: 'web',
		img: '/assets/img/r/product-web-icon.svg',
	},
	{
		type: 'sdk',
		img: '/assets/img/r/product-app-icon.svg',
	},
];

const useStyles = makeStyles(theme => ({
	img: {
		height: '100%',
		width: 'auto',
		paddingTop: theme.spacing(1.5),
	},
	imgSmall: {
		height: 40,
	},
	fillWidth: {
		width: '100%',
	},
}));

export default function DeploymentAddDialog({ open, onClose = () => {} }) {
	const classes = useStyles();
	const { app } = useAppContext();
	const { t } = useTranslation();
	const history = useHistory();
	const textFieldRef = useRef(null);
	const [state, setState] = useState({
		name: '',
		type: !app.package['allow_web'] ? 'sdk' : 'web',
		permission_groups: [],
	});

	const { postForm, clicked, loading } = useAjaxForm({
		url: `/api/1/pastease/${app.domain}/${app.organisations.current.org_id}`,
		type: 'mjolnir',
		data: state,
		valid: () => state.name && app.api.packageProductAllowed(state.type),
		onSuccess: response => {
			if (response.deployment_id) {
				history.push(
					`/data-collection/deployment/edit/${response.deployment_id}${
						state.type === 'sdk' ? '/sdk' : ''
					}`
				);
			}
		},
	});

	useEffect(() => {
		if (open) {
			try {
				setTimeout(() => textFieldRef.current.focus());
			} catch (e) {}
		}
	}, [open]);

	return (
		<SimpleDialog
			title={t`data_collection-deployment_add_dialog-title`}
			open={open}
			onClose={onClose}
			dataTrackEvent={
				state.type === 'sdk' ? 'deployment_in_app_created' : 'deployment_web_created'
			}
			dataTestElement="create-new-deployment-submit"
			submit={t`data_collection-deployment_add_dialog-submit`}
			loading={loading}
			onSubmit={postForm}
		>
			<Grid
				container
				direction="column"
				spacing={3}
			>
				<Grid
					item
					xs
				>
					<ValidatedField
						fullWidth
						label={t`data_collection-deployment_add_dialog-name-label`}
						placeholder={t`data_collection-deployment_add_dialog-name-placeholder`}
						value={state.name || ''}
						onChange={e => {
							const { value } = e.currentTarget;
							setState(prev => ({
								...prev,
								name: value,
							}));
						}}
						inputRef={textFieldRef}
						rules={{
							required: true,
						}}
						showErrors={clicked}
						errorMessage={t`data_collection-deployment_add_dialog-name-error`}
						data-test-element="deployment-name"
					/>
				</Grid>

				<Grid
					item
					xs
				>
					<Typography
						variant="subtitle2"
						fontWeight="medium"
						mb={1}
					>
						{t`data_collection-deployment_add_dialog-type-label`}
					</Typography>
					<Grid
						container
						spacing={2}
						direction="row"
					>
						{deploymentTypeOptions.map(({ type, img }) => {
							return (
								<Grid
									item
									xs
									key={type}
								>
									{!app.package[`allow_${type}`] && (
										<UpsellCard
											product={type}
											size="small"
											title={t(
												`deployment_list-add_deployment-product_disabled_title_${type}`
											)}
										>
											<Box>
												{t(
													`deployment_list-add_deployment-product_disabled_message_${type}`
												)}
												<AddToPackageExplainer
													product={type}
													alignLink="left"
												/>
											</Box>
										</UpsellCard>
									)}
									{app.package[`allow_${type}`] && (
										<SelectionCard
											selected={state.type === type}
											onClick={() => {
												setState(prev => ({
													...prev,
													type,
												}));
											}}
											disabled={!app.api.packageProductAllowed(type)}
										>
											<Box
												mb={2}
												align="center"
												flexDirection="column"
												justifyContent="center"
												alignItems="center"
												height="120px"
												width="100%"
											>
												<img
													src={img}
													className={classes.img}
												/>
											</Box>
											<Typography
												variant="subtitle2"
												align="center"
												mb={0.5}
											>
												{t(`data_collection-deployment_add_dialog-type-${type}-title`)}
											</Typography>
										</SelectionCard>
									)}
								</Grid>
							);
						})}
					</Grid>
				</Grid>

				<Grid
					item
					xs
				>
					<CollapsibleBox
						label={t`data_collection-deployment_add_dialog-access_collapse-label`}
						ActionButtonProps={{
							fontWeight: 'regular',
						}}
						startCollapsed
					>
						<Box mt={2}>
							<DeploymentAccess
								permissionGroups={state.permission_groups}
								onChange={permission_groups =>
									setState(prev => ({ ...prev, permission_groups }))
								}
							/>
						</Box>
					</CollapsibleBox>
				</Grid>
			</Grid>
		</SimpleDialog>
	);
}
