import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/components/AppContext';
import { Loader, EditableContent, Typography } from '@/components/Layout';
import useSetTextAnalyticsLanguage from './useSetTextAnalyticsLanguage';
import { useTextAnalyticsContext } from './TextAnalyticsContext';
import useGetProjectLanguageExpiration from './useGetProjectLanguageExpiration';
import { useTrackingEvents } from '@/components/Tracking';
import { sortByAlphabet } from '@/utils';

export default function TextAnalyticsLanguageSelect({
	languages = {},
	loading,
	surveyId,
	surveyName = '',
}) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { app } = useAppContext();
	const {
		textAnalytics: {
			languagePerActiveForm,
			api: { loadLanguages },
		},
	} = useTextAnalyticsContext();
	const { track } = useTrackingEvents();

	const [temporaryValue, setTemporaryValue] = useState(null);

	const mappedLanguages = Object.keys(languages).map(language => {
		return {
			value: languages[language],
			label: t(`reporting-text_analytics-${language}`),
		};
	});

	const languageValue =
		languagePerActiveForm.find(obj => {
			return Number(obj.survey_id) === Number(surveyId);
		})?.language ?? null;

	const projectLanguageValue =
		languagePerActiveForm.find(obj => {
			return typeof obj.survey_id === 'undefined';
		})?.language ?? null;

	const inputValue = surveyId
		? languageValue ?? projectLanguageValue ?? 'nl'
		: projectLanguageValue ?? 'nl';

	const [{ update_allowed }, expirationLoading, expirationError, loadExpirationData] =
		useGetProjectLanguageExpiration({
			surveyId: surveyId ?? null,
			languageValue: languageValue,
		});

	const languageLocked = !update_allowed || expirationLoading;
	const isDefaultLanguage =
		(surveyId && !languageValue) || (!surveyId && !projectLanguageValue);

	const { postForm: setLanguage, loading: languageLoading } = useSetTextAnalyticsLanguage(
		{
			onSuccess: response => {
				if (response.code >= 200 && response.code < 300) {
					enqueueSnackbar(
						t('reporting-text_analytics-snackbar-language_updated', {
							form: surveyName,
						})
					);
					loadLanguages();
					loadExpirationData();
					track(`text_analytics-language_select-update-${surveyId ? 'form' : 'report'}`);
				} else {
					enqueueSnackbar(t('reporting-text_analytics-snackbar-language_error'));
				}
			},
		}
	);

	const languageUpdateLoading = loading || languageLoading;

	return (
		<>
			{languageUpdateLoading && (
				<Loader
					circular
					size={16}
				/>
			)}
			{!loading && !languageUpdateLoading && Object.keys(languages).length > 0 && (
				<Typography variant="body1">
					<EditableContent
						showIconAlways
						disabled={languageLocked}
						editTooltip={
							languageLocked
								? t`reporting-text_analytics-tooltip-edit_blocked`
								: t`reporting-text_analytics-tooltip-edit`
						}
						options={sortByAlphabet(mappedLanguages, 'label')}
						onClose={() => {
							if (temporaryValue && temporaryValue !== inputValue) {
								setLanguage({
									post: {
										data: {
											language: temporaryValue,
											project_id: app.projects.current.id,
											domain: app.domain,
											...(surveyId && { survey_id: surveyId }),
										},
									},
								});
							}
						}}
						cancelOnBlur
						onChange={value => {
							setTemporaryValue(value);
						}}
						onEdit={e => {
							if (languageLocked) {
								enqueueSnackbar(
									t`reporting-text_analytics-snackbar-language_already_updated`
								);
							}
						}}
						value={temporaryValue ?? inputValue}
					>
						{isDefaultLanguage && !temporaryValue
							? `Default (${
									mappedLanguages.find(lang => lang.value === inputValue)?.label
							  })`
							: mappedLanguages.find(lang => lang.value === inputValue)?.label}
					</EditableContent>
				</Typography>
			)}
		</>
	);
}
