import React, { createContext, useContext } from 'react';

const DeploymentEditorContext = createContext({});

function DeploymentEditorProvider({ children, value }) {
	return (
		<DeploymentEditorContext.Provider value={value}>
			{children}
		</DeploymentEditorContext.Provider>
	);
}

function useDeploymentEditorContext() {
	return useContext(DeploymentEditorContext);
}

export { DeploymentEditorContext, DeploymentEditorProvider, useDeploymentEditorContext };
