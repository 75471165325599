import React, { Fragment, useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles(theme => ({
	wrap: {
		width: '100%',
		minHeight: '100%',
		height: '100%',
	},
	mainArea: {
		height: 'calc(100% - 64px)' /* TODO: do for responsive height toolbar too */,
		height: '100%',
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		'@media (max-width: 1200px)': {
			width: '100%',
		},
	},
	editorArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	areaShift: {
		width: props => `calc(100% - ${props.drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	fillHeight: {
		height: '100%',
	},
	filterDrawer: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		'@media (max-width: 1200px)': {
			width: '100%',
		},
	},
	filterDrawerShift: {
		width: props => `calc(100% - ${props.drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	persistentDrawerPaper: {
		...theme.mixins.toolbarNormal('top'),
		height: `calc(100% - 64px)`,
	},
	persistentDrawerPaperProminent: {
		...theme.mixins.toolbarProminent('top'),
		height: `calc(100% - 128px)`,
	},
	drawerPaper: {
		width: props => props.drawerWidth,
		zIndex: theme.zIndex.appBar - 1,
		transitions: theme.transitions.create('top'),
	},
	disableScroll: {
		overflowY: 'hidden',
	},
	fillWidth: {
		width: '100%',
	},
}));

export default function LayoutWithSidebar({
	children,
	editor,
	prominent,
	drawerWidth = 300,
	drawerOpen,
	persistentDrawer,
	drawerContent,
	DrawerProps = {},
	...props
}) {
	const classes = useStyles({ drawerWidth, ...props });
	return (
		<Fragment>
			<div className={classes.wrap}>
				<div
					className={`${classes.mainArea} ${editor ? classes.editorArea : ''} ${
						drawerOpen && persistentDrawer ? classes.areaShift : ''
					}`}
				>
					{children}
				</div>
			</div>

			<Drawer
				variant={persistentDrawer ? 'persistent' : 'temporary'}
				anchor="right"
				classes={{
					paper: `${classes.drawerPaper} ${
						persistentDrawer
							? prominent
								? classes.persistentDrawerPaperProminent
								: classes.persistentDrawerPaper
							: ''
					}`,
				}}
				open={drawerOpen}
				//  onClose={onClose}
				{...DrawerProps}
			>
				{drawerContent}
			</Drawer>
		</Fragment>
	);
}
