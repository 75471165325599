import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Explainer from './Explainer';
import { Typography, Link } from '../Layout';
import { UpgradeButton } from '../Billing';

const infoLinkUrls = {
	web: 'https://mopinion.com/product/mopinion-for-websites/',
	email: 'https://mopinion.com/what-is-email-feedback/',
	sdk: 'https://mopinion.com/guide-to-mobile-feedback/',
};

export default function AddToPackageExplainer({ product, ...props }) {
	const { t } = useTranslation();
	return (
		<Explainer
			title={t(`add_to_package_explainer-title_${product}`)}
			content={
				<Trans
					i18nKey={`add_to_package_explainer-content_${product}`}
					components={{
						p1: <Typography mb={2} />,
						p2: <Typography mb={2} />,
						p3: <Typography mb={2} />,
						moreInfoLink: (
							<Link
								color="inherit"
								component="a"
								target="_blank"
								href={infoLinkUrls[product]}
								mb={3}
								display="block"
							/>
						),
						upgradeButton: (
							<UpgradeButton
								color="inherit"
								data-track-event="upgrade_from_explainer"
								upgradeSubject={t(`add_to_package_explainer-subject_${product}`)}
							/>
						),
					}}
				/>
			}
			{...props}
		>
			{t(`add_to_package_explainer-label_${product}`)}
		</Explainer>
	);
}
