import React from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { useDataSourceContext, DataSourceAvatar } from '@/components/DataSources';
import { makeStyles } from '@/styles';
import { MenuItem, Typography } from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	subheader: {
		backgroundColor: '#fff',
		textAlign: 'left',
	},
	title: {
		verticalAlign: 'center',
		'& span': {
			display: 'inline-block',
			verticalAlign: 'top',
		},
		'& div': {
			display: 'inline-block',
			marginBottom: theme.spacing(-0.3),
		},
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginRight: theme.spacing(1),
	},
}));

export default function DirectInsightsQuickSelection({ selectedDatasource }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { datasource } = useDataSourceContext();

	return (
		<Select
			value={selectedDatasource.id}
			className={classes.title}
			onChange={e => {
				history.push(`/reporting/direct-insights/${e.target.value}`);
				enqueueSnackbar(
					t('reporting-insights-dashboard_quick_selection-snackbar_switched', {
						source: datasource.api.getSource(e.target.value)?.name ?? '',
					})
				);
			}}
		>
			{datasource.forms.asArray.length > 0 && (
				<ListSubheader
					className={classes.subheader}
				>{t`reporting-insights-dashboard_quick_selection-subheader_forms`}</ListSubheader>
			)}
			{datasource.forms.asArray.map((datasource, index) => {
				return (
					<MenuItem
						key={index}
						value={datasource.id}
					>
						<DataSourceAvatar
							className={classes.small}
							survey_format={datasource.survey_format}
							survey_type={datasource.survey_type}
							campaign={datasource.campaign}
						/>
						<Typography component="span">{datasource.name}</Typography>
					</MenuItem>
				);
			})}

			{datasource.datasets.asArray.length > 0 && (
				<ListSubheader
					className={classes.subheader}
				>{t`reporting-insights-dashboard_quick_selection-subheader_datasets`}</ListSubheader>
			)}
			{datasource.datasets.asArray.map((datasource, index) => {
				return (
					<MenuItem
						key={index}
						value={datasource.id}
						onClick={() => {
							if (datasource.id !== selectedDatasource.id) {
								history.push(`/reporting/direct-insights/${datasource.id}`);
								enqueueSnackbar(
									t('reporting-insights-dashboard_quick_selection-snackbar_switched', {
										source: datasource.title,
									})
								);
							}
						}}
					>
						<DataSourceAvatar
							className={classes.small}
							survey_format={datasource.survey_format}
							survey_type={datasource.survey_type}
							campaign={datasource.campaign}
						/>
						<Typography component="span">{datasource.name}</Typography>
					</MenuItem>
				);
			})}
		</Select>
	);
}
