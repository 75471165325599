import React, { Fragment } from 'react';
import { PageHeader } from '../../components/App';
import { FeedbackList } from '../../components/Feedback';
import { useTranslation } from 'react-i18next';

export default function FeedbackResults() {
	const { t } = useTranslation();
	return (
		<Fragment>
			<PageHeader title={t`Feedback inbox`} />
			<FeedbackList />
		</Fragment>
	);
}
