import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@/styles';
import {
	ActionIconButton,
	Typography,
	Grid,
	Card,
	CardActionArea,
} from '@/components/Layout';
import { DataSourceAvatar } from '../DataSources';

const useStyles = makeStyles(theme => ({
	paperRow: {
		cursor: 'pointer',
		'& button': {
			opacity: 0,
		},
		'&:hover button': {
			opacity: 1,
		},
		'& a': {
			opacity: 0,
		},
		'&:hover a': {
			opacity: 1,
		},
	},
	paper: {
		padding: theme.spacing(2),
	},
	marginRight: {
		marginRight: theme.spacing(1),
	},
	marginBottom: {
		marginBottom: theme.spacing(2),
	},
}));

export default function DirectInsightsOverviewRow({ datasource = {} }) {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();

	return (
		<>
			<Card>
				<CardActionArea className={classes.paperRow}>
					<Grid
						container
						direction="row"
						alignItems="center"
						onClick={() => {
							history.push(`/reporting/direct-insights/${datasource.id}`);
						}}
					>
						<Grid
							item
							className={classes.paper}
						>
							<DataSourceAvatar
								survey_type={datasource.survey_type}
								survey_format={datasource.survey_format}
								campaign={datasource.campaign}
							/>
						</Grid>
						<Grid
							item
							xs
							className={classes.paper}
						>
							<Typography
								fontWeight="medium"
								component="span"
							>
								{datasource.name}
							</Typography>
						</Grid>
						<Grid
							item
							className={classes.paper}
						>
							<ActionIconButton
								action="view"
								link
								to={`/reporting/direct-insights/${datasource.id}`}
								tooltip={t`reporting-dashboard_overview-dashboard_row-button_view`}
							/>
						</Grid>
					</Grid>
				</CardActionArea>
			</Card>
		</>
	);
}
