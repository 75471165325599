import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { SimpleDialog, Alert, Typography, Box } from '@/components/Layout';

export default function DeploymentPublishDialog({
	open,
	loading,
	editorType,
	description,
	newRules,
	oldRules,
	onDescriptionChange = () => {},
	onClose = () => {},
	onSubmit = () => {},
}) {
	const { t } = useTranslation();

	return (
		<SimpleDialog
			title={t`data_collection-deployment_editor-publish_dialog-title`}
			open={open}
			onClose={onClose}
			onSubmit={onSubmit}
			submit={t`data_collection-deployment_editor-publish_dialog-submit`}
			loading={loading}
			dataTrackEvent="deployment_publish_dialog_deployment_published"
		>
			<Typography
				mb={3}
			>{t`data_collection-deployment_editor-publish_dialog-content`}</Typography>
			{editorType === 'web' && (
				<Alert severity="info">
					<Trans
						i18nKey="data_collection-deployment_editor-publish_dialog-assistant_alert-content"
						components={{
							ul: <ul />,
							li: <li />,
							text: <Box />,
							strong: (
								<Box
									display="inline"
									fontWeight="fontWeightMedium"
								/>
							),
						}}
					/>
				</Alert>
			)}
		</SimpleDialog>
	);
}
