import React from 'react';

import PassiveIcon from '@material-ui/icons/TouchApp';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ProactiveIcon from '@material-ui/icons/OpenInBrowser';

import ClockIcon from '@material-ui/icons/Alarm';
import DevicesIcon from '@material-ui/icons/Devices';

import DateIcon from '@material-ui/icons/Event';
import SessionIcon from '@material-ui/icons/DateRange';

import CodeIcon from '@material-ui/icons/Code';
import CookieIcon from '@material-ui/icons/SettingsInputSvideo';
import VisitorTypeIcon from '@material-ui/icons/People';

import WebsiteIcon from '@material-ui/icons/Web';
import LocationIcon from '@material-ui/icons/MyLocation';
import ReferrerIcon from '@material-ui/icons/Directions';
import ScrollPosIcon from '@material-ui/icons/SwapVert';

import EventIcon from '@material-ui/icons/Vibration';
import TargetIcon from '@material-ui/icons/Smartphone';

import { PercIcon } from '../Icons';

function iconReducer({ value, type }) {
	switch (value) {
		case 'passive':
			return PassiveIcon;

		case 'proactive':
			return ProactiveIcon;
		case 'exit':
			return ExitIcon;
	}

	switch (type) {
		case 'clock':
		case 'time':
		case 'time_in_session':
			return ClockIcon;

		case 'cookie':
			return CookieIcon;

		case 'css_selector':
		case 'js':
			return CodeIcon;

		case 'date':
			return DateIcon;

		case 'session':
			return SessionIcon;

		case 'devices':
			return DevicesIcon;

		case 'location':
			return LocationIcon;

		case 'number_of_pages':
			return WebsiteIcon;

		case 'percentage':
			return PercIcon;

		case 'referrer':
			return ReferrerIcon;

		case 'scrollpos':
			return ScrollPosIcon;

		case 'type':
			return VisitorTypeIcon;

		case 'events':
			return EventIcon;

		case 'target':
			return TargetIcon;
		default:
			return null;
	}
}

export default function DeploymentIcon(props) {
	const Icon = iconReducer(props);

	return Icon ? <Icon {...props} /> : Icon;
}
