import React, { useState, Fragment, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { useTranslation, Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '../AppContext';
import Amount from './Amount';
import Stripe from './Stripe';
import BillingCardBase from './BillingCardBase';
import CreditCardForm from './CreditCardForm';
import OrganisationForm from './OrganisationForm';
import { calculatePriceTotal, isPackageIncrease } from './billingUtils';
import {
	Typography,
	Grid,
	Logo,
	ActionIconButton,
	ActionButton,
	SimpleDialog,
	ContactDialog,
	FormattedDate,
	Link,
} from '../Layout';
import { useEFM, useAjaxForm } from '../Ajax';
import CalculatorCard from './CalculatorCard';
import SelectedPackageCard from './SelectedPackageCard';
import PaymentDetailsOverviewCard from './PaymentDetailsOverviewCard';
import ConfirmCard from './ConfirmCard';
import { rnd } from '../../utils';
import { useTrackingEvents } from '../Tracking';

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: theme.palette.background.default,
	},
	heroContent: {
		padding: theme.spacing(4, 0, 2),
		maxWidth: 720,
	},
	verticalCenterChildren: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	fillHeight: {
		height: '100%',
	},
	track: {
		backgroundColor: '#000!important',
	},
	thumb: {
		color: 'rgba(0,0,0,.87)!important',
	},
	checked: {
		color: 'rgba(0,0,0,.87)!important',
	},
	pointer: {
		cursor: 'pointer',
	},
	customerLogo: {
		filter: 'grayscale(100%)',
		opacity: 0.5,
		width: '100%',
	},
	savings: {
		marginTop: theme.spacing(0.5),
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	billingSelect: {
		fontSize: theme.typography.pxToRem(24),
	},
	summaryRow: {
		color: props => (props.discount ? theme.palette.secondary.main : ''),
		fontWeight: props => (props.discount || props.total ? 600 : ''),
	},
	circle: {
		width: 80,
		height: 80,
		borderRadius: '100%',
		boxShadow: theme.shadows[4],
		backgroundColor: '#fff',
		zIndex: 1,
		marginBottom: theme.spacing(3),
		display: 'inline-flex',
		'& > svg': {
			fontSize: theme.typography.pxToRem(48),
		},
	},
	packageCardGrid: {
		[theme.breakpoints.up('md')]: {
			width: '20%',
		},
	},
	termsError: {
		textAlign: 'center',
	},
	stepper: {
		backgroundColor: 'transparent',
		marginBottom: theme.spacing(3),
	},
	paymentDetailsSubtitle: {
		marginBottom: theme.spacing(2),
	},
	cardActions: {
		justifyContent: 'space-between',
	},
	summaryStep: {
		'& > div': {
			marginBottom: theme.spacing(3),
		},
	},
}));

function getDiscount(discount = {}, billing, selectedPackage = {}) {
	if (discount.valid) {
		if (
			!discount.restrict_to_package ||
			discount.restrict_to_package.indexOf(Number(selectedPackage.id)) > -1
		) {
			switch (discount.duration) {
				case 'forever':
				case 'once':
					return discount.amount;

				case 'repeating':
					if (billing === 'monthly') {
						return discount.amount;
					}
			}
		}
	}
}

const upgradeSteps = [
	'upgrade_dialog-upgrade_step_calculator',
	'upgrade_dialog-upgrade_step_payment_details',
	'upgrade_dialog-upgrade_step_confirm_order',
];

export default function UpgradeDialog({ onClose, ...props }) {
	const [key, setKey] = useState(rnd());

	function handleClose(upgraded) {
		if (upgraded) {
			//Set a new key if the package name changed to reinit component
			//but only do so after the component has closed
			setTimeout(() => setKey(rnd()), 500);
		}
		onClose();
	}

	return (
		<Stripe>
			<Upgrade
				key={key}
				onClose={handleClose}
				{...props}
			/>
		</Stripe>
	);
}

export function Upgrade(props) {
	const { open, onClose } = props;
	const { track } = useTrackingEvents();
	useEffect(() => {
		if (open) {
			track('upgrade_package_dialog_opened');
		}
	}, [open]);
	const history = useHistory();
	const { app } = useAppContext();
	const isUpgrading = app.package.valid && !app.package.trial && !app.package.freemium;

	const [step, setStep] = useState(upgradeSteps[0]);

	const [billing, setBilling] = useState(
		app.package.payment_interval ? app.package.payment_interval : 'monthly'
	);
	const [cardToken, setCardToken] = useState('');
	const [cardName, setCardName] = useState('');

	const [showDiscountDialog, setShowDiscountDialog] = useState(false);
	const [couponValue, setCouponValue] = useState('');
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [contactSomethingWrong, setContactSomethingWrong] = useState(false);
	const [authRequired, setAuthRequired] = useState(false);

	const stripe = useStripe();

	const { enqueueSnackbar } = useSnackbar();

	function updateBilling(to) {
		if (app.package.payment_interval !== 'annual') {
			setBilling(to);
		}
	}

	const [selectedPackage, setSelectedPackage] = useState({});
	const [organisation, setOrganisation] = useState({
		valid: false,
		form: {},
	});

	const { t } = useTranslation();
	const [{ plans = {}, vat, discount = {} }, loading, error, loadData] = useEFM(
		'/account/billing/purchase',
		{},
		!app.tokens.payment
	);

	const standardPlan =
		Object.values(plans).find(plan => plan.prefix === 'standard') ?? {};
	const planConfig = Object.keys(selectedPackage)
		.filter(key => standardPlan[key]?.[billing]?.price_id)
		.map(key => ({
			price: standardPlan[key]?.[billing]?.price_id,
			quantity: selectedPackage[key],
			nickname: key,
		}));

	const totalPriceMonthly = calculatePriceTotal({
		state: selectedPackage,
		billing: 'monthly',
		plan: standardPlan,
	});
	const totalPriceYearly = calculatePriceTotal({
		state: selectedPackage,
		billing: 'annual',
		plan: standardPlan,
	});
	const saveWithYearly = totalPriceMonthly * 12 - totalPriceYearly;

	const classes = useStyles();

	const { postForm: saveConfig } = useAjaxForm({
		url: '/account/billing/save-config',
		valid: () => agreeTerms,
		data: {
			...(!isUpgrading && {
				billing_cycle: billing,
			}),
			plan_config: planConfig,
		},
	});

	const {
		postForm: savePaymentDetails,
		loading: loadingSavePaymentDetails,
		response: savePaymentDetailsResponse,
		clicked: paymentDetailsClicked,
	} = useAjaxForm({
		valid: () => organisation.valid && cardToken,
		url: '/account/billing/payment-details',
		data: {
			organisation: organisation.form,
			stripeToken: cardToken,
		},
		onSuccess: response => {
			if (response.success) {
				track('upgrade_package_standard_payment_details_filled');
				setStep(upgradeSteps[2]);
				//fetch new organistion data
				app.api.getOrganisations();
			}
		},
	});

	const handleSuccessfulUpgrade = () => {
		track('upgrade_package_standard_completed', { ...selectedPackage });
		enqueueSnackbar(t`Upgrade successful`);
		app.api.getPackage();
	};

	const {
		postForm: paymentIntentConfirmed,
		loading: loadingPaymentIntentConfirmed,
		response: paymentIntentConfirmedResponse,
	} = useAjaxForm({
		url: '/account/billing/intent-confirmed',
		onSuccess: response => {
			setAuthRequired(false);
			if (response.upgrade_success) {
				handleSuccessfulUpgrade();
			}
		},
	});
	const postBillingCycle =
		!isUpgrading ||
		(isUpgrading && app.package.payment_interval === 'monthly' && billing === 'annual');

	const {
		postForm: buyPackage,
		loading: loadingBuyPackage,
		clicked: buyClicked,
		response: buyResponse,
	} = useAjaxForm({
		url: '/account/billing/checkout',
		valid: () => agreeTerms,
		data: {
			...(postBillingCycle && {
				billing_cycle: billing,
			}),
			plan_config: planConfig,
		},
		onSuccess: async response => {
			if (response.upgrade_success) {
				handleSuccessfulUpgrade();
				return;
			}

			if (response.authentication?.required) {
				setAuthRequired(true);
				track('upgrade_package_standard_requires_confirm_card', { ...selectedPackage });
				const result = await stripe.confirmCardPayment(
					response.authentication.client_secret,
					{
						payment_method: response.authentication.payment_method_id,
					}
				);

				setAuthRequired(false);

				if (result.paymentIntent && !result.error) {
					paymentIntentConfirmed();
					return;
				}

				if (result.error) {
					enqueueSnackbar(t(`stripe_error_${result.error.code}`));
					return;
				}

				enqueueSnackbar(
					t`If you keep getting this message please contact support@mopinion.com.`
				);
			}
		},
	});

	const { postForm: validateCoupon, loading: loadingValidateCoupon } = useAjaxForm({
		url: '/account/ajax/validate-coupon',
		data: {
			discount_code: couponValue,
		},
		onSuccess: response => {
			if (response.valid) {
				enqueueSnackbar(t`Discount applied`);
			} else {
				enqueueSnackbar(t`Discount code not valid`);
			}

			if (response.reload) loadData();
			setShowDiscountDialog(false);
		},
	});

	const { postForm: removeCoupon, loading: loadingRemoveCoupon } = useAjaxForm({
		url: '/account/ajax/validate-coupon',
		data: {
			discount_code: '',
		},
		onSuccess: response => {
			enqueueSnackbar(t`Discount removed`);
			loadData();
		},
	});

	const upgradeSuccesful =
		buyResponse.upgrade_success || paymentIntentConfirmedResponse.upgrade_success;

	function getDiscountText() {
		if (
			Array.isArray(discount.restrict_to_package) &&
			discount.restrict_to_package.indexOf(Number(selectedPackage.id)) === -1
		) {
			return t`This discount code is not valid for your selected package`;
		}

		const times = discount.months ? discount.months : 1;

		switch (discount.duration) {
			case 'forever':
				return `${discount.amount}${
					discount.type === 'percentage' ? '%' : ''
				} ${t`off for your entire subscription`}`;
			case 'once':
				return `${discount.amount}${discount.type === 'percentage' ? '%' : ''} ${`off`} ${
					times === 1 ? t`once` : times + ' ' + t`times`
				}`;
			case 'repeating':
				return `${discount.amount}${
					discount.type === 'percentage' ? '%' : ''
				} ${`off for`} ${times} ${times === 1 ? t`month` : t`months`}`;
		}

		return discount.duration === 'forever'
			? `${discount.amount}${
					discount.type === 'percentage' ? '%' : ''
			  } ${t`off for your entire subscription`}`
			: discount`${discount.amount}${
					discount.type === 'percentage' ? '%' : ''
			  } ${`off for`} ${discount.months} ${t`months`}`;
	}

	function handleClose() {
		onClose(upgradeSuccesful);
		if (upgradeSuccesful) {
			history.push('/account/billing/success');
		}
	}

	function setCalculatorSelection(selection) {
		saveConfig();
		setSelectedPackage(selection);
		setStep(isUpgrading ? upgradeSteps[2] : upgradeSteps[1]);
		track('upgrade_package_standard_calculator_confirm', {
			...selection,
			isUpgrading,
		});
	}

	const disabledInputAndClose = loadingBuyPackage || authRequired;

	return (
		<Dialog
			open={open ?? false}
			onClose={handleClose}
			fullScreen
			disableAutoFocus
			disableEnforceFocus
			disableRestoreFocus
		>
			<DialogContent className={classes.content}>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						item
						xs
					>
						<Logo
							withText
							onBg="light"
						/>
					</Grid>
					<Grid item>
						<ActionIconButton
							action="close"
							onClick={handleClose}
							disabled={disabledInputAndClose}
							dataTrackEvent="upgrade_package_"
						/>
					</Grid>
				</Grid>
				<Container
					maxWidth={false}
					component="main"
					className={classes.heroContent}
				>
					{!upgradeSuccesful && (
						<Fragment>
							{app.package.trial && (
								<Stepper
									className={classes.stepper}
									activeStep={upgradeSteps.indexOf(step)}
								>
									{upgradeSteps.map((label, index) => {
										return (
											<Step
												key={label}
												onClick={() => {
													if (
														upgradeSteps.indexOf(step) !== 0 &&
														index < upgradeSteps.indexOf(step)
													) {
														setStep(step => upgradeSteps[index]);
													}
												}}
											>
												<StepLabel>{t(label)}</StepLabel>
											</Step>
										);
									})}
								</Stepper>
							)}
							{step === upgradeSteps[0] && (
								<Fragment>
									<CalculatorCard
										vat={vat}
										billing={billing}
										updateBilling={updateBilling}
										plan={standardPlan}
										loading={loading}
										onValidSelect={selection => {
											setCalculatorSelection(selection);
										}}
										selectedPackage={selectedPackage}
									/>
									{app.package.trial && (
										<Box mt={4}>
											<Box mb={1}>
												<Typography
													variant="subtitle1"
													color="textSecondary"
												>
													{t`upgrade_dialog-upgrade_logo_tag_line`}
												</Typography>
											</Box>
											<Grid
												container
												justify="center"
												spacing={3}
											>
												{['klm', 'decathlon', 'expedia', 'paypal', 'kpn', 'walmart'].map(
													customer => (
														<Grid
															item
															xs={4}
															sm={2}
															key={customer}
														>
															<img
																src={`/assets/img/customers/logo_${customer}.svg`}
																className={classes.customerLogo}
															/>
														</Grid>
													)
												)}
											</Grid>
										</Box>
									)}
								</Fragment>
							)}

							{step === upgradeSteps[1] && (
								<BillingCardBase title={t`upgrade_dialog-payment_details_title`}>
									<Box mb={4}>
										<Typography
											variant="subtitle"
											className={classes.paymentDetailsSubtitle}
										>
											{t`upgrade_dialog-payment_details_credit_card_subtitle`}
										</Typography>
										<CreditCardForm
											onCardChange={({ token, name }) => {
												setCardToken(token);
												setCardName(name);
											}}
											showErrors={paymentDetailsClicked}
											disabled={disabledInputAndClose}
										/>
									</Box>

									<Box mb={4}>
										<Typography
											variant="subtitle"
											className={classes.paymentDetailsSubtitle}
										>
											{t`upgrade_dialog-payment_details_organisation_subtitle`}
										</Typography>
										<OrganisationForm
											clicked={paymentDetailsClicked}
											onValidChange={(valid, form) => setOrganisation({ valid, form })}
											disabled={disabledInputAndClose}
										/>
									</Box>
									<Box
										display="flex"
										justifyContent="space-between"
									>
										<ActionButton
											data-track-event="upgrade_package_standard_payment_details_back"
											onClick={() =>
												setStep(isUpgrading ? upgradeSteps[2] : upgradeSteps[0])
											}
										>
											{isUpgrading
												? t`upgrade_dialog-payment_details_cancel_change`
												: t`upgrade_dialog-payment_details_back`}
										</ActionButton>
										<ActionButton
											color="primary"
											variant="contained"
											onClick={savePaymentDetails}
											loading={loadingSavePaymentDetails}
										>
											{isUpgrading
												? t`upgrade_dialog-payment_details_change_details`
												: t`upgrade_dialog-payment_details_next`}
										</ActionButton>
									</Box>
								</BillingCardBase>
							)}

							{step === upgradeSteps[2] && (
								<div className={classes.summaryStep}>
									<SelectedPackageCard
										selectedPackage={selectedPackage}
										onEdit={() => setStep(upgradeSteps[0])}
										disableAction={disabledInputAndClose}
									/>

									<PaymentDetailsOverviewCard
										onEdit={() => setStep(upgradeSteps[1])}
										disableAction={disabledInputAndClose}
									/>

									<ConfirmCard
										loading={loadingBuyPackage || authRequired}
										setAgreeTerms={setAgreeTerms}
										agreeTerms={agreeTerms}
										billing={billing}
										updateBilling={updateBilling}
										totalAmount={
											billing === 'monthly' ? totalPriceMonthly : totalPriceYearly
										}
										saveWithYearly={saveWithYearly}
										vat={vat}
										isPackageIncrease={isPackageIncrease(selectedPackage, app.package)}
										onBack={() => {
											if (isUpgrading) {
												setStep(upgradeSteps[0]);
											} else {
												setStep(upgradeSteps[1]);
											}
										}}
										showErrors={buyClicked}
										onConfirm={buyPackage}
										isUpgrading={isUpgrading}
									/>
								</div>
							)}
						</Fragment>
					)}
					{upgradeSuccesful && (
						<Fragment>
							<BillingCardBase title={t`upgrade_dialog-upgrade_success_card-title`}>
								<Typography gutterBottom>
									{t(`upgrade_dialog-upgrade_success_card-success_confirm_message`, {
										email: app.users.current.email,
									})}
								</Typography>
								<Typography>
									<Trans
										i18nKey="upgrade_dialog-upgrade_success_card-success_summary_message"
										values={{
											interval:
												billing === 'monthly'
													? t`upgrade_dialog-upgrade_success_card-billing_monthly`
													: t`upgrade_dialog-upgrade_success_card-billing_yearly`,
										}}
										components={{
											date: <FormattedDate date={new Date()} />,
											amount: (
												<Amount
													amount={
														billing === 'monthly' ? totalPriceMonthly : totalPriceYearly
													}
												/>
											),
										}}
									/>
								</Typography>
								<Box mt={4}>
									<Grid
										container
										alignItems="center"
										spacing={1}
									>
										<Grid
											item
											xs
										>
											<ActionButton
												color="primary"
												variant="contained"
												onClick={handleClose}
											>
												{t`upgrade_dialog-upgrade_success_card-success_button`}
											</ActionButton>
										</Grid>
										<Grid item>
											<Typography>
												<Trans
													i18nKey="upgrade_dialog-upgrade_success_card-something_wrong_text"
													components={{
														somethingWrongLink: (
															<Link onClick={() => setContactSomethingWrong(true)} />
														),
													}}
												/>
											</Typography>
										</Grid>
									</Grid>
								</Box>
							</BillingCardBase>
						</Fragment>
					)}
				</Container>
			</DialogContent>

			<SimpleDialog
				open={showDiscountDialog}
				onClose={() => setShowDiscountDialog(false)}
				title={t`Apply your discount coupon code`}
				submit={t`Apply discount`}
				onSubmit={validateCoupon}
				dataTrackEvent="upgrade_package_discount"
				loading={loadingValidateCoupon}
			>
				<TextField
					fullWidth
					label={t`Enter discount code`}
					value={couponValue}
					onChange={e => setCouponValue(e.currentTarget.value)}
				/>
			</SimpleDialog>

			<ContactDialog
				open={contactSomethingWrong}
				onClose={() => setContactSomethingWrong(false)}
				mailTo="success@mopinion.com"
				title={t`upgrade_dialog-something_wrong_contact_title`}
				subject={t`upgrade_dialog-something_wrong_contact_subject`}
				dataTrackEvent="upgrade_package_something_wrong"
			/>
		</Dialog>
	);
}
