import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

import set from 'lodash.set';
import { Link as RouterLink } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../AppContext';
import { TextEditor } from '../TextEditor';
import { ValidatedField } from '../Validation';
import { UserAutoComplete } from '../AutoComplete';
import FeedbackHighlights from './FeedbackHighlights';
import FeedbackMeta from './FeedbackMeta';
import FeedbackSummary from './FeedbackSummary';
import { SimpleDialog, Typography, ActionIconButton } from '../Layout';
import { format } from '../../utils/date';
import { useAjaxForm, useEFM } from '../Ajax';
import { useLocalStorage } from '../../hooks';
import { ruleTests } from '../Validation';

const useStyles = makeStyles(theme => ({
	fixColumn: {
		'& > div': {
			width: '100%',
		},
	},
	shrinkPadding: {
		paddingTop: theme.spacing(2),
	},
}));

function mapDetailsToTableData(data = {}) {
	const tableData = {};
	set(tableData, 'feedback.data', data.feedback_details);
	set(tableData, 'metadata.data', data.meta_vars);
	set(tableData, 'technical.data', data.technical_vars);
	set(tableData, 'customer.data', data.customer);

	return JSON.stringify(tableData);
}

function messageValid(value) {
	return String(value).length > 0 && value !== '<p></p>\n';
}

function htmlToEditorState(html) {
	const blocksFromHtml = htmlToDraft(html);
	const { contentBlocks, entityMap } = blocksFromHtml;
	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
	const editorState = EditorState.createWithContent(contentState);
	return editorState;
}

const initialFormState = {
	to: '',
	forwardTo: '',
	cc: '',
	reply_to: '',
	copy: false,
	answer: '',
	message: '',
	subject: '',
	sender: '',
};

export default function FeedbackShareDialog({
	open,
	onClose = () => {},
	reply,
	replyTo,
	feedback_id,
	onSuccess = () => {},
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();

	const [recapFeedback, setRecapFeedback] = useLocalStorage(
		`recap-feedback-reply-${app.organisations.current.id}`,
		false
	);

	const [answerData] = useEFM('/application/email/answer', {}, !open || !feedback_id);
	const [details] = useEFM(
		`/dashboard/inbox/detail/${feedback_id}`,
		{},
		!open || !feedback_id
	);

	const [form, setForm] = useState(initialFormState);
	useEffect(() => {
		setForm(initialFormState);
	}, [feedback_id]);

	function setFormValue(key, value) {
		setForm(prev => ({
			...prev,
			[key]: value,
		}));
	}

	const [messageState, setMessageState] = useState(EditorState.createEmpty());
	useEffect(() => {
		let raw = '';
		try {
			raw = draftToHtml(convertToRaw(messageState.getCurrentContent()));
		} catch (e) {}
		setFormValue('message', raw);
	}, [messageState]);

	useEffect(() => {
		const feedbackDate = format(
			new Date(details?.feedback?.feedback_created ?? null),
			app.date.format
		);
		if (reply) {
			const defaultMessage = t(
				'This email is a reply to your Mopinion feedback to {{org_name}} on {{feedbackDate}} by {{replyTo}}.',
				{
					org_name: app.organisations.current.name,
					feedbackDate,
					replyTo,
				}
			);

			const highlights = Object.values(details?.feedback_details ?? {})
				//dont show if screenshot types and website data
				.filter(
					({ var_type, question_type }) =>
						[40, 41, 42].indexOf(var_type) === -1 && question_type !== 'website_data'
				)
				.map(({ label, value }) => `<em>${label}</em><br>${value}`)
				.join('<br>');

			const message = `${defaultMessage} ${
				highlights
					? `\n\n<strong>${t`This was your feedback`}</strong><br>${highlights}`
					: ''
			}`;

			setMessageState(htmlToEditorState(message));

			setFormValue(
				'subject',
				t('Answer to your feedback on {{date}}', { date: feedbackDate })
			);
		} else {
			setMessageState(EditorState.createEmpty());
			setFormValue(
				'subject',
				t('Check out this feedback item from {{date}}', { date: feedbackDate })
			);
		}
	}, [
		reply,
		feedback_id,
		replyTo,
		app.organisations.current.name,
		details?.feedback?.feedback_created,
	]);

	const sendingUser = form.reply_to
		? app.users.asArray.find(user => user.email == form.reply_to)
		: app.users.current;

	function getAdminValues(type) {
		switch (type) {
			case 'admin_fullname':
				return `${sendingUser.firstname} ${sendingUser.lastname}`;

			case 'admin_firstname':
				return sendingUser.firstname;

			case 'admin_lastname':
				return sendingUser.lastname;

			case 'admin_email':
				return sendingUser.email;

			case 'admin_telephone':
				return sendingUser.telephone;

			default:
				return null;
		}
	}

	function setAnswer(value, answer) {
		const replaceValues = str =>
			str.replace(/{{(.+?)}}/g, (fullMatch, innerMatch) => {
				if (getAdminValues(innerMatch)) {
					return getAdminValues(innerMatch);
				} else {
					const field = Object.values(details?.datafield_vars).find(feedbackVar => {
						const [systemVar, datafieldId] = innerMatch.split(':::');
						return (
							feedbackVar.data_field_var == systemVar ||
							feedbackVar.data_field_id == datafieldId
						);
					});
					return field ? field.value : '';
				}
			});

		if (answer) {
			//replace values in standard answer if possible
			const replacedAnswer = replaceValues(answer.answer);
			const editorState = htmlToEditorState(replacedAnswer);

			setFormValue('answer', value);
			setMessageState(editorState);
			if (answer.subject) {
				setFormValue('subject', replaceValues(answer.subject));
			}
		} else if (!value) {
			setFormValue('answer', '');
			setMessageState(EditorState.createEmpty());
		}
	}

	const { postForm, loading, clicked } = useAjaxForm({
		url: '/application/email/send',
		data: {
			...(reply && { to: replyTo }),
			...(!reply && { forwardTo: form.forwardTo }),
			...(reply && { reply_to: form.reply_to }),
			cc: form.cc,
			copy: form.copy ? 'yes' : 'no',
			answer: form.answer,
			message: form.message,
			subject: form.subject,
			id: feedback_id,
			type: 'feedback',
			...(!reply && { tableData: mapDetailsToTableData(details) }),
		},
		valid: () => {
			if (reply) {
				return messageValid(form.message);
			} else {
				return form.forwardTo;
			}
		},
		successMessage: t`Email sent`,
		onSuccess: r => {
			onClose();
			onSuccess(r);
		},
	});

	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			title={
				<Grid
					container
					alignItems="center"
				>
					<Grid
						item
						xs
					>
						{reply ? t`Reply to customer` : t`Share feedback`}
					</Grid>
					<Grid item>
						<ActionIconButton
							action={recapFeedback ? 'less_detail' : 'detail'}
							tooltip={
								recapFeedback ? t`Hide feedback details` : t`Show feedback details`
							}
							onClick={() => setRecapFeedback(prev => !prev)}
							// size="small"
							IconProps={{
								fontSize: 'small',
							}}
						/>
					</Grid>
				</Grid>
			}
			submit={reply ? t`Reply` : t`Share`}
			loading={loading}
			onSubmit={postForm}
			maxWidth={recapFeedback ? 'lg' : 'sm'}
			dataTrackEvent={
				reply ? 'inbox_feedback_replied_email' : 'inbox_feedback_shared_email'
			}
		>
			<Grid
				container
				direction={recapFeedback ? 'row' : 'column'}
				spacing={5}
				className={`${classes.fixColumn}`}
			>
				<Grid
					item
					xs={recapFeedback ? 7 : 12}
				>
					<Grid
						container
						direction="column"
						spacing={3}
						className={classes.fixColumn}
					>
						<Grid
							item
							xs
						>
							{reply ? (
								<TextField
									disabled
									fullWidth
									label={t`To`}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Chip
													avatar={<Avatar>{String(replyTo).charAt(0)}</Avatar>}
													label={replyTo}
												/>
											</InputAdornment>
										),
									}}
								/>
							) : (
								<ValidatedField
									component={UserAutoComplete}
									label={t('To')}
									placeholder={t('email@company.com')}
									selectedItem={[{ email: form.forwardTo }]}
									onChange={user =>
										setFormValue(
											'forwardTo',
											user.email ? user.email : user.label ? user.label : ''
										)
									}
									renderChips
									autocompleteLabel="email"
									validateValue={form.forwardTo}
									rules={[['required', true]]}
									showErrors={clicked}
									dataTestElement="feedbackForwardToInput"
								/>
							)}
						</Grid>
						<Grid
							item
							xs
						>
							<UserAutoComplete
								label={t('CC')}
								placeholder={t('email@company.com')}
								multiple
								autocompleteLabel="email"
								selectedItem={[{ email: form.cc.split(',') }]}
								onChange={users => {
									const emails = users
										.filter(user => user.email || ruleTests.email(user.label))
										.map(user => user.email || user.label)
										.join(',');
									setFormValue('cc', emails);
								}}
							/>
						</Grid>

						{reply && (
							<Grid
								item
								xs
							>
								<UserAutoComplete
									label={t`Reply to`}
									placeholder={t('noreply@mopinion.com')}
									helperText={t`The email will be sent by noreply@mopinion.com but the recipient can reply to the selected email address`}
									multiple={false}
									selectedItem={[{ email: form.reply_to }]}
									onChange={user => setFormValue('reply_to', user.email)}
									autocompleteLabel="email"
									renderChips
									disableCustomEntry
									onlyVerifiedUsers
									dataTestElement="feedbackReplyToAutocomplete"
								/>
							</Grid>
						)}
						<Grid
							item
							xs
						>
							<TextField
								label={t`Subject`}
								placeholder={
									reply ? t`Answer to your feedback...` : t`Check out this feedback item`
								}
								value={form.subject}
								fullWidth
								onChange={e => setFormValue('subject', e.target.value)}
							/>
						</Grid>
						<Grid
							item
							xs
						>
							<TextField
								select
								label={t`Standard answer`}
								fullWidth
								value={form.answer}
								helperText={
									answerData?.answers?.length === 0 ? (
										<Fragment>
											{t`You haven't created any standard answers yet.`}{' '}
											<Link
												to="/application/standard-answers"
												component={RouterLink}
												target="_blank"
											>{t`Create some`}</Link>
											.
										</Fragment>
									) : null
								}
								onChange={e => {
									const { value } = e.target;
									const foundAnswer = answerData.answers.find(ans => ans.id == value);
									setAnswer(value, foundAnswer);
								}}
							>
								<MenuItem value="">{t`None`}</MenuItem>
								{answerData?.answers?.map(answer => (
									<MenuItem
										key={answer.id}
										value={String(answer.id)}
									>
										{answer.name}
									</MenuItem>
								))}
							</TextField>
						</Grid>

						<Grid
							item
							xs
						>
							<FormControl fullWidth>
								<FormLabel
									error={reply && clicked && !messageValid(form.message)}
								>{t`Message`}</FormLabel>
								<ValidatedField
									component={TextEditor}
									surveyId={details?.feedback?.survey_id}
									fieldVars={details?.datafield_vars ?? {}}
									user={sendingUser}
									editorState={messageState}
									onChange={setMessageState}
									seperateError
									validateValue={form.message}
									rules={reply ? [['custom', value => messageValid(value)]] : [[]]}
									showErrors={clicked}
								/>
							</FormControl>
						</Grid>

						<Grid
							item
							xs
						>
							<FormControlLabel
								control={<Checkbox />}
								checked={form.copy}
								label={t`Send me a copy`}
								onChange={e => setFormValue('copy', e.target.checked)}
							/>
						</Grid>
					</Grid>
				</Grid>
				{recapFeedback && (
					<Grid
						item
						xs={5}
					>
						<Grid
							container
							direction="column"
							spacing={3}
							className={`${classes.fixColumn}`}
						>
							<Grid
								item
								xs
							>
								<Typography
									variant="subtitle2"
									gutterBottom
								>{t`Feedback`}</Typography>
								<FeedbackSummary {...details} />
								<FeedbackMeta {...details} />
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</SimpleDialog>
	);
}
