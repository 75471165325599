import React, { useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { useTranslation } from 'react-i18next';

import { SearchIcon } from '@/components/Icons';
import { makeStyles, useTheme } from '@/styles';
import { useAppContext } from '@/components/AppContext';
import { DataSourceAvatar } from '@/components/DataSources';
import { useDataSourceContext } from '@/components/DataSources';
import TextAnalyticsLanguageSelect from './TextAnalyticsLanguageSelect';
import useGetTextAnalyticsLanguages from './useGetTextAnalyticsLanguages';

import {
	Grid,
	Card,
	CardContent,
	Typography,
	TextField,
	CollapsibleBox,
	List,
	InputAdornment,
} from '@/components/Layout';

const useStyles = makeStyles(theme => ({
	gridContainer: {
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	emptyState: {
		whiteSpace: 'pre-line',
	},
	titleMargin: {
		marginBottom: theme.spacing(2),
	},
	fullWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
	maxWidth: {
		maxWidth: theme.spacing(20),
	},
	italic: {
		fontStyle: 'italic',
	},
}));

export default function TextAnalyticsFormList() {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const {
		app: { projects },
	} = useAppContext();
	const {
		datasource: { forms },
	} = useDataSourceContext();

	const [languages, languagesLoading] = useGetTextAnalyticsLanguages();

	const [searchForm, setSearchForm] = useState('');

	const filteredForms = forms.asArray.filter(
		form =>
			searchForm === '' || form.name.toLowerCase().includes(searchForm.toLowerCase())
	);

	return (
		<Card>
			<CardContent>
				<Grid
					container
					spacing={3}
					direction="column"
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							component="p"
							className={classes.titleMargin}
							variant="subtitle1"
							fontWeight="medium"
						>
							{t`reporting-text_analytics-form_language-title`}
						</Typography>
						<Typography
							component="p"
							variant="body1"
							gutterBottom
						>
							{t`reporting-text_analytics-form_language-content`}
						</Typography>
						<Typography
							component="p"
							variant="body1"
							gutterBottom
							className={classes.italic}
						>
							{t`reporting-text_analytics-form_language-info_message`}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Typography
							className={classes.titleMargin}
							variant="subtitle1"
							fontWeight="medium"
						>
							{t`reporting-text_analytics-form_language-report_title`}
						</Typography>
						<div className={classes.maxWidth}>
							<TextAnalyticsLanguageSelect
								languages={languages}
								loading={languagesLoading}
								surveyName={projects.current.name}
							/>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<CollapsibleBox
							label={
								<Typography variant="button">
									{t`reporting-text_analytics-form_language-survey_title`}
								</Typography>
							}
							ActionButtonProps={{
								style: {
									width: 'auto',
									color: theme.palette.primary.main,
								},
							}}
							startCollapsed
							mb={3}
						>
							<Grid
								container
								spacing={2}
								direction="column"
							>
								<Grid
									item
									xs={12}
								>
									<TextField
										className={classes.marginTop}
										placeholder={t`reporting-text_analytics-form_language-search`}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
										onChange={e => {
											setSearchForm(e.target.value);
										}}
										value={searchForm}
									/>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<List>
										{filteredForms.map((form, index) => {
											return (
												<ListItem key={`${form.name}_${index}`}>
													<ListItemAvatar>
														<DataSourceAvatar
															survey_format={form.survey_format}
															survey_type={form.survey_type}
															campaign={form.campaign}
														/>
													</ListItemAvatar>
													<ListItemText primary={form.name} />
													<ListItemSecondaryAction>
														<TextAnalyticsLanguageSelect
															surveyId={form.id}
															surveyName={form.name}
															languages={languages}
															loading={languagesLoading}
														/>
													</ListItemSecondaryAction>
												</ListItem>
											);
										})}
									</List>
								</Grid>
							</Grid>
						</CollapsibleBox>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
