import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAppContext } from '../AppContext';
import { useAjaxForm } from '../Ajax';
import { SimpleDialog, Typography, ActionButton } from '../Layout';
import { ValidatedField } from '../Validation';

export default function AllowedDomainsDialog({
	open,
	onClose = () => {},
	product,
	setUpgrade = () => {},
	...props
}) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { enqueueSnackbar } = useSnackbar();

	const [domains, setDomains] = useState([]);
	const [didChange, setDidChange] = useState(false);

	const { postForm, loading, clicked } = useAjaxForm({
		valid: () => didChange,
		url: '/account/ajax/allowed-domains',
		data: {
			domains,
		},
		onSuccess: response => {
			if (response.success) {
				enqueueSnackbar(t(`allowed_domains_dialog-success_snackbar-${product}`));
				app.api.getPackage();
				onClose();
			}
		},
	});

	function updateDomain(index, value) {
		setDidChange(true);
		setDomains(prev => {
			return prev.map((domain, domainIndex) => {
				if (index === domainIndex) {
					return {
						...domain,
						value,
					};
				}

				return domain;
			});
		});
	}

	useEffect(() => {
		if (!product) return;
		try {
			const currentDomains =
				app.package.allowed_domains?.filter(domain => domain.type === product) ?? [];
			const needToRegisterDomains = [
				...Array(app.package[product] - currentDomains.length),
			].map(x => {
				return {
					type: product,
					value: '',
				};
			});

			setDomains([...currentDomains, ...needToRegisterDomains]);
		} catch (e) {}
	}, [product, app.package]);

	return (
		<SimpleDialog
			title={
				<Box
					display="flex"
					justifyContent="space-between"
				>
					<Box>
						{t(`allowed_domains_dialog-title-${product}`, {
							count: app.package[product],
						})}
					</Box>
					<ActionButton
						color="primary"
						onClick={() => {
							onClose();
							setUpgrade();
						}}
					>{t`allowed_domains_dialog-upgrade_button`}</ActionButton>
				</Box>
			}
			submit={t(`allowed_domains_dialog-submit-${product}`)}
			onSubmit={postForm}
			open={open}
			onClose={onClose}
			loading={loading}
			{...props}
		>
			<Box mb={3}>
				<Typography variant="body1">
					{t(`allowed_domains_dialog-description-${product}`)}
				</Typography>
			</Box>
			{domains.map((domain, index, self) => {
				return (
					<Box
						key={index}
						mb={index === self.length - 1 ? 0 : 2}
					>
						<ValidatedField
							label={t(`allowed_domains_dialog-textfield_label-${product}`, {
								index: index + 1,
							})}
							value={domain.value}
							onChange={e => updateDomain(index, e.target.value)}
							fullWidth
							rules={{
								domain: ['web_domains', 'email_brands'].includes(product),
								required: true,
							}}
							messages={{
								domain: t(`allowed_domains_dialog-domain_error-${product}`),
								required: t(`allowed_domains_dialog-required_error-${product}`),
							}}
							showErrorsAfterBlur
							helperText={
								index === self.length - 1
									? t(`allowed_domains_dialog-helper_text-${product}`)
									: null
							}
						/>
					</Box>
				);
			})}
		</SimpleDialog>
	);
}
