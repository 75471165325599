import React, { useEffect, Fragment, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { useSnackbar } from 'notistack';
import { format } from '../../utils/date';
import parseISO from 'date-fns/parseISO';

import { useAjaxForm, useEFM } from '../Ajax';
import { useAppContext } from '../AppContext';
import { useDataSourceContext } from '../DataSources';
import { tryParse } from '../../utils';
import { bigExport, exportLimit } from './constants';
import { exportBase } from './exportUtils';
import { Typography, SimpleDialog, Alert, Loader } from '../Layout';
import { useListFilterContext } from '../FilteredLists';
import FeedbackQuickExport from './FeedbackQuickExport';
import FeedbackExportFilters from './FeedbackExportFilters';
import FeedbackExportDataFields from './FeedbackExportDataFields';
import FeedbackExportSettings from './FeedbackExportSettings';

const useStyles = makeStyles(theme => ({
	grid: {
		'& > div': {
			width: '100%',
		},
	},
	stepper: {
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 0,
	},
	error: {
		color: theme.palette.error.main,
	},
	wrap: {
		flexGrow: 1,
		minHeight: 175,
		height: '100%',
		maxWidth: '100%',
	},
	menuDefault: {
		display: 'none',
	},
	inline: {
		display: 'inline-block',
	},
	marginBottom: {
		marginBottom: theme.spacing(3),
	},
}));

function getSteps() {
	return [
		{ label: 'Select source', value: 'source' },
		{ label: 'Select filters', value: 'filters' },
		{ label: 'Export settings', value: 'export_settings' },
	];
}

function getStepValue(index, key = 'value') {
	return getSteps()[index][key];
}

function convertCustomFilters(customFilters = []) {
	return customFilters.map(filter => {
		return {
			type: 'datafield',
			identifier: Number(filter.data_field_id),
			operator: filter.operator ?? 'e',
			value: filter.values,
		};
	});
}

export default function FeedbackExportDialog({
	open,
	onClose = () => {},
	sourceId,
	variant = 'single',
	exportData = {},
	templatesFromConfig = [],
	withUuid,
	maxSetupsReached = false,
	...props
}) {
	const classes = useStyles();
	const {
		app,
		app: { date, users },
	} = useAppContext();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dialogRef = useRef(null);
	const { pathname } = useLocation();

	const { datasource } = useDataSourceContext();

	const [templates, templatesLoading, templatesError, templatesLoadData] = useEFM(
		'/dashboard/ajax/fetch-export-templates'
	);

	const [activeStep, setActiveStep] = useState(0);

	const [initialLoaded, setInitialLoaded] = useState(false);
	const [countingFeedback, setCountingFeedback] = useState(false);

	const currentDate = format(new Date(), date.format);
	const exportBaseName = app.users.current.firstname + '_' + currentDate;

	const [exportConfig, setExportConfig] = useImmer(
		exportBase(date.fromDate, date.toDate)
	);

	const filterIsEmpty =
		exportConfig.data_field_query.length === 0 &&
		typeof Object.values(exportConfig.filters).find(value => value !== '') ===
			'undefined';

	const { filters, other, customFilters, customView } = useListFilterContext();

	const [customViewData, customViewsLoading, err, loadCustomData] =
		useEFM('/filters/get-filter');
	const customViews =
		customViewData?.data
			?.filter(view =>
				view.rules.every(
					rule =>
						datasource.api.getField(rule.identifier)?.project_id ==
						app.projects.current.id
				)
			)
			.map(view => {
				const description = tryParse(view.description);
				view.rules = view.rules.map(rule => {
					rule.surveyId = description.survey_id;
					return rule;
				});
				return view;
			}) ?? [];

	useEffect(() => {
		if (
			(open && exportData.uuid && exportData.export) ||
			(open && exportData.uuid && exportData.kwargs) ||
			(open &&
				exportConfig.template_selected &&
				exportConfig.templates.find(
					template => template.uuid === exportConfig.template_selected
				))
		) {
			//update a template or schedule, or turn template into schedule
			const whichData =
				templatesFromConfig.length && exportConfig.template_selected
					? templatesFromConfig.find(
							template => template.uuid === exportConfig.template_selected
					  )
					: exportConfig.templates.length && exportConfig.template_selected
					? exportConfig.templates.find(
							template => template.uuid === exportConfig.template_selected
					  )
					: exportData;
			const exportObj = whichData.kwargs ? whichData.kwargs : whichData.export;

			//pre-convert some nested values to match front end filters
			const withActions =
				exportObj.filters.with_actions !== null
					? exportObj.filters.with_actions
						? '>'
						: '='
					: false;
			const withComments =
				exportObj.filters.with_comments !== null
					? exportObj.filters.with_comments
						? '>'
						: '='
					: false;
			const withConversation =
				exportObj.filters.with_email_conversations !== null
					? exportObj.filters.with_email_conversations
						? '>'
						: '='
					: false;

			setExportConfig(draft => ({
				...draft,
				export_name: ['use_template', 'run_schedule', 'change_schedule_status'].includes(
					variant
				)
					? whichData.name
					: exportObj.name,
				variant: variant,
				uuid: withUuid && whichData.uuid,
				dialog_status: [
					'quick',
					'use_template',
					'run_schedule',
					'change_schedule_status',
				].includes(variant)
					? 'intro'
					: 'content',
				...(whichData?.beat?.every && { interval_period: whichData.beat.every }),
				...(whichData?.beat?.on && { interval_day: whichData.beat.on }),
				...(whichData?.beat?.at && {
					interval_time: ['Quarter', 'Year'].includes(whichData.beat.every)
						? '-'
						: whichData.beat.at,
				}),
				...(whichData.last_run_at && { last_run_at: whichData.last_run_at }),
				...(whichData.next_run_at && { next_run_at: whichData.next_run_at }),
				...(exportObj.format && { format: exportObj.format }),
				...(exportObj.notify_to && {
					notify_to: exportObj.notify_to.map(id => String(id)),
				}),
				...(exportObj.prettify_labels && { prettify_labels: exportObj.prettify_labels }),
				...(exportObj.rename_labels && { rename_labels: exportObj.rename_labels }),
				...(exportObj.split_into_sheets && {
					split_into_sheets: exportObj.split_into_sheets,
				}),
				...(exportObj.filters?.survey_ids && {
					survey_ids: exportObj.filters.survey_ids,
					other: { survey_id: exportObj.filters.survey_ids },
				}),
				...(exportObj.filters?.data_field_ids && {
					datafield_ids: exportObj.filters?.data_field_ids,
				}),
				...(exportObj.filters?.data_field_ids && { datafield_selection: 'custom' }),
				...(exportObj.filters?.data_field_query && {
					customFilters: convertCustomFilters(exportObj.filters?.data_field_query),
				}),
				...(exportObj.filters && {
					filters: {
						...exportObj.filters,
						...(exportObj.filters?.tag_names && { tags: exportObj.filters?.tag_names }),
						...(withActions && { actions: withActions }),
						...(withComments && { comment: withComments }),
						...(withConversation && { email: withConversation }),
					},
				}),
				...(exportObj.sorting && {
					order: exportObj.sorting.ascending ? 'asc' : 'desc',
					sort:
						exportObj.sorting.order_by === 'id'
							? 'feedback_created'
							: 'customer_lastname',
				}),
				...(variant !== 'change_schedule_status' && { enabled: whichData.enabled }),
				...(['scheduled', 'run_schedule', 'change_schedule_status'].includes(variant) &&
					whichData.name && { schedule_name: whichData.name }),
				...(['scheduled', 'run_schedule', 'change_schedule_status'].includes(variant) &&
					whichData.description && { schedule_description: whichData.description }),
				...(variant === 'template' &&
					whichData.name && { template_name: whichData.name }),
				...(variant === 'template' &&
					whichData.description && { template_description: whichData.description }),
				...(variant === 'use_template' &&
					whichData.uuid && { template_selected: whichData.uuid }),
				...(variant === 'use_template' &&
					whichData.uuid && {
						data_field_query: exportObj?.filters?.data_field_query ?? [],
					}),
				...(variant === 'run_schedule' && { to_date: currentDate }),
				...(variant === 'run_schedule' &&
					whichData.last_run_at && {
						from_date: format(parseISO(whichData.last_run_at), app.date.format),
					}),
				...(variant === 'run_schedule' && { run_immediately: true }),
				...(variant === 'change_schedule_status' && { enabled: !whichData.enabled }),
				...(['scheduled', 'change_schedule_status'].includes(variant) && {
					run_immediately: false,
				}), //just to be sure
				templates: templatesFromConfig.length
					? templatesFromConfig
					: templates?.message?.templates ?? [],
			}));
		} else {
			//single export or new schedule
			const selectedView = customViews.find(view => view.id === customView);
			const filterQuery =
				customFilters.length > 0
					? createExportFilter(customFilters)
					: selectedView
					? createExportFilter(selectedView.rules)
					: [];

			//unfortunately labels in SQL api differ from export api, so lets map them here.
			const mappedFilters = {
				...(filters.os && {
					os: filters.os.map(os => {
						if (os === 'windows') {
							return 'Windows';
						}
						if (os === 'macos') {
							return 'Mac OS';
						}
						if (os === 'ios') {
							return 'iOS';
						}
						if (os === 'android') {
							return 'Android';
						}
						if (os === 'chromeos') {
							return 'Chrome OS';
						}
						if (os === 'linux') {
							return 'Linux';
						}
						if (os === 'other') {
							return 'Other';
						}
					}),
				}),
				...(filters.browsers && {
					browsers: filters.browsers.map(browser => {
						if (browser === 'chrome') {
							return 'Chrome';
						}
						if (browser === 'safari') {
							return 'Safari';
						}
						if (browser === 'firefox') {
							return 'Firefox';
						}
						if (browser === 'edge') {
							return 'Edge';
						}
						if (browser === 'internet_explorer') {
							return 'Explorer';
						}
						if (browser === 'opera') {
							return 'Opera';
						}
						if (browser === 'other') {
							return 'Other';
						}
					}),
				}),
				...(filters.devices && {
					devices: filters.devices.map(device => {
						if (device === 'desktop') {
							return 'Desktop';
						}
						if (device === 'tablet') {
							return 'Tablet';
						}
						if (device === 'mobile') {
							return 'Mobile';
						}
					}),
				}),
			};

			setExportConfig(state => ({
				...state,
				...(pathname.match(/reporting\/inbox/) !== null && {
					filters: { ...filters, ...mappedFilters },
				}),
				dialog_status: [
					'quick',
					'use_template',
					'run_schedule',
					'change_schedule_status',
				].includes(variant)
					? 'intro'
					: 'content',
				export_name: 'export_' + exportBaseName,
				schedule_name: 'export_' + exportBaseName,
				template_name: 'export_' + exportBaseName,
				data_field_query: filterQuery,
				customView: customView,
				other: other,
				from_date: date.fromDate,
				to_date: date.toDate,
				survey_ids: other.survey_id ? [other.survey_id] : [],
				customFilters: customFilters,
				variant: variant,
				notify_to: [app.users.current.id],
				interval_period: 'Day',
				interval_day: '-',
				interval_time: '06:00',
				maxSetupsReached: maxSetupsReached,
				templates: templatesFromConfig.length
					? templatesFromConfig
					: templates?.message?.templates ?? [],
			}));
		}
	}, [open, exportConfig.template_selected, templates]);

	//update templates in global object
	useEffect(() => {
		if (open && templates?.message?.templates) {
			setExportConfig(draft => {
				draft.templates = templates.message.templates.filter(
					template =>
						template.template_access === 'public' ||
						template.export.user_id == users.current.id
				);
			});
		}
	}, [templates]);

	useEffect(() => {
		if (
			(open && exportConfig.variant === 'quick') ||
			(open && exportConfig.variant === 'single' && !exportConfig.make_schedule) ||
			(open &&
				exportConfig.variant === 'use_template' &&
				exportConfig.template_selected) ||
			(open && exportConfig.variant === 'run_schedule')
		) {
			setCountingFeedback(true);
			postExists();
		} else if (open && exportConfig.variant === 'single' && exportConfig.make_schedule) {
			setExportConfig(draft => {
				draft.feedback_exist = true;
			});
		}
	}, [
		exportConfig.make_schedule,
		exportConfig.filters,
		exportConfig.other,
		exportConfig.survey_ids,
		exportConfig.to_date,
		exportConfig.from_date,
		JSON.stringify(exportConfig.data_field_query),
	]);

	const { postForm, loading, clicked } = useAjaxForm({
		url: '/dashboard/ajax/add-single-export',
		data: getAPIdata(),
		onSuccess: r => {
			if (r.message?.task_uuid) {
				setExportConfig(draft => {
					draft.dialog_status = 'thanks';
				});
				postCount();
			} else {
				enqueueSnackbar(
					t`Sorry we couldn't process your export at this time, try again later or contact our support.`
				);
			}
		},
		onError: () => {
			enqueueSnackbar(t`Something went wrong, please try again later`);
		},
	});

	//filter if at least 1 feedback item is found with current filters, else show message.
	const {
		postForm: postExists,
		loading: loadingExists,
		clicked: clickedExists,
	} = useAjaxForm({
		url: '/dashboard/ajax/export-exists',
		data: getAPIdata().filters,
		onSuccess: r => {
			if (r.message) {
				setExportConfig(draft => {
					draft.feedback_exist = r.message.feedback_exist ?? true;
				});
			}
			setInitialLoaded(true);
			setCountingFeedback(false);
		},
	});

	//count the amount of results, to estimate the export time.
	const {
		postForm: postCount,
		loading: loadingCount,
		clicked: clickedCount,
	} = useAjaxForm({
		url: '/dashboard/ajax/count-export',
		data: getAPIdata().filters,
		onSuccess: r => {
			if (r.message?.feedback_count) {
				setExportConfig(draft => {
					draft.exportSize = r.message.feedback_count;
				});
			} else {
				setExportConfig(draft => {
					//on failed count, just trigger standard message
					draft.exportSize = 1;
				});
			}
		},
	});

	//save export schedule
	const {
		postForm: postScheduled,
		loading: loadingScheduled,
		clicked: clickedScheduled,
	} = useAjaxForm({
		url: exportConfig.uuid
			? '/dashboard/ajax/edit-scheduled-export'
			: '/dashboard/ajax/add-scheduled-export',
		data: {
			name: exportConfig.schedule_name,
			uuid: exportConfig.uuid ? exportConfig.uuid : null,
			description: exportConfig.schedule_description,
			export: getAPIdata(),
			run_immediately: exportConfig.run_immediately,
			beat: {
				every: exportConfig.interval_period,
				on: exportConfig.interval_day !== '-' ? exportConfig.interval_day : null,
				at: exportConfig.interval_time !== '-' ? exportConfig.interval_time : '06:00',
			},
			enabled: exportConfig.enabled,
		},
		onSuccess: r => {
			enqueueSnackbar(
				exportConfig.variant === 'run_schedule'
					? t`Your schedule has been exported`
					: exportConfig.variant === 'change_schedule_status '
					? t`Your schedule status has been changed`
					: t`Your schedule has been saved`
			);
			setInitialLoaded(true);
			setCountingFeedback(false);
			resetDialog();
			onClose(true);
		},
		onError: () => {
			enqueueSnackbar(t`Something went wrong, please try again later`);
			onClose(false);
		},
	});

	//save export template
	const {
		postForm: postTemplate,
		loading: loadingTemplate,
		clicked: clickedTemplate,
	} = useAjaxForm({
		url: exportConfig.uuid
			? '/dashboard/ajax/edit-export-template'
			: '/dashboard/ajax/add-export-template',
		data: {
			name: exportConfig.template_name,
			uuid: exportConfig.uuid ? exportConfig.uuid : null,
			description: exportConfig.template_description,
			template_access: exportConfig.template_access,
			export: getAPIdata(),
			enabled: true,
		},
		onSuccess: r => {
			if (exportConfig.variant === 'template') {
				//only close in template flow, else will be closed in exportPost
				resetDialog();
				onClose(false);
			}
		},
		onError: () => {
			enqueueSnackbar(t`Something went wrong, please try again later`);
		},
	});

	function getAPIdata() {
		return {
			export_name: exportConfig.export_name,
			rename_labels: exportConfig.rename_labels,
			prettify_labels: exportConfig.prettify_labels,
			split_into_sheets: exportConfig.split_into_sheets,
			format: exportConfig.format,
			...(exportConfig.notify_to.length && { notify_to: exportConfig.notify_to }), //only send if adding scheduled export, else set user_id in controller
			filters: {
				...(exportConfig.variant !== 'scheduled' && {
					from_date: exportConfig.from_date,
				}),
				...(exportConfig.variant !== 'scheduled' && { to_date: exportConfig.to_date }),
				...(exportConfig.datafield_ids.length && {
					data_field_ids: exportConfig.datafield_ids,
				}),
				...(exportConfig.survey_ids.length && { survey_ids: exportConfig.survey_ids }),
				...(exportConfig.filters.tags && { tag_names: exportConfig.filters.tags }),
				...(exportConfig.filters.email && {
					with_email_conversations: exportConfig.filters.email === '>' ? true : false,
				}),
				...(exportConfig.filters.comment && {
					with_comments: exportConfig.filters.comment === '>' ? true : false,
				}),
				...(exportConfig.filters.actions && {
					with_actions: exportConfig.filters.actions === '>' ? true : false,
				}),
				...(exportConfig.filters.browsers && { browsers: exportConfig.filters.browsers }),
				...(exportConfig.filters.devices && { devices: exportConfig.filters.devices }),
				...(exportConfig.filters.search && { search: exportConfig.filters.search }),
				...(exportConfig.filters.os && { os: exportConfig.filters.os }),
				...(exportConfig.data_field_query.length && {
					data_field_query: exportConfig.data_field_query,
				}),
			},
			sorting: {
				ascending: exportConfig.order === 'asc' ? true : false,
				order_by: exportConfig.sort === 'feedback_created' ? 'id' : 'customer_lastname',
			},
			...(exportConfig.variant === 'scheduled' && {
				export_name: exportConfig.schedule_name,
			}),
			...(exportConfig.variant === 'template' && {
				export_name: exportConfig.template_name,
			}),
		};
	}

	function resetDialog() {
		setActiveStep(step => 0);
		setExportConfig(draft => ({
			...exportBase(
				date.fromDate,
				date.toDate,
				app.users.current.firstname + '_' + currentDate
			),
		}));
	}

	function setSingleFilter(key, value, nested) {
		if (nested) {
			setExportConfig(draft => {
				if (value) {
					draft[nested][key] = value;
				} else {
					const { [key]: removedKey, ...rest } = exportConfig[nested];
					draft[nested] = rest;
				}
			});
		} else {
			setExportConfig(draft => {
				if (value) {
					draft[key] = value;
				} else {
					const { [key]: removedKey, ...rest } = exportConfig;
					draft = rest;
				}
			});
		}
	}

	function setMultiFilter(key, value = [], nested) {
		if (nested) {
			setExportConfig(draft => {
				if (value.length) {
					draft[nested][key] = value;
				} else {
					const { [key]: removedKey, ...rest } = exportConfig[nested];
					draft[nested] = rest;
				}
			});
		} else {
			setExportConfig(draft => {
				if (value.length) {
					draft[key] = value;
				} else {
					const { [key]: removedKey, ...rest } = exportConfig;
					draft = rest;
				}
			});
		}
	}

	function setCustomFilter({ action, custom, index, view }) {
		setExportConfig(draft => {
			switch (action) {
				case 'set':
					draft.customFilters = custom;
					draft.data_field_query = createExportFilter(custom);
					draft.customView = view;
					break;
				case 'remove':
					const filtered = exportConfig.customFilters.filter((custom, i) => i !== index);

					draft.customFilters = filtered;
					draft.data_field_query = createExportFilter(filtered);
					draft.customView = 0;
					break;
				case 'add':
					draft.customFilters = [...exportConfig.customFilters, custom];
					draft.data_field_query = createExportFilter([
						...exportConfig.customFilters,
						custom,
					]);
					draft.customView = 0;
					break;
				case 'reset':
					draft.customFilters = [];
					draft.data_field_query = [];
					draft.customView = 0;
					break;
				case 'update':
					const filteredUpdate = exportConfig.customFilters.map((filter, i) => {
						if (i === index) {
							return { ...exportConfig.customFilters[i], ...custom };
						}
						return filter;
					});
					draft.customFilters = filteredUpdate;
					draft.data_field_query = createExportFilter(filteredUpdate);
					draft.customView = 0;
					break;
				default:
					return null;
			}
		});
	}

	function createExportFilter(customFilters) {
		if (!Array.isArray(customFilters)) return [];

		const queryFilters = customFilters.map(filterObj => {
			return {
				data_field_id: String(filterObj.identifier),
				operator: filterObj.operator ?? 'e',
				values: filterObj.value,
			};
		});

		return queryFilters;
	}

	function setClicked() {
		setExportConfig(draft => {
			draft.clicked = true;
		});
	}

	function isValid() {
		let validated;

		if (exportConfig.dialog_status === 'intro') {
			validated =
				exportConfig.variant === 'change_schedule_status'
					? true
					: exportConfig.variant === 'run_schedule'
					? exportConfig.feedback_exist
					: exportConfig.variant === 'use_template'
					? exportConfig.template_selected &&
					  exportConfig.feedback_exist &&
					  exportConfig.export_name.length > 0
					: exportConfig.export_name.length > 0;
		} else {
			validated = getSteps().every((step, index) => pageValid(index));
		}

		return validated;
	}

	function pageValid(which) {
		switch (getStepValue(which)) {
			case 'source':
				return exportConfig.variant === 'scheduled'
					? activeStep >= 0
					: exportConfig.feedback_exist || !filterIsEmpty;

			case 'filters':
				return exportConfig.variant === 'scheduled'
					? activeStep >= 1
					: exportConfig.feedback_exist && activeStep === 0 && exportConfig.clicked
					? true
					: exportConfig.feedback_exist && activeStep >= 1;

			case 'export_settings':
				return exportConfig.variant === 'scheduled'
					? exportConfig.schedule_name.length > 0 &&
							exportConfig.notify_to.length &&
							(activeStep === 2 || (activeStep < 2 && exportConfig.clicked))
					: exportConfig.variant === 'template'
					? exportConfig.template_name.length > 0 &&
					  (activeStep === 2 || (activeStep < 2 && exportConfig.clicked))
					: exportConfig.export_name.length > 0 &&
					  (activeStep === 2 || (activeStep < 2 && exportConfig.clicked));

			default:
				return false;
		}
	}

	return (
		<SimpleDialog
			PaperProps={{ ref: dialogRef }}
			title={
				<Fragment>
					<div>
						{exportConfig.dialog_status === 'thanks'
							? ''
							: exportConfig.variant === 'use_template'
							? t`Export from template`
							: exportConfig.variant === 'run_schedule'
							? t`Run schedule`
							: exportConfig.variant === 'change_schedule_status'
							? t`Change schedule status`
							: exportConfig.variant === 'scheduled'
							? t`Schedule feedback export`
							: exportConfig.variant === 'template'
							? t`Export template`
							: exportConfig.dialog_status === 'intro'
							? t`Quick export`
							: t`Export feedback`}
					</div>
					{exportConfig.dialog_status === 'content' && (
						<Stepper
							activeStep={activeStep}
							nonLinear
							classes={{ root: classes.stepper }}
						>
							{getSteps().map(({ label }, index) => {
								return (
									<Step key={label}>
										<StepButton
											completed={Boolean(index < activeStep && pageValid(index))}
											onClick={e => {
												if (
													(!countingFeedback && pageValid(activeStep)) ||
													(!countingFeedback && activeStep > index)
												) {
													setActiveStep(index);
												}
											}}
										>
											<StepLabel
												error={
													(exportConfig.clicked && !pageValid(index)) ||
													(!exportConfig.feedback_exist &&
														filterIsEmpty &&
														index === 0) ||
													(!exportConfig.feedback_exist &&
														!filterIsEmpty &&
														index === 1 &&
														activeStep <= 1)
												}
											>
												{t(label)}
											</StepLabel>
										</StepButton>
									</Step>
								);
							})}
						</Stepper>
					)}
				</Fragment>
			}
			submit={
				exportConfig.variant === 'change_schedule_status'
					? exportConfig.enabled
						? t`Activate`
						: t`Pause`
					: exportConfig.dialog_status === 'intro'
					? t`Export`
					: exportConfig.dialog_status === 'thanks'
					? pathname.match(/reporting\/inbox/) !== null
						? t`Show export progress`
						: null
					: getStepValue(activeStep) === 'export_settings'
					? exportConfig.make_schedule || exportConfig.variant === 'scheduled'
						? t`Save schedule`
						: exportConfig.variant === 'template'
						? t`Save template`
						: t`Send export`
					: t`Next`
			}
			open={open}
			onClose={(e, from) => {
				if (
					from.button &&
					getStepValue(activeStep) !== 'source' &&
					exportConfig.dialog_status !== 'intro' &&
					exportConfig.dialog_status !== 'thanks'
				) {
					setActiveStep(step => step - 1);
				} else {
					onClose(true);
					resetDialog();
				}
			}}
			cancel={
				exportConfig.dialog_status === 'thanks'
					? t`Close`
					: exportConfig.dialog_status === 'intro' ||
					  getStepValue(activeStep) === 'source'
					? t`Cancel`
					: t`back`
			}
			loading={countingFeedback || loading || loadingScheduled || loadingCount}
			maxWidth="sm"
			disabled={
				!exportConfig.feedback_exist &&
				(activeStep === 1 || (activeStep === 0 && filterIsEmpty))
			}
			dataTrackEvent={
				getStepValue(activeStep) === 'export_settings'
					? exportConfig.save_as_template
						? 'inbox_feedback_advanced_exported_template'
						: 'inbox_feedback_advanced_exported'
					: null
			}
			to={
				exportConfig.dialog_status === 'thanks' &&
				pathname.match(/reporting\/inbox/) !== null
					? '/reporting/exports/recent'
					: null
			}
			onSubmit={() => {
				//introPage quick export
				if (
					(exportConfig.dialog_status === 'intro' && exportConfig.variant === 'quick') ||
					(exportConfig.dialog_status === 'intro' &&
						exportConfig.variant === 'use_template')
				) {
					setClicked();
					if (isValid()) {
						postForm();
						setExportConfig(draft => {
							draft.dialog_status = 'thanks';
						});
					}
				}
				//run existing schedule
				else if (
					exportConfig.dialog_status === 'intro' &&
					(exportConfig.variant === 'run_schedule' ||
						exportConfig.variant === 'change_schedule_status')
				) {
					setClicked();
					if (isValid()) {
						postScheduled();
					}
				}
				//thanks page
				else if (exportConfig.dialog_status === 'thanks') {
					//on thanks page, button is used as link (to feedback exports)
					return null;
					//normal flow, post from step 3
				} else if (getStepValue(activeStep) === 'export_settings') {
					setClicked();

					if (
						isValid() &&
						(exportConfig.variant === 'scheduled' || exportConfig.make_schedule)
					) {
						postScheduled();
					} else if (isValid() && exportConfig.variant === 'template') {
						postTemplate();
					} else if (isValid()) {
						//check if template needs to be saved
						if (exportConfig.save_as_template) {
							postTemplate();
						}
						postForm();
						setExportConfig(draft => {
							draft.dialog_status = 'thanks';
						});
					}
					//pagination next button.
				} else {
					if (pageValid(activeStep)) {
						setActiveStep(step => step + 1);
					} else {
						setClicked();
					}
				}
			}}
			{...props}
		>
			{exportConfig.dialog_status === 'intro' && (
				<Grid
					container
					spacing={3}
					className={classes.grid}
					direction="column"
				>
					{exportConfig.variant === 'quick' && (
						<Grid
							item
							xs
						>
							<Typography
								variant="body1"
								color="textSecondary"
							>{t`Export results are based on your current inbox filters`}</Typography>
						</Grid>
					)}
					{exportConfig.variant === 'use_template' && (
						<Grid
							item
							xs
						>
							<Typography
								variant="body1"
								color="textSecondary"
							>{t`Export feedback data based on the settings from one of your templates`}</Typography>
						</Grid>
					)}
					{exportConfig.variant === 'run_schedule' && (
						<Grid
							item
							xs
						>
							<Typography
								variant="body1"
								color="textSecondary"
							>{t`Export results are based on your selected schedule settings`}</Typography>
						</Grid>
					)}
					{((!initialLoaded &&
						!['use_template', 'change_schedule_status'].includes(exportConfig.variant)) ||
						countingFeedback) &&
						!templatesLoading && (
							<Grid
								item
								xs
							>
								<Grid
									container
									direction="row"
									spacing={2}
								>
									<Grid item>
										<Loader
											circular
											size={20}
										/>
									</Grid>
									<Grid
										item
										xs
									>
										<Typography color="textSecondary">{t`Checking feedback data...`}</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
					{(exportConfig.variant === 'run_schedule' ||
						exportConfig.variant === 'quick' ||
						exportConfig.template_selected !== '') &&
						!exportConfig.feedback_exist &&
						initialLoaded &&
						!countingFeedback && (
							<Grid
								item
								xs
							>
								<Alert severity="warning">
									{exportConfig.variant === 'run_schedule'
										? t`No feedback found within the scheduled period`
										: exportConfig.variant === 'use_template'
										? t`No feedback found with your current template settings`
										: t`No feedback found with your current inbox filters`}
								</Alert>
							</Grid>
						)}
					<Grid
						item
						xs
					>
						<FeedbackQuickExport
							exportConfig={exportConfig}
							setExportConfig={setExportConfig}
							templatesLoading={templatesLoading}
						/>
					</Grid>
				</Grid>
			)}
			{exportConfig.dialog_status === 'content' && (
				<Grid
					container
					spacing={3}
					className={classes.grid}
					direction="column"
				>
					{getStepValue(activeStep) === 'source' && (
						<Fragment>
							{!exportConfig.feedback_exist &&
								initialLoaded &&
								!countingFeedback &&
								filterIsEmpty && (
									<Grid
										item
										xs
									>
										<Alert severity="warning">
											{exportConfig.survey_ids.length
												? t`No feedback found for this feedback source within the date range`
												: t`No feedback found within the date range`}
										</Alert>
									</Grid>
								)}
							<Grid
								item
								xs
							>
								<FeedbackExportDataFields
									dataSource={datasource}
									exportConfig={exportConfig}
									setSingleFilter={setSingleFilter}
									setExportConfig={setExportConfig}
									templates={templates?.message?.templates ?? []}
								/>
							</Grid>
						</Fragment>
					)}
					{getStepValue(activeStep) === 'filters' && (
						<Fragment>
							{exportConfig.variant === 'single' &&
								!exportConfig.feedback_exist &&
								initialLoaded &&
								!countingFeedback && (
									<Grid
										item
										xs
									>
										<Alert severity="warning">
											{filterIsEmpty
												? t`No feedback found for the selected source or date range. Please return to 'select source'`
												: t`No feedback found with your selected filters`}
										</Alert>
									</Grid>
								)}
							<Grid
								item
								xs
							>
								<Typography variant="subtitle">{t`Select export filters`}</Typography>
								<Typography
									color="textSecondary"
									gutterBottom
								>{t`Setup which feedback items your export should contain by selecting filters`}</Typography>
							</Grid>
							<Grid
								item
								xs
							>
								<FeedbackExportFilters
									exportConfig={exportConfig}
									setSingleFilter={setSingleFilter}
									setMultiFilter={setMultiFilter}
									setCustomFilter={setCustomFilter}
									setExportConfig={setExportConfig}
									customViews={customViews}
									createExportFilter={createExportFilter}
								/>
							</Grid>
						</Fragment>
					)}
					{getStepValue(activeStep) === 'export_settings' && (
						<Fragment>
							<Grid
								item
								xs
							>
								<FeedbackExportSettings
									exportConfig={exportConfig}
									setExportConfig={setExportConfig}
									postTemplate={postTemplate}
									loadingTemplate={loadingTemplate}
								/>
							</Grid>
						</Fragment>
					)}
				</Grid>
			)}
			{exportConfig.dialog_status === 'thanks' && (
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.wrap}
					spacing={3}
				>
					{exportConfig.exportSize === 0 && (
						<Fragment>
							<Grid>
								<Typography
									variant="h6"
									display="block"
									align="center"
									gutterBottom
								>
									{t`Crunching your data`}
								</Typography>
							</Grid>
							<Grid item>
								<CircularProgress />
							</Grid>
						</Fragment>
					)}
					{exportConfig.exportSize > 0 && (
						<Fragment>
							<Grid>
								<Typography
									variant="h6"
									display="block"
									align="center"
									gutterBottom
								>
									{t`Your export is being processed.`}
								</Typography>
							</Grid>
							<Grid item>
								<CheckIcon fontSize="large" />
							</Grid>
						</Fragment>
					)}
					<Grid item>
						<Typography
							color="textSecondary"
							variant="body1"
							align="center"
							gutterBottom
						>
							{exportConfig.exportSize > bigExport
								? t`We found a lot of data in your export. This may take a little longer than usual. The export will be sent to your email when it's finished.`
								: exportConfig.exportSize > 0
								? t`Once it’s ready, you’ll receive a download link in your email.`
								: t`We are analyzing the data to export, please wait...`}
						</Typography>
					</Grid>
					{exportConfig.exportSize > 0 && exportConfig.save_as_template && (
						<Grid>
							<Alert severity="info">
								{t('Your export settings are also saved as template')}
							</Alert>
						</Grid>
					)}
					{exportConfig.exportSize >= exportLimit && (
						<Grid item>
							<Alert severity="warning">
								{t(
									'Your export reached the limit of {{exportLimit}} items. Items surpassing this limit are not included. Please use more defined filters to export a smaller collection',
									{ exportLimit }
								)}
							</Alert>
						</Grid>
					)}
					{exportConfig.exportSize > 0 && pathname.match(/reporting\/inbox/) !== null && (
						<Grid
							item
							className={classes.marginBottom}
						>
							<Typography
								variant="subtitle2"
								align="center"
								gutterBottom
							>
								{t`Stay up to date on your export progress via the feedback exports page`}
							</Typography>
						</Grid>
					)}
				</Grid>
			)}
		</SimpleDialog>
	);
}
