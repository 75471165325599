import React, { useState, useRef, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ActionIcon from '@material-ui/icons/AssignmentTurnedIn';
import Fade from '@material-ui/core/Fade';

import LabelIcon from '@material-ui/icons/Label';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ReplyIcon from '@material-ui/icons/Reply';
import NewWindowIcon from '@material-ui/icons/OpenInNew';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContainerQuery } from 'react-container-query';

import FeedbackDetails from './FeedbackDetails';
import FeedbackDeleteDialog from './FeedbackDeleteDialog';
import FeedbackShareDialog from './FeedbackShareDialog';
import FeedbackWebHookDialog from './FeedbackWebHookDialog';
import ShareMenu from './ShareMenu';
import ContextBubble from './ContextBubble';
import { AddTagsDialog, TagChip } from '@/components/Tags';
import { ActionAddDialog } from '@/components/Actions';
import {
	Grid,
	Menu,
	MenuItem,
	Tooltip,
	StatusIcon,
	FormattedDate,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	ActionIconButton,
	ActionButton,
	Chip,
} from '@/components/Layout';
import { scrollToNode } from '@/utils';
import { useAppContext } from '@/components/AppContext';
import { useEFM, AjaxForm } from '@/components/Ajax';
import { DataSourceAvatar } from '@/components/DataSources';
import { useListFilterContext } from '@/components/FilteredLists';
import {
	useHasPermission,
	NoPermissionTooltip,
	permissionSubjects,
	permissionModules,
} from '@/components/Permission';
import useGetFeedbackDetails from './useGetFeedbackDetails';

const useStyles = makeStyles(theme => ({
	clickBg: {
		backgroundColor: theme.palette.grey[200],
	},
	maxWidth: {
		maxWidth: '100%',
	},
	expPanelSmall: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	expPanelSumContent: {
		padding: 0,
	},
	gridWidth: {
		maxWidth: '100%',
		paddingRight: '0!important',
	},
	commentHeight: {
		minHeight: 36,
	},
	childMargins: {
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(0.5),
		},
	},
	childMarginsTags: {
		'& > *': {
			marginLeft: theme.spacing(0.5),
		},
	},
	childMarginsTagsOverlay: {
		'& > *': {
			display: 'inline-block',
			margin: theme.spacing(0.25),
		},
	},
	wrap: {
		flexWrap: 'wrap',
	},
	noWrap: {
		flexWrap: 'nowrap',
	},
	bubbleWrap: {
		display: 'inline-flex',
	},
	statusIcons: {
		'& > *:not(:last-child)': {
			marginRight: theme.spacing(1),
		},
	},
	progress: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
	},
	lastClickIndicator: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: 2,
		backgroundColor: theme.palette.primary.main,
	},
	badgeXs: {
		minWidth: 16,
		height: 16,
		backgroundColor: theme.palette.grey['400'],
	},
	dateSpacing: {
		marginLeft: theme.spacing(1),
	},
	tagChip: {
		marginTop: theme.spacing(0.25),
	},
	tagsHidden: {
		opacity: 0,
	},
	checkBox: {
		paddingTop: 2,
	},
	checkboxExpanded: {
		marginTop: 10,
	},
	dummyWidth: {
		width: 0 /* we need a dummywidth for noWrap to work */,
		overflow: 'hidden',
	},
	clampText: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	avatar: {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
	},
	tagsOverlay: {
		position: 'absolute',
		backgroundColor: 'rgba(255,255,255,.8)',
		top: 0,
		left: 0,
		right: 0,
		bottom: 1,
		display: 'flex',
		zIndex: 10,
	},
	tagsPositionSmall: {
		right: 36,
	},
	tagsPositionLarge: {
		right: 80,
	},
	overflowHidden: {
		overflow: 'hidden',
	},
	ellipsis: {
		textOverflow: 'ellipsis',
	},
	avatarSpace: {
		maxWidth: 'calc(100% - 40px)',
	},
	displayNone: {
		display: 'none',
	},
	alignRight: {
		textAlign: 'right',
	},
	surveyName: {
		fontWeight: theme.typography.fontWeightRegular,
		color: theme.palette.text.secondary,
	},
	pipe: {
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
	centerPipe: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

function Highlights({ thumbs, gcr, scores, MSI }) {
	return (
		<>
			{typeof MSI !== 'undefined' && (
				<ContextBubble
					showLabel
					MSI={MSI}
					label={`Mopinion Sentiment Index: ${MSI}`}
				>
					{MSI}
				</ContextBubble>
			)}
			{Array.isArray(thumbs) &&
				thumbs.map((thumbs, i) => (
					<ContextBubble
						{...thumbs}
						key={thumbs.value + i}
						thumbs
					/>
				))}
			{Array.isArray(gcr) &&
				gcr.map((gcr, i) => (
					<ContextBubble
						{...gcr}
						key={gcr.value + i}
						gcr
					/>
				))}
			{Array.isArray(scores) &&
				scores.map((score, i) => {
					//show 4 scores max
					let count =
						1 +
						i +
						(thumbs instanceof Array ? thumbs.length : 0) +
						(gcr instanceof Array ? gcr.length : 0);
					if (count <= 4)
						return (
							<ContextBubble
								showLabel
								{...score}
								key={score.label + i}
							>
								{score.value}
							</ContextBubble>
						);
					return null;
				})}
		</>
	);
}

function StatusIcons({ screenshot, notes_count, actions_count, email_count }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const getTooltip = (count, single, multiple) =>
		Number(count) === 1 ? `${count} ${single}` : `${count} ${multiple}`;

	return (
		<Grid
			container
			wrap="nowrap"
			justify="center"
			alignItems="center"
			className={classes.statusIcons}
		>
			{screenshot > 0 && (
				<StatusIcon
					variant="screenshot"
					count={screenshot}
					tooltip={getTooltip(screenshot, t`screenshot`, t`screenshots`)}
				/>
			)}
			{notes_count > 0 && (
				<StatusIcon
					variant="note"
					count={notes_count}
					tooltip={getTooltip(notes_count, t`note`, t`notes`)}
				/>
			)}
			{actions_count > 0 && (
				<StatusIcon
					variant="action"
					count={actions_count}
					tooltip={getTooltip(actions_count, t`action`, t`actions`)}
				/>
			)}
			{email_count > 0 && (
				<StatusIcon
					variant="email"
					count={email_count}
					tooltip={getTooltip(email_count, t`email`, t`emails`)}
				/>
			)}
		</Grid>
	);
}

function TagsList({
	tags,
	feedback_id,
	expanded,
	wasLastClicked,
	feedbackLoading,
	tagsOverlay,
	setTagsOverlay = () => {},
	loadFeedback = () => {},
}) {
	const classes = useStyles();
	const tagWrap = useRef(null);

	const [tagWrapWidth, setTagWrapWidth] = useState(0);
	const [tagList, setTagList] = useState([]);
	const [tagsWidthArray, setTagsWidthArray] = useState([]);

	useEffect(() => {
		if (tags.length && tagWrap?.current?.querySelectorAll('.tagInList')?.length) {
			//store nodeList in state, to avoid losing them when the list is expanded/unmounted
			setTagList(Array.from(tagWrap.current?.querySelectorAll('.tagInList')));

			//size in px's of the wrapper for the tags ( when not wasLastClicked)
			setTagWrapWidth(tagWrap?.current?.clientWidth);
		}
	}, [tagWrap, tags]);

	useEffect(() => {
		if (tagList.length) {
			const makeArray = tagList.reverse().reduce((tags, tag) => {
				const prevValue = tags[tags.length - 1] ?? 30; // startmargin of + amount tag
				const tagSize = prevValue + tag.clientWidth + 5; //bit dirty but we need to add the margin between tags
				return [...tags, tagSize];
			}, []);

			//calculate total width in px's of tags in tagWrap
			setTagsWidthArray(makeArray);
		}
	}, [tagList]);

	const collapseTagsAfter = tagsWidthArray.filter(size => size < tagWrapWidth);

	return (
		<Grid
			container
			alignItems="center"
			justify="flex-end"
			justifyContent="flex-end"
			alignContent="center"
			className={`${classes.childMarginsTags} ${
				!expanded && !wasLastClicked ? classes.noWrap : ''
			}`}
			ref={tagWrap}
		>
			{tags.length > 0 &&
				(expanded || wasLastClicked) &&
				tags.map((tag, i) => {
					return (
						<Grid
							item
							key={'taggrid-' + feedback_id + tag.name + i}
						>
							<AjaxForm
								url="/application/tags/delete-by-id"
								data={{
									ids: tag.id,
									feedbackId: feedback_id,
								}}
								onSuccess={e => {
									if (e.tags.deleted?.length > 0) {
										loadFeedback();
									}
								}}
								key={'tag' + tag.id}
							>
								{({ postForm }) => (
									<TagChip
										key={'tag-' + feedback_id + tag.name + i}
										label={tag.name}
										variant={'default'}
										small
										tagClass={classes.tagChip}
										onDelete={postForm}
										{...tag}
									/>
								)}
							</AjaxForm>
						</Grid>
					);
				})}
			{tags.length > 0 &&
				!expanded &&
				!wasLastClicked &&
				tags.map((tag, i) => {
					const hideTag = tags.length - collapseTagsAfter.length;

					return (
						<Grid
							item
							key={'taggrid-' + feedback_id + tag.name + i}
							className={`${tagsOverlay || i < hideTag ? classes.tagsHidden : ''} ${
								i === hideTag ? classes.ellipsis : ''
							} tagInList`}
							onMouseOver={() => {
								if (!expanded && !wasLastClicked && !feedbackLoading && i >= hideTag) {
									setTagsOverlay(true);
								}
							}}
						>
							<AjaxForm
								url="/application/tags/delete-by-id"
								data={{
									ids: tag.id,
									feedbackId: feedback_id,
								}}
								onSuccess={e => {
									if (e.tags.deleted.length > 0) {
										loadFeedback();
									}
								}}
								key={'tag' + tag.id}
							>
								{({ postForm }) => (
									<TagChip
										key={'tag-' + feedback_id + tag.name + i}
										label={tag.name}
										variant={'default'}
										small
										tagClass={`${classes.tagChip} ${classes.ellipsis} ${classes.maxWidth}`}
										{...tag}
									/>
								)}
							</AjaxForm>
						</Grid>
					);
				})}
			{!expanded && !wasLastClicked && tags.length > collapseTagsAfter.length && (
				<Grid
					item
					className={tagsOverlay ? classes.tagsHidden : ''}
					onMouseOver={() => {
						if (!expanded && !wasLastClicked && !feedbackLoading) {
							setTagsOverlay(true);
						}
					}}
				>
					<TagChip
						label={`+ ${
							tags.length - (collapseTagsAfter.length ? collapseTagsAfter.length : 1)
						}`}
						small
						tagClass={classes.tagChip}
					/>
				</Grid>
			)}
		</Grid>
	);
}

function TagsListOverlay({
	tags,
	feedback_id,
	tagRef,
	customer,
	setTagsOverlay = () => {},
	loadFeedback = () => {},
	setTagsOverlayPending = () => {},
}) {
	const classes = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			justify="flex-end"
			justifyContent="flex-end"
			className={classes.wrap}
			ref={tagRef}
		>
			<Grid
				item
				className={`${classes.alignRight} ${classes.childMarginsTagsOverlay}`}
			>
				{tags.map((tag, i) => {
					return (
						<div key={'taggrid-' + feedback_id + tag.name + i}>
							<AjaxForm
								url="/application/tags/delete-by-id"
								data={{
									ids: tag.id,
									feedbackId: feedback_id,
								}}
								onSuccess={e => {
									if (e.tags.deleted.length > 0) {
										setTagsOverlay(false);
										setTagsOverlayPending(false);
										loadFeedback();
									}
								}}
								key={'tag' + tag.id}
							>
								{({ postForm }) => (
									<TagChip
										key={'tag-' + feedback_id + tag.name + i}
										label={tag.name}
										variant={'default'}
										small
										tagClass={classes.tagChip}
										onDelete={() => {
											setTagsOverlayPending(true);
											postForm();
										}}
										{...tag}
									/>
								)}
							</AjaxForm>
						</div>
					);
				})}
			</Grid>
		</Grid>
	);
}

function FeedbackUrl({ url }) {
	const { t } = useTranslation();

	if (!url?.value) {
		return '';
	}

	// Out of scope for now
	// if (url.value.includes('data-collection/survey/edit') || url.value.includes('data-collection/survey/preview')) {
	//   return t`Test feedback (preview page)`;
	// }

	if (url?.value?.includes('trial_demo_data')) {
		return (
			<Chip
				label={t`reporting-feedback_inbox-trial_demo_data`}
				variant={'default'}
				size="small"
			/>
		);
	}

	return url.value?.replace(/(^\w+:|^)\/\//, '');
}

const CampaignInfo = ({ campaign = '', appName = '', os = {} }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	if (campaign === 'email') {
		return t`reporting-feedback_inbox-campaign-email`;
	}

	if (campaign === 'sdk') {
		return (
			<>
				{appName || t(`reporting-feedback_inbox-campaign-sdk`)}
				{os?.value && (
					<>
						<div className={classes.centerPipe}>|</div>
						{os.value}
					</>
				)}
			</>
		);
	}

	return '';
};

function FeedbackExpansionPanel({
	loadFeedback = () => {},
	toggleExpand = () => {},
	feedbackLoading,
	toggleSelection,
	expanded,
	wasLastClicked,
	isSelected,
	allowShareEmail,
	customer = {},
	firstname = '',
	lastname = '',
	comment,
	extra_input_comment,
	app_name,
	url,
	survey_id,
	survey_name,
	survey_type,
	survey_format,
	campaign,
	os,
	feedback_created,
	feedback_id,
	feedbackList,
	actions_count,
	email_count,
	notes_count,
	screenshot,
	tags,
	...props
}) {
	const { app } = useAppContext();
	const { t } = useTranslation();
	const classes = useStyles();
	const { setSingleFilter } = useListFilterContext();

	const panelRef = useRef(null);
	const tagRef = useRef(null);

	const hasInboxEditPermission = useHasPermission();
	const hasActionEditPermission = useHasPermission({
		module: permissionModules.actions,
		subject: permissionSubjects.action_management,
	});

	const scrollNode = app.layout.drawerScrollerRef?.current ?? window;

	const [data = {}, loading, error, loadData] = useGetFeedbackDetails(
		feedback_id,
		expanded,
		() => {
			scrollToNode(panelRef.current, -90, scrollNode);
		}
	);

	const [dialogs, setDialogs] = useState({
		tagsDialogOpen: false,
		deleteDialogOpen: false,
		shareDialogOpen: false,
		webHookDialogOpen: false,
		addActionDialogOpen: false,
		isReply: false,
		webhook_id: null,
	});

	const [anchors, setAnchors] = useState({
		moreMenuAnchor: null,
		shareMenuAnchor: null,
		mouseX: null,
		mouseY: null,
	});

	const closeMenus = () => {
		setAnchors(prevState => ({
			...prevState,
			moreMenuAnchor: null,
			shareMenuAnchor: null,
			mouseY: null,
			mouseX: null,
		}));
	};

	const [tagsOverlay, setTagsOverlay] = useState(false);
	const [tagsOverlayPending, setTagsOverlayPending] = useState(false);

	const panelProps = {
		className: classes.maxWidth,
		expanded: expanded,
		...(expanded && { elevation: 10 }),
		'data-onboarding': 'feedback-expansion-panel',
		ref: panelRef,
		'data-test-element': 'feedback-expansion-panel',
	};

	return (
		<>
			<ContainerQuery
				query={{
					xs: { maxWidth: 500 },
					sm: { maxWidth: 600 },
					lg: { minWidth: 750 },
					xl: { minWidth: 1000 },
				}}
			>
				{matches => (
					<ExpansionPanel {...panelProps}>
						<ExpansionPanelSummary
							onContextMenu={e => {
								e.preventDefault();
								const { clientX, clientY } = e;
								setAnchors(prevState => ({
									...prevState,
									mouseX: clientX - 2,
									mouseY: clientY - 4,
								}));
							}}
							classes={{
								root: `${classes.maxWidth} ${
									expanded ||
									(isSelected && !tagsOverlay) ||
									anchors.moreMenuAnchor ||
									(anchors.mouseX && anchors.mouseY)
										? classes.clickBg
										: ''
								} ${matches.sm ? classes.expPanelSmall : ''}`,
								content: classes.maxWidth,
							}}
							data-test-element="feedbackInboxItem"
							data-onboarding="feedback-expansion-panel-summary"
							disableRipple={false}
							onClick={() => {
								if (!tagsOverlay) {
									toggleExpand();
								}
							}}
							onMouseLeave={e => {
								if (!tagsOverlayPending) {
									setTagsOverlay(false);
								}
							}}
						>
							{wasLastClicked ? <div className={classes.lastClickIndicator} /> : null}

							<Grid
								container
								className={classes.gridWidth}
								alignItems="top"
								spacing={1}
							>
								{tagsOverlay && !feedbackLoading && (
									<Grid
										item
										xs={12}
										className={`${classes.tagsOverlay} ${
											matches.lg ? classes.tagsPositionLarge : classes.tagsPositionSmall
										}`}
									>
										<TagsListOverlay
											tags={Array.isArray(tags) ? tags : []}
											customer={customer}
											feedback_id={feedback_id}
											setTagsOverlay={e => setTagsOverlay(e)}
											setTagsOverlayPending={e => setTagsOverlayPending(e)}
											loadFeedback={() => loadFeedback()}
											tagRef={tagRef}
										/>
									</Grid>
								)}

								<Grid item>
									<Checkbox
										className={`${classes.checkBox} ${
											expanded || wasLastClicked ? classes.checkboxExpanded : ''
										}`}
										checked={isSelected || false}
										onClick={e => e.stopPropagation()}
										onChange={toggleSelection}
									/>
								</Grid>

								<Grid
									item
									xs
									zeroMinWidth
								>
									<Grid
										container
										direction="column"
										wrap="nowrap"
										spacing={1}
									>
										<Grid item>
											<Grid
												container
												wrap="nowrap"
												className={classes.gridWidth}
												spacing={1}
												alignItems="center"
											>
												<Grid
													item
													xs
													className={classes.dummyWidth}
												>
													<Grid
														container
														spacing={1}
														alignItems="center"
														wrap="nowrap"
													>
														<Grid item>
															<Tooltip
																title={
																	survey_type === 'import'
																		? t(`reporting-feedback_inbox-tooltip-${survey_type}`)
																		: t(
																				`reporting-feedback_inbox-tooltip-${survey_format}_${campaign}`
																		  )
																}
															>
																<span>
																	<DataSourceAvatar
																		survey_format={survey_format}
																		survey_type={survey_type}
																		campaign={campaign}
																		className={classes.avatar}
																	/>
																</span>
															</Tooltip>
														</Grid>

														<Grid
															item
															xs
															className={classes.avatarSpace}
														>
															<Typography
																variant="body2"
																color="textSecondary"
																noWrap
																component="div"
															>
																<ActionButton
																	disableTextTransform
																	disableRipple
																	tooltip={t(
																		'reporting-feedback_inbox-tooltip-survey_title_as_filter',
																		{ formName: survey_name }
																	)}
																	className={classes.surveyName}
																	onClick={e => {
																		e.stopPropagation();
																		setSingleFilter('survey_id', survey_id, true);
																	}}
																>
																	{survey_name}
																</ActionButton>
																<div className={classes.pipe}>|</div>
																{campaign === 'sdk' ? (
																	<CampaignInfo
																		campaign={campaign}
																		os={os}
																		appName={app_name}
																	/>
																) : (
																	<FeedbackUrl url={url} />
																)}
															</Typography>
														</Grid>
													</Grid>
												</Grid>

												<Grid
													item
													zeroMinWidth
													className={`${classes.childMargins} ${classes.bubbleWrap}`}
												>
													<Highlights {...props} />
												</Grid>

												<Grid
													item
													zeroMinWidth
												>
													<StatusIcons
														screenshot={screenshot}
														notes_count={notes_count}
														actions_count={actions_count}
														email_count={email_count}
													/>
												</Grid>

												<Grid
													item
													className={classes.dateSpacing}
												>
													<Typography
														variant="body2"
														color="textSecondary"
													>
														<FormattedDate date={feedback_created} />
													</Typography>
												</Grid>

												{matches.lg && (expanded || wasLastClicked) && (
													<Grid
														item
														zeroMinWidth
													>
														<Grid
															container
															direction="row"
															wrap="nowrap"
															justify="space-between"
															alignItems="center"
															data-onboarding="feedback-actions"
														>
															<ActionIconButton
																tooltip={t`Detail view`}
																action="detail"
																to={{
																	pathname: `/reporting/inbox/detail/${feedback_id}`,
																	state: { feedbackList },
																}}
																onClick={e => e.stopPropagation()}
															/>
															{customer.email && (
																<ActionIconButton
																	tooltip={t`Reply`}
																	action="reply"
																	data-test-element="feedbackReply"
																	onClick={e => {
																		e.stopPropagation();
																		setDialogs(prevState => ({
																			...prevState,
																			shareDialogOpen: true,
																			isReply: true,
																		}));
																	}}
																	disabled={!hasInboxEditPermission}
																/>
															)}
															<ActionIconButton
																tooltip={t`Share`}
																action="share"
																data-test-element="feedbackForwardReply"
																onClick={e => {
																	e.stopPropagation();
																	setAnchors(prevState => ({
																		...prevState,
																		shareMenuAnchor: e.target,
																	}));
																}}
															/>
															<NoPermissionTooltip>
																<ActionIconButton
																	tooltip={t`Add action`}
																	action="add_action"
																	data-test-element="feedbackAddAction"
																	onClick={e => {
																		e.stopPropagation();
																		setDialogs(prevState => ({
																			...prevState,
																			addActionDialogOpen: true,
																		}));
																	}}
																	disabled={!hasActionEditPermission}
																/>
															</NoPermissionTooltip>
															<NoPermissionTooltip>
																<ActionIconButton
																	tooltip={t`Add tags`}
																	action="tags"
																	onClick={e => {
																		e.stopPropagation();
																		setDialogs(prevState => ({
																			...prevState,
																			tagsDialogOpen: true,
																		}));
																	}}
																	disabled={!hasInboxEditPermission}
																/>
															</NoPermissionTooltip>
															<NoPermissionTooltip>
																<ActionIconButton
																	action="delete"
																	tooltip={t`Delete item`}
																	data-test-element="feedbackDelete"
																	onClick={e => {
																		e.stopPropagation();
																		setDialogs(prevState => ({
																			...prevState,
																			deleteDialogOpen: true,
																		}));
																	}}
																	disabled={!hasInboxEditPermission}
																/>
															</NoPermissionTooltip>
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>

										<Grid item>
											<Grid
												container
												wrap="nowrap"
												className={`${classes.gridWidth} ${classes.commentHeight}`}
												spacing={1}
												alignItems="top"
											>
												<Grid
													item
													xs
												>
													{customer.full_name !== 'unknown' && (
														<Typography
															variant="body2"
															fontWeight="Medium"
															noWrap
														>
															{customer.full_name}
														</Typography>
													)}
													<div className={!expanded ? classes.clampText : ''}>
														{comment ? (
															<Typography variant="body2">{comment}</Typography>
														) : extra_input_comment ? (
															<Typography variant="body2">
																{extra_input_comment}
															</Typography>
														) : (
															<Typography
																variant="body2"
																color="textSecondary"
															>
																{t`reporting-feedback_inbox-no_comment`}
															</Typography>
														)}
													</div>
												</Grid>

												<Grid
													item
													xs={expanded ? 5 : 4}
													className={classes.alignRight}
												>
													<TagsList
														tags={Array.isArray(tags) ? tags : []}
														expanded={expanded}
														feedbackLoading={feedbackLoading}
														feedback_id={feedback_id}
														tagsOverlay={tagsOverlay}
														setTagsOverlay={e => setTagsOverlay(e)}
														loadFeedback={() => loadFeedback()}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								<Grid item>
									{((!expanded && !wasLastClicked) || (expanded && matches.sm)) && (
										<Grid item>
											<Grid
												container
												direction="row"
												wrap="nowrap"
												justify="space-between"
												alignItems="center"
											>
												<ActionIconButton
													action="more_vert"
													onClick={e => {
														e.stopPropagation();
														const { clientX, clientY } = e;
														setAnchors(prevState => ({
															...prevState,
															moreMenuAnchor: e.target,
															mouseX: clientX - 2,
															mouseY: clientY - 4,
														}));
													}}
												/>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						</ExpansionPanelSummary>
						{expanded && (
							<>
								<Divider />
								<ExpansionPanelDetails className={classes.expPanelSumContent}>
									{loading ? <LinearProgress className={classes.progress} /> : null}
									{data.feedback && (
										<Fade in={Boolean(data.feedback)}>
											<Grid container>
												<FeedbackDetails
													fromExpansionPanel={true}
													details={data}
													campaign={campaign}
													feedback_id={feedback_id}
													loadData={loadData}
												/>
											</Grid>
										</Fade>
									)}
								</ExpansionPanelDetails>
							</>
						)}
					</ExpansionPanel>
				)}
			</ContainerQuery>
			<Menu
				anchorEl={anchors.moreMenuAnchor}
				open={
					Boolean(anchors.moreMenuAnchor) ||
					Boolean(anchors.mouseY && anchors.mouseY !== null)
				}
				anchorReference={anchors.mouseY !== null ? 'anchorPosition' : null}
				anchorPosition={
					anchors.mouseY !== null && anchors.mouseX !== null
						? { top: anchors.mouseY, left: anchors.mouseX }
						: undefined
				}
				onClose={e => {
					e.stopPropagation();
					closeMenus();
				}}
			>
				<MenuItem
					component={Link}
					to={{
						pathname: `/reporting/inbox/detail/${feedback_id}`,
						state: { feedbackList },
					}}
					onClick={e => e.stopPropagation()}
				>
					<ListItemIcon>
						<ZoomInIcon />
					</ListItemIcon>
					<ListItemText>{t('Detail view')}</ListItemText>
				</MenuItem>

				<MenuItem
					component={Link}
					to={{
						pathname: `/reporting/inbox/detail/${feedback_id}`,
						state: { feedbackList },
					}}
					onClick={e => e.stopPropagation()}
					target="_blank"
				>
					<ListItemIcon>
						<NewWindowIcon />
					</ListItemIcon>
					{t`Detail view in new window`}
				</MenuItem>

				{customer.email && (
					<MenuItem
						disabled={!hasInboxEditPermission}
						onClick={e => {
							e.stopPropagation();
							setDialogs(prevState => ({
								...prevState,
								shareDialogOpen: true,
								isReply: true,
							}));
							closeMenus();
						}}
					>
						<ListItemIcon>
							<ReplyIcon />
						</ListItemIcon>
						<ListItemText>{t('Reply')}</ListItemText>
					</MenuItem>
				)}

				<ShareMenu
					nestedMenu
					shareMenuAnchor={anchors.shareMenuAnchor}
					allowShareEmail={allowShareEmail}
					feedbackId={feedback_id}
					mouseY={anchors.mouseY}
					mouseX={anchors.mouseX}
					setShareDialogOpen={() => {
						setDialogs(prevState => ({
							...prevState,
							shareDialogOpen: true,
							isReply: false,
						}));
						closeMenus();
					}}
					setWebHookDialogOpen={webhook_id => {
						setDialogs(prevState => ({
							...prevState,
							webHookDialogOpen: true,
							webhook_id: webhook_id,
						}));
						closeMenus();
					}}
					closeMenu={() => {
						closeMenus();
					}}
				/>

				<MenuItem
					onClick={e => {
						e.stopPropagation();
						setDialogs(prevState => ({
							...prevState,
							addActionDialogOpen: true,
						}));
						closeMenus();
					}}
					disabled={!hasActionEditPermission}
				>
					<ListItemIcon>
						<ActionIcon />
					</ListItemIcon>
					<ListItemText>{t('Add action')}</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={e => {
						e.stopPropagation();
						setDialogs(prevState => ({
							...prevState,
							tagsDialogOpen: true,
						}));
						closeMenus();
					}}
					disabled={!hasInboxEditPermission}
				>
					<ListItemIcon>
						<LabelIcon />
					</ListItemIcon>
					<ListItemText>{t('Add tags')}</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={e => {
						e.stopPropagation();
						setDialogs(prevState => ({
							...prevState,
							deleteDialogOpen: true,
						}));
						closeMenus();
					}}
					disabled={!hasInboxEditPermission}
				>
					<ListItemIcon>
						<DeleteIcon />
					</ListItemIcon>
					<ListItemText>{t('Delete item')}</ListItemText>
				</MenuItem>
			</Menu>

			<ShareMenu
				shareMenuAnchor={anchors.shareMenuAnchor}
				allowShareEmail={allowShareEmail}
				feedbackId={feedback_id}
				closeMenu={() => {
					closeMenus();
				}}
				setShareDialogOpen={() => {
					setDialogs(prevState => ({
						...prevState,
						shareDialogOpen: true,
						isReply: false,
					}));
					closeMenus();
				}}
				setWebHookDialogOpen={webhook_id => {
					setDialogs(prevState => ({
						...prevState,
						webHookDialogOpen: true,
						isReply: false,
						webhook_id: webhook_id,
					}));
					closeMenus();
				}}
			/>

			<AddTagsDialog
				open={dialogs.tagsDialogOpen}
				onClose={() => {
					setDialogs(prevState => ({
						...prevState,
						tagsDialogOpen: false,
					}));
				}}
				feedback_ids={[feedback_id]}
				onSuccess={loadFeedback}
				dataTrackEvent="inbox_feedback_tags_added"
			/>

			<FeedbackDeleteDialog
				open={dialogs.deleteDialogOpen}
				close={() => {
					setDialogs(prevState => ({
						...prevState,
						deleteDialogOpen: false,
					}));
				}}
				feedback_ids={[feedback_id]}
				onSuccess={loadFeedback}
			/>

			<FeedbackShareDialog
				open={dialogs.shareDialogOpen}
				onClose={() => {
					setDialogs(prevState => ({
						...prevState,
						shareDialogOpen: false,
					}));
				}}
				reply={dialogs.isReply}
				replyTo={customer.email}
				feedback_id={feedback_id}
				onSuccess={() => {
					loadFeedback();
					loadData();
				}}
			/>

			<FeedbackWebHookDialog
				open={dialogs.webHookDialogOpen}
				onClose={() => {
					setDialogs(prevState => ({
						...prevState,
						webHookDialogOpen: false,
					}));
				}}
				selectedHook={dialogs.webhook_id}
				feedback_ids={[feedback_id]}
				onSuccess={() => {
					loadFeedback();
					loadData();
				}}
			/>

			<ActionAddDialog
				feedback_id={feedback_id}
				open={dialogs.addActionDialogOpen}
				onClose={() => {
					setDialogs(prevState => ({
						...prevState,
						addActionDialogOpen: false,
					}));
				}}
				onSuccess={() => {
					loadFeedback();
					loadData();
				}}
			/>
		</>
	);
}

export default React.memo(FeedbackExpansionPanel);
