import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import { PageHeader } from '@/components/App';
import {
	Box,
	Grid,
	AddCard,
	Loader,
	EmptyState,
	ActionButton,
} from '@/components/Layout';

import { useAppContext } from '@/components/AppContext';
import { useMjolnir } from '@/components/Ajax';
import { UpgradeOverlay } from '@/components/Billing';
import DeploymentCard from './DeploymentCard';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';

import DeploymentAddDialog from './DeploymentAddDialog';

export default function DeploymentList({ ...props }) {
	const { t } = useTranslation();
	const { app } = useAppContext();

	const hasEditPermission = useHasPermission();
	const [addDialogOpen, setAddDialogOpen] = useState(false);

	const [deployments, loading, error, loadData] = useMjolnir(
		`/api/1/pastease/${app.domain}/${app.organisations.current.org_id}`
	);

	return (
		<Fragment>
			<PageHeader title={t`data_collection-deployment_list-page_header`} />
			{loading && (
				<Loader
					empty={!deployments?.deployments}
					appBar
				/>
			)}
			{deployments?.deployments?.length > 0 && (
				<Grid
					container
					spacing={3}
				>
					{!app.session.onboarding_trial && (
						<Grid
							item
							xs={6}
							sm={4}
							md={3}
						>
							<NoPermissionTooltip>
								<AddCard
									onClick={() => setAddDialogOpen(true)}
									label={t`data_collection-deployment_list-add_card`}
									maxReached={
										get(deployments, 'deployments', []).length >= app.package.deployments
									}
									subject={t`deployments`}
									disabled={!hasEditPermission}
									dataTestElement="deployment-list-create-new"
								/>
							</NoPermissionTooltip>
						</Grid>
					)}
					{get(deployments, 'deployments', []).map(deployment => {
						return (
							<Grid
								item
								xs={6}
								sm={4}
								md={3}
								key={deployment.id}
							>
								<UpgradeOverlay
									message={t(
										`data_collection-deployment_list-deployment_card-product_not_allowed-${
											deployment.type ?? 'web'
										}`
									)}
									needsUpgrade={!app.api.packageProductAllowed(deployment.type ?? 'web')}
								>
									<Box
										height="100%"
										width="100%"
										flexDirection="column"
										display="flex"
										clone
									>
										<DeploymentCard
											{...deployment}
											loadDeployments={loadData}
										/>
									</Box>
								</UpgradeOverlay>
							</Grid>
						);
					})}
				</Grid>
			)}
			{!loading && (deployments?.deployments?.length === 0 || !deployments?.deployments) && (
				<EmptyState
					image={<img src={`/assets/img/r/emptystate/deployments.gif`} />}
					primary={t`data_collection-deployments-emptystate_default-title`}
					secondary={t`data_collection-deployments-emptystate_default-text`}
					action={
						<NoPermissionTooltip>
							<ActionButton
								variant="contained"
								color="primary"
								onClick={() => setAddDialogOpen(true)}
								disabled={!hasEditPermission}
								data-test-element="deployment-list-create-new"
							>
								{t`data_collection-deployments-emptystate_default-button_new_deployment`}
							</ActionButton>
						</NoPermissionTooltip>
					}
				/>
			)}

			<DeploymentAddDialog
				open={addDialogOpen}
				onClose={() => {
					setAddDialogOpen(false);
					loadData();
				}}
			/>
		</Fragment>
	);
}
