import React, { memo, cloneElement, isValidElement } from 'react';

import ThumbsIcon from '@material-ui/icons/ThumbsUpDown';
import ContactIcon from '@material-ui/icons/ContactMail';
import MultiMediaIcon from '@material-ui/icons/Collections';
import ScreenshotIcon from '@material-ui/icons/CameraAlt';
import LinkIcon from '@material-ui/icons/Link';
import WebsiteDataIcon from '@material-ui/icons/Input';
import TranslateIcon from '@material-ui/icons/Translate';
import WordClusterIcon from '@material-ui/icons/TextFields';
import DeviceIcon from '@material-ui/icons/Devices';
import TagIcon from '@material-ui/icons/Label';
import DateIcon from '@material-ui/icons/CalendarToday';
import GeoIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import UserIcon from '@material-ui/icons/Contacts';
import EndingIcon from '@material-ui/icons/AssignmentTurnedIn';

import FormTriggerIcon from '@material-ui/icons/Vibration';
import SurveyIcon from '@material-ui/icons/Assignment';
import PageTitleIcon from '@material-ui/icons/Title';
import FormCompletionPercentageIcon from '@material-ui/icons/DonutSmall';
import OsIcon from '@material-ui/icons/Devices';
import BrowserIcon from '@material-ui/icons/Web';
import ViewPortIcon from '@material-ui/icons/WebAsset';
import CustomerIdIcon from '@material-ui/icons/Fingerprint';
import IconIcon from '@material-ui/icons/SentimentSatisfied';
import IntroPageIcon from '@material-ui/icons/Storefront';
import UserAgentIcon from '@material-ui/icons/VerifiedUser';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import StarRatingIcon from '@material-ui/icons/StarBorder';
import EmojiRatingIcon from '@material-ui/icons/SentimentSatisfied';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import XSameAsYIcon from '@material-ui/icons/ImportExport';

import { faMobileAlt, faTabletAlt, faDesktop } from '@fortawesome/free-solid-svg-icons';

import {
	faChrome,
	faSafari,
	faFirefox,
	faEdge,
	faInternetExplorer,
	faOpera,
	faLinux,
	faApple,
	faWindows,
} from '@fortawesome/free-brands-svg-icons';

import {
	FaIcon,
	CategoryIcon,
	CheckboxIcon,
	DropdownIcon,
	GcrIcon,
	InputIcon,
	LikertIcon,
	MatrixIcon,
	NpsIcon,
	PageBreakIcon,
	RatingIcon,
	RadioIcon,
	SectionBreakIcon,
	TextAreaIcon,
	CesIcon,
	ContentsquareIcon,
	NumericRatingIcon,
	BarRatingIcon,
} from '@/components/Icons';

import { makeStyles } from '@/styles';

import { RenderConditional, Tooltip, Badge } from '@/components/Layout';
import { chartConstants } from '@/components/Charts';

const useStyles = makeStyles(theme => ({
	badge: {
		//backgroundColor:theme.palette.grey[50]
		backgroundColor: '#fff',
		boxShadow: theme.shadows[1],
	},
	badgeIcon: {
		width: 12,
	},
}));

function varTypeReducer({
	var_type,
	system_var_simple = '',
	system_var = '',
	label = '',
	import_var = '',
	survey_block = {},
}) {
	//handle some exceptions like sub datafields and meta types
	if (/extra.input/gi.test(import_var)) {
		return InputIcon;
	}

	if (/website_data/gi.test(import_var)) {
		return WebsiteDataIcon;
	}

	if (/translation/gi.test(import_var)) {
		return TranslateIcon;
	}

	if (Number(survey_block.type) === 4) {
		return CheckboxIcon;
	}

	if (Number(survey_block.type) === 11) {
		return MatrixIcon;
	}

	if (Number(survey_block.type) === 12) {
		return LikertIcon;
	}

	const testLabel = system_var_simple
		? system_var_simple.toLowerCase()
		: system_var
		? system_var.toLowerCase()
		: label.toLowerCase();

	switch (testLabel) {
		case 'tags':
			return TagIcon;

		case 'form trigger':
			return FormTriggerIcon;

		case 'survey':
			return SurveyIcon;

		case 'page title':
			return PageTitleIcon;

		case 'contentsquare session recording':
			return ContentsquareIcon;

		case chartConstants.xIsSameAsYSerieValue:
			return XSameAsYIcon;
	}

	//handle var types

	switch (parseInt(var_type)) {
		//ordinal, interval, ratio, score0-10
		case 2:
		case 3:
		case 4:
		case 25:
			return RatingIcon;

		//datetime
		case 7:
			return DateIcon;

		//geo
		case 8:
			return GeoIcon;

		//Feedback id
		case 9:
			return CustomerIdIcon;

		case 10:
			return PhoneIcon;

		//nps
		case 12:
			return NpsIcon;

		//open text
		case 13:
			return TextAreaIcon;

		//customer email
		case 14:
			return EmailIcon;

		//customer_id
		case 6:
			return CustomerIdIcon;
		//customer id, customer names and titles, twitter name, customer general
		case 15:
		case 17:
		case 18:
		case 19:
		case 20:
		case 22:
		case 23:
			return UserIcon;

		// anonymized/to be anonymized data
		case 29:
			return VisibilityOffIcon;

		//language
		case 21:

		//ces n nes
		case 16:
		case 24:
		case 27:
			return CesIcon;

		//cluster
		case 31:
		//word
		case 32:
			return WordClusterIcon;

		//url
		case 35:
			return LinkIcon;

		//OS
		case 36:
			return OsIcon;

		//browser
		case 37:
			return BrowserIcon;
		//sentiment
		case 38:
			return IconIcon;

		//useragent
		case 39:
			return UserAgentIcon;

		//image
		case 40:
			return MultiMediaIcon;

		//viewport
		case 43:
			return ViewPortIcon;

		//device
		case 45:
			return DeviceIcon;

		//form completion perc
		case 46:
			return FormCompletionPercentageIcon;

		//translated open feedback
		case 48:
			return GTranslateIcon;

		default:
			return CategoryIcon;
	}
}

function blockTypeReducer({ block_type, system_var_simple }) {
	if (system_var_simple === 'tags') {
		return TagIcon;
	}

	switch (parseInt(block_type)) {
		//input
		case 1:
			return InputIcon;
		//textarea
		case 2:
			return TextAreaIcon;

		//radio
		case 3:
			return RadioIcon;

		//checkbox
		case 4:
			return CheckboxIcon;

		//select
		case 5:
			return DropdownIcon;

		//rating
		case 6:
			return RatingIcon;

		//pagebreak
		case 7:
			return PageBreakIcon;

		//section_break
		case 8:
			return SectionBreakIcon;

		//progress
		case 9:
			return InputIcon;

		//nps
		case 10:
			return NpsIcon;

		//matrix
		case 11:
			return MatrixIcon;

		//likert
		case 12:
			return LikertIcon;

		//category
		case 13:
			return CategoryIcon;

		//ces
		case 14:
			return CesIcon;

		//contact
		case 15:
			return ContactIcon;

		//gcr
		case 16:
			return GcrIcon;

		//screenshot
		case 17:
			return ScreenshotIcon;

		//link
		case 18:
			return LinkIcon;

		//website data
		case 19:
			return WebsiteDataIcon;

		//thumbs
		case 20:
			return ThumbsIcon;

		//url parameter
		case 21:
			return LinkIcon;

		//multimedia
		case 22:
			return MultiMediaIcon;

		//name
		case 24:
			return UserIcon;

		//email
		case 25:
			return EmailIcon;

		//phone
		case 26:
			return PhoneIcon;

		default:
			return CategoryIcon;
	}
}

function blockNameReducer({ blockName }) {
	switch (blockName) {
		//input
		case 'input':
			return InputIcon;
		//textarea
		case 'textarea':
			return TextAreaIcon;

		//radio
		case 'radio':
			return RadioIcon;

		//checkbox
		case 'checkbox':
			return CheckboxIcon;

		//select
		case 'dropdown':
		case 'select':
			return DropdownIcon;

		//rating
		case 'rating':
			return RatingIcon;

		//star rating
		case 'rating_stars':
		case 'stars':
			return StarRatingIcon;

		//numeric rating
		case 'rating_numeric':
		case 'numeric':
			return NumericRatingIcon;

		//bar rating
		case 'rating_bar':
		case 'bar':
			return BarRatingIcon;

		//emoji rating
		case 'rating_emoji':
		case 'emoji':
			return EmojiRatingIcon;

		//pagebreak
		case 'page_break':
			return PageBreakIcon;

		//section_break
		case 'section_break':
		case 'text':
			return SectionBreakIcon;

		//progress
		case 'progress':
			return InputIcon;

		//nps
		case 'nps':
			return NpsIcon;

		//matrix
		case 'matrix':
			return MatrixIcon;

		//likert
		case 'likert':
			return LikertIcon;

		//category
		case 'category':
			return CategoryIcon;

		//ces
		case 'ces':
			return CesIcon;

		//contact
		case 'contact':
			return ContactIcon;

		//gcr
		case 'gcr':
			return GcrIcon;

		//screenshot
		case 'screenshot':
			return ScreenshotIcon;

		//link
		case 'link':
			return LinkIcon;

		//website data
		case 'website_data':
			return WebsiteDataIcon;

		//thumbs
		case 'thumbs':
			return ThumbsIcon;

		//url parameter
		case 'url_parameter':
			return LinkIcon;

		//multimedia
		case 'multimedia':
		case 'img':
			return MultiMediaIcon;

		case 'icon':
			return IconIcon;

		case 'intro':
			return IntroPageIcon;

		case 'name':
			return UserIcon;

		case 'email':
			return EmailIcon;

		case 'phone':
			return PhoneIcon;

		case 'ending':
			return EndingIcon;

		default:
			return CategoryIcon;
	}
}

function valueReducer(value = '') {
	const lowerCase = value => (typeof value === 'string' ? value.toLowerCase() : '');

	const matches = input => {
		const isMatch = (matchValue = '') => {
			return (
				typeof value === 'string' && lowerCase(value).indexOf(lowerCase(matchValue)) > -1
			);
		};

		return !Array.isArray(input)
			? isMatch(input)
			: input.some(inputOption => {
					return typeof inputOption !== 'object'
						? isMatch(inputOption)
						: inputOption.matchExact
						? inputOption.value === lowerCase(value)
						: isMatch(inputOption.value);
			  });
	};

	switch (true) {
		case matches(['chrome', 'chromium']):
			return <FaIcon icon={faChrome} />;

		case matches('firefox'):
			return <FaIcon icon={faFirefox} />;

		case matches('edge'):
			return <FaIcon icon={faEdge} />;

		case matches(['explorer', { matchExact: true, value: 'ie' }]):
			return <FaIcon icon={faInternetExplorer} />;

		case matches('safari'):
			return <FaIcon icon={faSafari} />;

		case matches('opera'):
			return <FaIcon icon={faOpera} />;

		case matches(['ios', 'macos', 'os x', 'iphone', 'ipad']):
			return <FaIcon icon={faApple} />;

		case matches('windows'):
			return <FaIcon icon={faWindows} />;

		case matches(['linux', 'debian', 'ubuntu']):
			return <FaIcon icon={faLinux} />;

		case matches('desktop'):
			return <FaIcon icon={faDesktop} />;

		case matches('tablet'):
			return <FaIcon icon={faTabletAlt} />;

		case matches('phone'):
			return <FaIcon icon={faMobileAlt} />;
		//translated open feedback
		case matches('translateIcon'):
			return <GTranslateIcon />;

		default:
			return null;
	}
}

function DataFieldIcon({
	blockName,
	value,
	forceIcon = false,
	badgeTooltip = false,
	...props
}) {
	const classes = useStyles();

	const { block_id, block_type, question_type } = props;

	const IconComponent =
		block_type && block_id
			? blockTypeReducer({ ...props })
			: blockName
			? blockNameReducer({ blockName, ...props })
			: question_type
			? blockNameReducer({ blockName: question_type, ...props })
			: varTypeReducer({ ...props });

	//use forceIcon when value can be anything but you need a static badge
	const valueBadge = forceIcon
		? valueReducer(forceIcon)
		: value
		? valueReducer(value)
		: null;

	return isValidElement(valueBadge) ? (
		<RenderConditional
			component={Tooltip}
			title={badgeTooltip}
			condition={badgeTooltip}
		>
			<Badge
				classes={{
					badge: classes.badge,
				}}
				badgeContent={cloneElement(valueReducer(forceIcon ? forceIcon : value), {
					className: classes.badgeIcon,
				})}
			>
				<IconComponent {...props} />
			</Badge>
		</RenderConditional>
	) : (
		<IconComponent {...props} />
	);
}

export default memo(DataFieldIcon);
