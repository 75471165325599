import React, { useState, Fragment, useEffect } from 'react';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';

import Search from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, ButtonAddList, Loader } from '../Layout';
import { filterDrawerWidth } from '../Layout/constants';

import { tryParse } from '../../utils';
import { useDataSourceContext } from '../DataSources';
import { DataSourceSelect } from '../Select';
import {
	useListFilterContext,
	FilterGroup,
	CustomFilter,
	FilterDrawer,
	CustomFilterSidebarChip,
	SaveCustomView,
} from '../FilteredLists';
import { useEFM } from '../Ajax';
import { useTagContext } from '../Tags';
import { useAppContext } from '../AppContext';

const useStyles = makeStyles(theme => ({
	gridPadding: {
		padding: theme.spacing(1),
	},
	filterGroupWrap: {
		padding: theme.spacing(1),
		'& > div:not(:last-child)': {
			marginBottom: theme.spacing(2),
		},
	},
	closeWrap: {
		position: 'relative',
	},
	closeButton: {
		position: 'absolute',
		top: -8,
		right: 0,
	},
	filterGroups: {
		maxWidth: '100%',
	},
	drawerPaper: {
		width: filterDrawerWidth,
		zIndex: theme.zIndex.appBar - 1,
	},
	persistentDrawerPaper: {
		...JSON.parse(JSON.stringify(theme.mixins.toolbar).replace(/minHeight/g, 'top')),
		height: `calc(100% - 64px)`,
	},
	marginBottom: {
		marginBottom: theme.spacing(4),
	},
}));

export default function FeedbackListFilters({ toggleScroll }) {
	const { t } = useTranslation();
	const { app } = useAppContext();
	const { datasource } = useDataSourceContext();
	const [showCustom, setShowCustom] = useState(false);

	const classes = useStyles();

	useEffect(() => {
		if (toggleScroll) {
			toggleScroll(showCustom);
		}
	}, [showCustom, toggleScroll]);

	const {
		setMultiFilter,
		setSingleFilter,
		setCustomFilter,
		filters,
		other,
		customFilters,
		setCustomView,
		customView,
	} = useListFilterContext();

	const { tags } = useTagContext();
	const mappedTags = tags.tagList.map(tag => {
		return {
			value: tag,
			label: tag,
			attr: 'inbox_filter_tags_custom',
		};
	});

	function addFilter() {
		const lastInArr = customFilters[customFilters.length - 1];
		if (!lastInArr || (lastInArr.identifier && lastInArr.value.length > 0)) {
			setCustomFilter({
				action: 'add',
				custom: {
					type: 'datafield',
					identifier: '',
					value: [],
				},
			});
		}
		setShowCustom(true);
	}

	const [customViewData, customViewsLoading, err, loadData] =
		useEFM('/filters/get-filter');
	const customViews =
		customViewData?.data
			?.filter(view =>
				view.rules.every(
					rule =>
						datasource.api.getField(rule.identifier)?.project_uuid ==
							app.projects.current.uuid ||
						datasource.api.getField(rule.identifier)?.project_id ==
							app.projects.current.id
				)
			)
			.map(view => {
				const description = tryParse(view.description);
				view.rules = view.rules.map(rule => {
					rule.surveyId = description.survey_id;
					return rule;
				});
				return view;
			}) ?? [];

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Type to start searching')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={filters.search ?? ''}
					onChange={e => setSingleFilter('search', e.target.value)}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t`Feedback source`}
					noCollapse
					filterKey={'datasource'}
				>
					<DataSourceSelect
						value={other.survey_id ?? ''}
						emptyLabel={t`All feedback`}
						onChange={e => setSingleFilter('survey_id', e.target.value, true)}
					/>
				</FilterGroup>
			</div>

			<div>
				<Grid
					container
					direction="column"
					wrap="wrap"
				>
					<Grid item>
						<Typography variant="subtitle2">{t('Custom filters')}</Typography>
					</Grid>
					<Grid
						item
						container
						wrap="wrap"
					>
						{customViewsLoading ? (
							<Loader
								size={16}
								circular
							/>
						) : (
							customFilters
								.filter(
									filter => filter.identifier && filter.value && filter.value.length > 0
								)
								.map((filter, index) => (
									<CustomFilterSidebarChip
										onClick={() => setShowCustom(true)}
										filter={filter}
										index={index}
										key={'custom-' + index}
										tooltip={t`Click to edit`}
									/>
								))
						)}
					</Grid>
					<Grid item>
						<ButtonAddList
							onClick={addFilter}
							label={t`Add filter`}
						/>
					</Grid>
				</Grid>
			</div>
			<FilterDrawer
				open={showCustom}
				title={t`Custom filters`}
				onClose={() => setShowCustom(false)}
			>
				<Typography
					variant="subtitle1"
					color="textSecondary"
				>
					{t('Select datafields and values to filter on')}
				</Typography>

				<Box mb={2}>
					{customFilters.map((filter, index) => (
						<CustomFilter
							forceUseSurvey={other.survey_id ? true : false}
							{...filter}
							index={index}
							surveyId={other.survey_id || filter.surveyId || null}
							key={filter.identifier ? filter.identifier : index}
						/>
					))}

					<ButtonAddList
						onClick={e =>
							setCustomFilter({
								action: 'add',
								custom: {
									type: 'datafield',
									identifier: '',
									value: [],
								},
							})
						}
						label={t`Add filter`}
					/>
				</Box>
				<Divider />
				<Box mt={2}>
					<SaveCustomView
						customViewList={customViews}
						updateFilters={loadData}
					/>
				</Box>
			</FilterDrawer>

			<div>
				<FilterGroup
					filterName={t('Custom views')}
					filters={(Array.isArray(customViews) ? customViews : []).map(view => {
						view.selected = customView && view.id === customView;
						return view;
					})}
					onChange={(custom, id) => {
						setCustomFilter({ action: 'set', custom: custom });
						setCustomView(id);
					}}
					custom
					openFilterDrawer={() => setShowCustom(true)}
					updateFilters={loadData}
				>
					{customViewsLoading ? (
						<Loader
							size={16}
							circular
						/>
					) : customViews.length === 0 ? (
						<Typography
							variant="caption"
							color="textSecondary"
						>{t`No custom views yet. You can add custom filters and save them into a view.`}</Typography>
					) : null}
				</FilterGroup>
			</div>

			<div>
				<FilterGroup
					filterName={t('Tags')}
					filterKey={'tags'}
					filters={[
						{ label: t('No tags'), value: 'withouttags', attr: 'inbox_filter_tags_all' },
						{ label: t('All tags'), value: 'all_tags', attr: 'inbox_filter_tags_none' },
						...mappedTags,
					].map(filter => {
						if ((filters.tags || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					autoComplete={mappedTags}
					favorites={tags.tagCount ?? {}}
					onChange={({ key, value }) => {
						if (value.indexOf('all_tags') > -1) {
							value = mappedTags.map(tag => tag.value);
						} else if (value.indexOf('withouttags') > -1) {
							value = ['withouttags'];
						}
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Open comment')}
					filterKey={'comment'}
					filters={[
						{
							label: t('With comment'),
							value: '>',
							attr: 'inbox_filter_with_comments',
							testEl: 'feedbackWithComment',
						},
						{
							label: t('Without comment'),
							value: '=',
							attr: 'inbox_filter_without_comments',
							testEl: 'feedbackWithoutComment',
						},
					].map(filter => {
						if (filter.value === filters.comment) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => {
						setSingleFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Device')}
					filterKey={'devices'}
					filters={[
						{ label: 'Desktop', value: 'desktop', attr: 'inbox_filter_device_desktop' },
						{ label: 'Tablet', value: 'tablet', attr: 'inbox_filter_device_tablet' },
						{ label: 'Mobile', value: 'mobile', attr: 'inbox_filter_device_mobile' },
					].map(filter => {
						if ((filters.devices || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Browser')}
					filterKey={'browsers'}
					filters={[
						{ label: 'Chrome', value: 'chrome', attr: 'inbox_filter_browser_chrome' },
						{ label: 'Safari', value: 'safari', attr: 'inbox_filter_browser_safari' },
						{ label: 'Firefox', value: 'firefox', attr: 'inbox_filter_browser_firefox' },
						{ label: 'Edge', value: 'edge', attr: 'inbox_filter_browser_edge' },
						{
							label: 'Internet Explorer',
							value: 'internet_explorer',
							attr: 'inbox_filter_browser_internetExplorer',
						},
						{ label: 'Opera', value: 'opera', attr: 'inbox_filter_browser_opera' },
						{ label: 'Other', value: 'other', attr: 'inbox_filter_browser_other' },
					].map(filter => {
						if ((filters.browsers || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Operating system')}
					filterKey={'os'}
					filters={[
						{ label: 'Windows', value: 'windows', attr: 'inbox_filter_os_windows' },
						{ label: 'macOS', value: 'macos', attr: 'inbox_filter_os_macOS' },
						{ label: 'iOS', value: 'ios', attr: 'inbox_filter_os_iOS' },
						{ label: 'Android', value: 'android', attr: 'inbox_filter_os_android' },
						{ label: 'Chrome OS', value: 'chromeos', attr: 'inbox_filter_os_chromeOS' },
						{ label: 'Linux', value: 'linux', attr: 'inbox_filter_os_linux' },
						{ label: 'Other', value: 'other', attr: 'inbox_filter_other' },
					].map(filter => {
						if ((filters.os || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Sent email replies')}
					filterKey={'email'}
					filters={[
						{
							label: t('With sent email replies'),
							value: '>',
							attr: 'inbox_filter_with_email',
						},
						{
							label: t('Without sent email replies'),
							value: '=',
							attr: 'inbox_filter_without_email',
						},
					].map(filter => {
						if (filter.value === filters.email) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => {
						setSingleFilter(key, value);
					}}
				/>
			</div>

			<div className={classes.marginBottom}>
				<FilterGroup
					filterName={t('Actions')}
					filterKey={'actions'}
					filters={[
						{ label: t('With actions'), value: '>', attr: 'inbox_filter_actions_with' },
						{
							label: t('Without actions'),
							value: '=',
							attr: 'inbox_filter_actions_without',
						},
					].map(filter => {
						if (filters.actions === filter.value) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => {
						setSingleFilter(key, value);
					}}
				/>
			</div>
		</Fragment>
	);
}
