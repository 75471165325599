import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import ActionIconButton from './ActionIconButton';

const useStyles = makeStyles(theme => ({
	default: {
		color: theme.palette.text.secondary,
		border: 0,
		backgroundColor: 'transparent',
	},
	icon: {
		color: theme.palette.text.secondary + '!important',
	},
	success: {
		background: theme.palette.secondary.light,
	},
}));

export default function Alert({
	children,
	severity = 'success',
	variant,
	title,
	css = {},
	close,
	onClose = () => {},
	...props
}) {
	const [isClosed, setIsClosed] = useState(false);
	const classes = useStyles();
	const AlertProps = {
		variant: variant === 'default' ? 'outlined' : variant,
		severity: variant === 'default' ? 'info' : severity,
		...(variant === 'default' && {
			classes: {
				icon: classes.icon,
				root: classes.default,
			},
		}),
		...(variant === 'filled' &&
			severity === 'success' && {
				classes: {
					root: classes.success,
				},
			}),
		...(close && {
			action: (
				<ActionIconButton
					action="close"
					onClick={() => {
						onClose();
						setIsClosed(true);
					}}
					color="inherit"
				/>
			),
		}),
		...props,
	};

	return (
		<Box
			clone
			{...css}
		>
			<Collapse in={!isClosed}>
				<MuiAlert {...AlertProps}>
					{title && <AlertTitle>{title}</AlertTitle>}
					{children}
				</MuiAlert>
			</Collapse>
		</Box>
	);
}
