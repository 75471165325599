import React, { useMemo } from 'react';

import { useAppContext } from '../AppContext';

import { useLocation } from 'react-router-dom';

import { useSessionStorage } from '../../hooks';

export default function useFilteredList({
	filters: filtersFromProps = {},
	other: otherFromProps = {},
	custom: customFromProps = [],
	view: viewFromProps = null,
	sort: sortFromProps = '',
	order: orderFromProps = '',
}) {
	const { app } = useAppContext();
	const { pathname } = useLocation();

	function sessionKey(key) {
		return `${key}-${app.projects.current.id}-${pathname}`;
	}

	const [main, setMain] = useSessionStorage('main', filtersFromProps);
	const [other, setOther] = useSessionStorage('other', otherFromProps);
	const [custom, setCustom] = useSessionStorage('custom', customFromProps);
	const [view, setView] = useSessionStorage('view', viewFromProps);
	const [sort, setSort] = useSessionStorage('sort', sortFromProps);
	const [order, setOrder] = useSessionStorage('order', orderFromProps);

	const hasFilters = useMemo(() => {
		const hasMainFilters = Object.values(main).some(value => value);
		const hasOtherFilters = Object.values(other).some(value => value);
		const hasCustom = custom.length > 0;

		return hasMainFilters || hasOtherFilters || hasCustom;
	}, [main, other, custom]);

	function setFilter(isOther, fn) {
		return isOther ? setOther(fn) : setMain(fn);
	}

	function setSingleFilter(key, value, isOther = false) {
		setFilter(isOther, state => ({
			...state,
			[key]: value,
		}));
	}

	function setMultiFilter(key, value = [], isOther = false) {
		setFilter(isOther, state => {
			if (value.length) {
				return {
					...state,
					[key]: value,
				};
			} else {
				const { [key]: removeKey, ...rest } = state;
				return rest;
			}
		});
	}

	function setCustomFilter({ action, custom, index }) {
		setCustom(state => {
			switch (action) {
				case 'set':
					return custom;

				case 'remove':
					return state.filter((custom, i) => i !== index);

				case 'add':
					return [...state, custom];

				case 'update':
					return state.map((filter, i) => {
						if (i === index) {
							return { ...filter, ...custom };
						}
						return filter;
					});
			}
		});
	}

	function setCustomView(id) {
		setView(id);
	}

	function clearAllFilters() {
		setMain({});
		setOther({});
		setCustom([]);
		setView(null);
	}

	return {
		filters: main,
		other,
		customFilters: custom,
		queryFilters: createQueryFilter(custom),
		customView: view,
		sort,
		order,
		setSingleFilter,
		setMultiFilter,
		setCustomFilter,
		setCustomView,
		setSort,
		setOrder,
		clearAllFilters,
		hasFilters,
	};
}

function createQueryFilter(customFilters) {
	if (!Array.isArray(customFilters)) return JSON.stringify({});

	const { data_field_id, value } = customFilters.reduce(
		(filterObj, currentFilter) => {
			if (currentFilter.identifier && currentFilter.value?.length > 0) {
				const valueArrayCleanedForCommas = currentFilter.value.map(val =>
					String(val).replace(/,/g, '&#44;')
				);

				filterObj.data_field_id.push(currentFilter.identifier);
				filterObj.value.push(`[${valueArrayCleanedForCommas.join(',')}]`);
			}

			return filterObj;
		},
		{
			data_field_id: [],
			value: [],
		}
	);

	return JSON.stringify(
		data_field_id.length > 0 && value.length > 0
			? {
					data_field_id: data_field_id.join('{PIPE}'),
					value: value.join('{PIPE}'),
			  }
			: {}
	);
}
