import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { EFM, Mjolnir, BackendBauer, OfficeDrones } from '@/api';
import AppContext from './AppContext';
import { reverseDate, makeKeyedObject, objectToArray } from '@/helpers';
import { triggerEvent, tryParse, getCookie, setGlobalSessionData } from '@/utils';
import { format } from '@/utils/date';
import { useLocalStorage } from '@/hooks';
import useRedirector from './useRedirector';

function reducer(draft, action) {
	switch (action.type) {
		case 'set_login_data':
			Object.assign(draft.auth, action.payload);
			if (action.payload.mixpanel) {
				draft.mixpanel = action.payload.mixpanel;
			}
			if (action.payload.domain) {
				draft.domain = action.payload.domain;
			}
			draft.auth.loading = false;
			return;

		case 'update_login_data':
			draft.users.current.firstname = action.payload.firstname;
			draft.users.current.lastname = action.payload.lastname;
			return;

		case 'set_initial_app_data_loading':
			draft.loading = true;
			return;

		case 'set_initial_app_data':
			const { dateSeparator, initialFromDate, initialToDate } = getDateSettings(
				action.payload
			);
			const tokens = getTokens(action.payload);

			if (action.payload.surveyJsUrl) {
				draft.surveyJsUrl = action.payload.surveyJsUrl;
			}

			draft.domain = action.payload.domain;
			draft.users.current = action.payload.user;
			draft.projects.current = action.payload.project;
			draft.organisations.current = action.payload.organisation;
			draft.roles.current = action.payload.role;
			draft.package = action.payload.package;
			draft.social = action.payload.social;
			draft.session = action.payload.session;
			draft.tokens = tokens;
			draft.enforceMfa =
				action.payload.mfa_policy === 'domain' ||
				(action.payload.mfa_policy === 'organisation' &&
					action.payload.organisation.force_mfa === 'yes');
			draft.mixpanel = action.payload.mixpanel;
			draft.language.id = action.payload.user.language;

			draft.date.separator = dateSeparator;
			draft.date.format = `dd${dateSeparator}MM${dateSeparator}yyyy`;
			draft.date.fromDate = reverseDate(initialFromDate, dateSeparator);
			draft.date.toDate = reverseDate(initialToDate, dateSeparator);
			draft.loading = false;

			return;

		case 'set_session_data':
			Object.assign(draft.session, action.payload);
			return;

		case 'set_projects':
			draft.projects.byKey = makeKeyedObject(action.payload.projects, 'id');
			draft.projects.asArray = action.payload.projects;

			if (draft.projects.byKey[draft.projects.current.id]) {
				draft.projects.current = draft.projects.byKey[draft.projects.current.id];
			}
			return;

		case 'change_project':
			Object.assign(draft.projects.current, action.payload);
			return;

		case 'set_organisations':
			draft.organisations.byKey = makeKeyedObject(action.payload.organisations, 'org_id');
			draft.organisations.asArray = action.payload.organisations;

			if (draft.organisations.byKey[draft.organisations.current.org_id]) {
				draft.organisations.current =
					draft.organisations.byKey[draft.organisations.current.org_id];
			}

			return;

		case 'change_organisation':
			Object.assign(draft.organisations.current, action.payload);
			return;

		case 'set_users':
			draft.users.byKey = makeKeyedObject(action.payload.users, 'id');
			draft.users.asArray = action.payload.users;

			const currentUserInResponse = action.payload.users.find(
				user => user.id == draft.users.current.id
			);
			Object.assign(draft.users.current, currentUserInResponse);

			//temporarily map uuids to int ids
			draft.users.inProject = action.payload.in_project.map(
				uuid => action.payload.users.find(user => user.uuid === uuid)?.id
			);
			return;

		case 'set_supervisors':
			const supervisors = action.payload.supervisors ?? [];
			Object.assign(draft.users.byKey, makeKeyedObject(supervisors, 'id'));

			supervisors.forEach(supervisor => {
				if (!draft.users.asArray.some(user => user.id == supervisor.id)) {
					draft.users.asArray.push(supervisor);
				}
				if (!draft.users.inProject.includes(supervisor.id)) {
					draft.users.inProject.push(supervisor.id);
				}
			});
			return;

		case 'set_roles':
			draft.roles.byKey = action.payload.roles;
			draft.roles.asArray = action.payload.roles;
			return;

		case 'change_role':
			return;

		case 'change_date':
			Object.assign(draft.date, action.payload);
			return;

		case 'set_action_settings':
			draft.actions.types = action.payload.actionTypes;
			draft.actions.status = action.payload.actionStatus;
			return;

		case 'set_answers':
			draft.answers.byKey = objectToArray(action.payload.answers, 'id');
			draft.answers.asArray = action.payload.answers;
			return;

		case 'set_utc_timezones':
			draft.timezones.asArray = action.payload.timezones;
			return;

		case 'set_title':
			draft.layout.title = action.payload;
			return;

		case 'set_app_bar_ref':
			draft.layout.appBarRef = action.payload;
			return;

		case 'set_app_actions_ref':
			draft.layout.appBarActionsRef = action.payload;
			return;

		case 'set_extra_filters_ref':
			draft.layout.appBarExtraFiltersRef = action.payload;
			return;

		case 'set_app_bar_left_content_ref':
			draft.layout.appBarLeftContentRef = action.payload;
			return;

		case 'app_bar_left_content_classes':
			draft.layout.appBarLeftContentClasses = action.payload;
			return;

		case 'app_bar_left_content_tabs':
			draft.layout.appBarLeftContentTabs = action.payload;
			return;

		case 'set_page_header_ref':
			draft.layout.pageHeaderRef = action.payload;
			return;

		case 'set_drawer_scroller_ref':
			draft.layout.drawerScrollerRef = action.payload;
			return;

		case 'set_hide_filters':
			draft.layout.hideGlobalFilters = action.payload;
			return;

		case 'main_menu':
			draft.layout.mainMenuOpen = action.payload;
			return;

		case 'persistent_drawer':
			draft.layout.persistentDrawer = action.payload;
			return;

		case 'set_loading':
			draft.layout.appLoading = action.payload;
			return;

		case 'set_loading_cancel_pending':
			draft.layout.appLoadingFull = action.payload;
			draft.cancelPending = action.payload;
			return;

		case 'set_language':
			draft.language.id = action.payload;
			return;

		case 'set_prominent_app_bar':
			draft.layout.appBarProminent = action.payload;
			return;

		case 'set_scroll_top':
			draft.layout.scrollTop = action.payload;
			return;

		case 'set_prominent_hidden':
			draft.layout.prominentHidden = action.payload;
			return;

		case 'set_package':
			draft.package = action.payload.package;
			return;

		case 'set_logged_out':
			draft.layout.loggedOut = true;
			draft.layout.loggedOutReason = action.payload;
			return;

		default:
			return;
	}
}

function getDateSettings({ date } = {}) {
	const dateSeparatorOpposite = { '-': '/', '/': '-' };

	let dateSeparator = '',
		initialFromDate = '',
		initialToDate = '';

	if (date) {
		dateSeparator = date.format.indexOf('-') > -1 ? '-' : '/';
		initialFromDate =
			date.fromDate?.indexOf(dateSeparator) === -1
				? date.fromDate.replace(
						new RegExp(dateSeparatorOpposite[dateSeparator], 'g'),
						dateSeparator
				  )
				: date.fromDate;
		initialToDate =
			date.toDate?.indexOf(dateSeparator) === -1
				? date.toDate.replace(
						new RegExp(dateSeparatorOpposite[dateSeparator], 'g'),
						dateSeparator
				  )
				: date.toDate;
	}
	return {
		dateSeparator,
		initialFromDate:
			initialFromDate ?? format(new Date(), `dd${dateSeparator}MM${dateSeparator}yyyy`),
		initialToDate:
			initialToDate ?? format(new Date(), `dd${dateSeparator}MM${dateSeparator}yyyy`),
	};
}

function setDefaultProject() {
	EFM.post('/account/ajax/set-project');
}

function getTokens({ tokens } = {}) {
	let parsedTokens = {};

	try {
		parsedTokens = JSON.parse(atob(tokens));
	} catch (e) {}

	return parsedTokens;
}

function getTranslationLanguage(id) {
	switch (Number(id)) {
		case 1:
			return 'nl';
		case 2:
		default:
			return 'en';
	}
}

function initDataLayer(dataLayerData) {
	let count = 0;
	const pushWhenReady = setInterval(() => {
		if (count === 10) {
			clearInterval(pushWhenReady);
		}

		if (Array.isArray(window.dataLayer)) {
			clearInterval(pushWhenReady);
			setGlobalSessionData(dataLayerData, true);
		}

		count++;
	}, 300);
}

export default function AppContextProvider({ children, locale, setLocale = () => {} }) {
	const { enqueueSnackbar } = useSnackbar();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [previousMenuOpen, setPreviousMenuOpen] = useLocalStorage('mainMenuOpen');

	const [state, dispatch] = useImmerReducer(reducer, {
		auth: {
			loggedIn: false,
			loading: true,
			options: [],
		},
		domain: '',
		//currently -> get from own subdomain, after survey decoupling it will get overwritten by the response
		surveyJsUrl: '/assets/surveys/2.0/js/survey.min.js',
		users: {
			current: {},
			byKey: {},
			asArray: [],
			inProject: [],
		},
		projects: {
			current: {},
			byKey: {},
			asArray: [],
		},
		organisations: {
			current: {},
			byKey: {},
			asArray: [],
		},
		roles: {
			current: {},
			byKey: {},
			asArray: [],
		},
		date: {
			separator: '',
			format: '',
			fromDate: '',
			toDate: '',
		},
		layout: {
			mainMenuOpen:
				typeof previousMenuOpen !== 'undefined'
					? previousMenuOpen
					: window.innerWidth >= 1200,
			appLoading: false,
			persistentDrawer: window.innerWidth >= 1200,
			title: '',
			appBarRef: {},
			appBarActionsRef: {},
			appBarExtraFiltersRef: {},
			appBarLeftContentRef: {},
			pageHeaderRef: {},
			drawerScrollerRef: {},
			appBarLeftContentClasses: '',
			appBarLeftContentTabs: false,
			hideGlobalFilters: [],
			appBarProminent: false,
			prominentHidden: false,
			scrollTop: 0,
			loggedOut: false,
			loggedOutReason: '',
			//  reduce:()=>{}
		},
		package: {
			name: '',
		},
		tokens: {},
		social: {},
		language: {
			id: 2,
		},
		locale: '',
		actions: {
			status: [],
			types: [],
		},
		answers: {
			asArray: [],
			byKey: {},
			getAnswers: () => {},
		},
		timezones: {
			asArray: [],
		},
		session: {},
		cancelPending: false,
	});

	useRedirector({
		loggedIn: state.auth.loggedIn,
		allowChange: state.projects.asArray.length > 0,
		changeProject: id => {
			const project = state.projects.byKey[id];
			changeProject(project ?? { id });
		},
	});

	useEffect(() => {
		getLoginData();
	}, []);

	useEffect(() => {
		if (state.layout.mainMenuOpen !== previousMenuOpen) {
			setPreviousMenuOpen(state.layout.mainMenuOpen);
		}
	}, [state.layout.mainMenuOpen]);

	//set logged out language / trial
	useEffect(() => {
		if (!state.auth.loggedIn) {
			//get lang from cookie on mopinion website
			const langCookie = getCookie('MOP_LANG');
			if (langCookie === 'nl' || langCookie === 'en') {
				dispatch({ type: 'set_language', payload: langCookie === 'nl' ? 1 : 2 });
				i18n.changeLanguage(langCookie);
				return;
			}

			//otherwise get lang from browser only change if nl otherwise use default (en)
			const browserLang = navigator.language.toLowerCase().split('-')[0];
			if (browserLang === 'nl') {
				dispatch({ type: 'set_language', payload: 1 });
				i18n.changeLanguage(browserLang);
				return;
			}
		} else if (state.language) {
			i18n.changeLanguage(Number(state.language.id) === 1 ? 'nl' : 'en');
		}
	}, [state.auth.loggedIn, state.language.id]);

	useEffect(() => {
		if (
			state.auth.loggedIn &&
			state.projects.current.id &&
			state.organisations.current.org_id
		) {
			getUsers();
			getProjects();
			getOrganisations();
			getActionSettings();
		}
	}, [
		state.projects.current.id,
		state.organisations.current.org_id,
		state.auth.loggedIn,
	]);

	useEffect(() => {
		if (state.organisations.current.org_id) {
			getPackage();
		}
	}, [state.organisations.current.org_id]);

	useEffect(() => {
		setTimeout(() => triggerEvent(window, 'resize'), 200);
	}, [state.layout.mainMenuOpen]);

	async function startApp() {
		getInitialAppData();
		dispatch({ type: 'set_login_data', payload: { loggedIn: true } });
	}

	async function getLoginData() {
		const response = await EFM.post('/auth/ajax/get-login-data');
		if (response.code === 200) {
			dispatch({
				type: 'set_login_data',
				payload: {
					...response,
					loggedIn: response.logged_in,
				},
			});
			EFM.init(response.csrf);
			if (response.logged_in) {
				startApp();
			}
		}
	}

	async function getInitialAppData() {
		dispatch({ type: 'set_initial_app_data_loading' });
		const response = await EFM.get('/application/ajax/get-initial-app-data');
		if (response.code === 200) {
			const tokens = getTokens(response);
			EFM.init(tokens.csrf);
			Mjolnir.init(tokens.csrf);
			BackendBauer.init(tokens.csrf);
			OfficeDrones.init(tokens.csrf);
			dispatch({ type: 'set_initial_app_data', payload: response });
			initDataLayer(response.session);
			Sentry.setUser({ id: response.user.uuid });
		}
	}

	async function logout(param) {
		const response = await EFM.post('/auth/ajax/logout');
		if (response.code === 200) {
			dispatch({ type: 'set_login_data', payload: { loggedIn: false } });
			if (response.logout_reason === 'security') {
				history.push('/login', { security_logout: true });
			} else if (param) {
				history.push('/login', param);
			} else {
				history.push('/login');
			}

			getLoginData();
		}
	}

	async function updateSessionData() {
		const response = await EFM.post('/application/ajax/update-session-data');
		if (response.code == 200) {
			dispatch({ type: 'set_session_data', payload: response.session });
			setGlobalSessionData(response.session, true);
		}
	}

	async function getProjects() {
		const response = await EFM.post('/application/ajax/get-projects');
		if (response.code == 200) {
			dispatch({ type: 'set_projects', payload: response });
			//if the current project id doesnt exist in response we should switch
			if (
				!response.projects.find(proj => proj.id == state.projects.current.id) &&
				response.projects.length > 0
			) {
				changeProject(response.projects[0]);
				return;
			}
			dispatch({ type: 'set_loading', payload: false });
		}
	}

	async function changeProject(project) {
		dispatch({ type: 'set_loading_cancel_pending', payload: true });
		const response = await EFM.post('/application/ajax/change-project', {
			project: project.id,
		});
		if (response.code == 200 || response.code == 201) {
			dispatch({ type: 'change_project', payload: project });
			enqueueSnackbar(t`Switched to report` + ` ${project.name}`);
			updateSessionData();
			setDefaultProject();
		} else if (response.code == 401) {
			enqueueSnackbar(t`You don't have access to` + ` ${project.name}`);
		}
		dispatch({ type: 'set_loading_cancel_pending', payload: false });
	}

	async function getOrganisations() {
		const response = await EFM.post('/application/ajax/get-organisations');
		if (response.code == 200) {
			dispatch({ type: 'set_organisations', payload: response });
		}
	}

	async function changeOrganisation(organisation) {
		dispatch({ type: 'set_loading', payload: true });
		const response = await EFM.post('/application/ajax/change-organisation', {
			organisation: { id: organisation.org_id, uuid: organisation.uuid },
		});
		if (response.code == 200 || response.msg === 'ok') {
			dispatch({ type: 'change_organisation', payload: organisation });
			enqueueSnackbar(t`Switched to organistion` + ` ${organisation.name}`);
			getProjects();
			return;
		}
		dispatch({ type: 'set_loading', payload: false });
	}

	async function getUsers() {
		const response = await EFM.post('/application/ajax/get-users');
		if (response.code == 200) {
			dispatch({ type: 'set_users', payload: response });
		}
		getSupervisors();
	}

	async function getSupervisors() {
		const response = await EFM.post('/application/ajax/get-supervisors');
		if (response.code == 200) {
			dispatch({ type: 'set_supervisors', payload: response });
		}
	}

	async function changeDate(fromDate, toDate) {
		dispatch({ type: 'set_loading', payload: true });
		const response = await EFM.post('/application/ajax/change-date', {
			fromdate: fromDate,
			todate: toDate,
		});
		if (response.code == 200) {
			dispatch({ type: 'change_date', payload: { fromDate, toDate } });
			enqueueSnackbar(t`Date range updated`);
		}
		dispatch({ type: 'set_loading', payload: false });
	}

	async function getActionSettings() {
		const response = await EFM.post('/actions/ajax/settings');
		if (response.code == 200) {
			dispatch({ type: 'set_action_settings', payload: response });
		}
	}

	async function getAnswers() {
		const response = await EFM.post('/application/email/answers');
		if (response.code == 200) {
			dispatch({ type: 'set_answers', payload: response });
		}
	}

	async function changeLanguage(language) {
		dispatch({ type: 'set_language', payload: language.id });
		i18n.changeLanguage(getTranslationLanguage(language.id));

		setLocale(language.id == 2 ? 'nl' : navigator.language.toLowerCase().split('-')[1]);

		const response = await EFM.post('/application/ajax/change-language', {
			lang: language.id,
		});
		enqueueSnackbar(t`Language settings updated`);
	}

	async function getTimezoneSettings() {
		const response = await EFM.post('/application/ajax/get-utc-timezones');
		if (response.code == 200) {
			dispatch({ type: 'set_utc_timezones', payload: response });
		}
	}

	async function getPackage() {
		const response = await EFM.post('/application/ajax/get-package');
		if (response.code == 200) {
			dispatch({ type: 'set_package', payload: { package: response.package } });
		}
	}

	function userIsAllowed(type) {
		const billingRights = ['billing'];
		const editRights = ['add', 'edit', 'delete'];
		const viewRights = ['view-integrations', 'my-organisation', 'billing'];

		const levelRights = {
			//superadmin
			1: [...editRights, ...viewRights],
			//admin
			2: [...editRights, ...viewRights],
			//manager
			3: [...editRights],
			//normal
			4: [],
			//supervise
			5: [...editRights],
			//ssadmin
			6: [...editRights, ...viewRights],
			//ssnormal,
			7: [],
			//ssfreemium
			8: [...editRights],
		};

		if (type === 'billing') {
			return state.users.current.owner;
		}

		return levelRights[Number(state.users.current.level)]?.indexOf(type) > -1;
	}

	function packageIsAllowed(action) {
		const upgradeRights = ['upgrade'];
		const contactRights = ['contact'];
		const feedbackApiRights = ['view-feedback-api'];

		const packageRight = {
			Freemium: [...upgradeRights],
			Trial: [...upgradeRights, ...feedbackApiRights],
			Basic: [...upgradeRights],
			Starter: [...upgradeRights],
			Growth: [...upgradeRights, ...contactRights, ...feedbackApiRights],
			'Growth (Demo)': [...upgradeRights, ...feedbackApiRights],
			'Turbo Growth': [...upgradeRights, ...contactRights, ...feedbackApiRights],
			Enterprise: [...contactRights, ...feedbackApiRights],
			Standard: [
				...upgradeRights,
				...contactRights,
				...feedbackApiRights,
				...feedbackApiRights,
			],
		};

		return packageRight[state.package.name]?.indexOf(action) > -1;
	}

	function getCurrentUser() {
		return state.users.current;
	}

	function getUserById(id) {
		return state.users.byKey[id] ?? {};
	}

	function getUserByUuid(uuid) {
		return state.users.asArray.find(user => user.uuid === uuid) ?? {};
	}

	function getUserByProp({ prop, value }) {
		return state.users.asArray.find(user => user[prop] === value) ?? {};
	}

	function packageProductAllowed(product) {
		return state.package[`allow_${product}`];
	}

	function getUserMeta(id) {
		const user = id ? state.users.byKey[id] : state.users.current;
		if (user && typeof user.meta === 'string') {
			return tryParse(user.meta, {});
		}
		if (user && user.meta && typeof user.meta === 'object') {
			return user.meta;
		}

		return {};
	}

	async function setUserMeta(updateObj) {
		const response = await EFM.post('/application/ajax/set-meta', { update: updateObj });
		if (response.code == 200) {
			getUsers();
		}
	}

	function getUserName(id) {
		const user = id ? state.users.byKey[id] : state.users.current;

		if (user) {
			return `${user.firstname} ${user.lastname}`;
		}

		return '';
	}

	function getProjectByUuid(uuid) {
		return state.projects.asArray.find(project => project.uuid === uuid) ?? {};
	}

	return (
		<AppContext.Provider
			value={{
				app: {
					...state,
					locale,
					dispatch,
					api: {
						changeProject,
						getProjects,
						getUsers,
						getOrganisations,
						changeOrganisation,
						changeDate,
						changeLanguage,
						userIsAllowed,
						packageIsAllowed,
						getPackage,
						getCurrentUser,
						getUserById,
						getUserByUuid,
						getUserByProp,
						getUserMeta,
						getUserName,
						setUserMeta,
						startApp,
						getInitialAppData,
						logout,
						packageProductAllowed,
						updateSessionData,
						getProjectByUuid,
					},
				},
			}}
		>
			{children}
		</AppContext.Provider>
	);
}
