import React, { useContext, useRef, useEffect, useState } from 'react';

import Zoom from '@material-ui/core/Zoom';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@/styles';
import {
	ProjectFilter,
	OrganisationFilter,
	DateRangeFilter,
} from '@/components/AppFilters';
import UserMenu from './UserMenu';
import { ActionButton, Typography } from '@/components/Layout';

import { matchesOneOf } from '@/utils';
import { AppContext } from '@/components/AppContext';
import { NotificationsMenu } from '@/components/Notifications';

import { useOnboardingContext } from '@/components/Onboarding';

const useStyles = makeStyles(theme => ({
	appBarFilters: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		overflowX: 'auto',
	},
	spaceChildren: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(0, 0.25),
		},
	},
	globalsWrap: {
		display: 'flex',
		paddingTop: props =>
			props.appBarProminent && !props.prominentHidden ? theme.spacing(1.5) : 0,
		maxWidth: '60%',
	},
	alignCenter: {
		alignItems: 'center',
	},
	upgradeButton: {
		borderColor: theme.palette.primary.main,
		'&:hover': {
			borderColor: theme.palette.primary.main,
		},
		marginRight: theme.spacing(1),
	},
	surveyEditor: {
		marginBottom: theme.spacing(1),
	},
	trialFlag: {
		display: 'flex',
		marginRight: theme.spacing(6),
		'& img': {
			marginRight: theme.spacing(1),
		},
	},
}));

const globalFilters = {
	date: DateRangeFilter,
	project: ProjectFilter,
	organisation: OrganisationFilter,
};

export default function AppBarGlobals({ forceFilters, filters = [] }) {
	const extraFiltersRef = useRef(null);
	const [state, setState] = useState([]);
	const [openUpgrade, setOpenUpgrade] = useState(false);
	const onboarding = useOnboardingContext();
	const {
		app,
		app: { layout },
	} = useContext(AppContext);
	const classes = useStyles({ ...layout });
	const { t } = useTranslation();

	const location = useLocation();

	useEffect(() => {
		if (extraFiltersRef) {
			app.dispatch({ type: 'set_extra_filters_ref', payload: extraFiltersRef });
		}
	}, [extraFiltersRef]);

	useEffect(() => {
		const filters = [];

		if (
			matchesOneOf(location.pathname, [
				{ regex: true, value: '/reporting/dashboard/' },
				{ exactMatch: true, value: '/reporting/data-explorer' },
				{ regex: true, value: '/reporting/chart' },
				{ regex: true, value: '^/reporting/direct-insights/[^a-z]+' },
				{ regex: true, value: '^/reporting/inbox((?!detail).)*$' },
				{ regex: true, value: '^/action/inbox((?!detail).)*$' },
			])
		) {
			filters.push('date');
		}

		if (!matchesOneOf(location.pathname, ['/edit', '/detail', '/chart'])) {
			filters.push('project', 'organisation');
		}

		setState(filters);
	}, [location.pathname]);

	return (
		<div
			className={classes.globalsWrap}
			data-onboarding="global-filters"
		>
			<div className={classes.appBarFilters}>
				<div
					className={classes.spaceChildren}
					ref={extraFiltersRef}
				/>

				{!app.session.onboarding_trial && (
					<div className={classes.spaceChildren}>
						{state.map(filterKey => {
							const Component = globalFilters[filterKey];
							return (
								<Zoom
									key={filterKey}
									in
								>
									<div>
										<Component />
									</div>
								</Zoom>
							);
						})}
					</div>
				)}
			</div>
			{onboarding.trialStep > -1 &&
				app.session.onboarding_trial &&
				location.pathname.includes('/list') && (
					<ActionButton
						className={classes.trialFlag}
						data-onboarding="onboarding-menu"
						data-test-element="onboarding-appbar-progress-button"
						onClick={() => {
							onboarding.dispatch({
								type: 'toggle_trial_menu',
								payload: {
									open: true,
								},
							});
						}}
					>
						<img src={`/assets/img/r/onboarding/trialstep_flag.svg`} />
						<Typography
							color="primary"
							fontWeight="bold"
						>
							{`${onboarding.trialStep <= 3 ? onboarding.trialStep : 3}/3`}
						</Typography>
					</ActionButton>
				)}
			{app.package.trial && !app.session.onboarding_trial && (
				<>
					<div className={`${classes.spaceChildren} ${classes.alignCenter}`}>
						<ActionButton
							action="upgrade"
							variant="outlined"
							color="primary"
							className={`${classes.upgradeButton} ${
								location.pathname.includes('/survey/edit') ? classes.surveyEditor : ''
							}`}
							dataTrackEvent="upgrade_from_header"
							link
							to="/account/billing/plans"
							tooltip={t`app_general-upgrade-button_tooltip`}
							TooltipProps={{
								enterDelay: 1500,
							}}
						>
							{t`Upgrade`}
						</ActionButton>
					</div>
				</>
			)}
			{!app.session.onboarding_trial && <NotificationsMenu />}
			<UserMenu />
		</div>
	);
}
