import { createMuiTheme } from '@material-ui/core/styles';
import { getSentimentColors } from './colors';

function getInverseColors(obj) {
	const newObj = {};

	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') {
			newObj[key] = getInverseColors(obj[key]); // recurse
		} else if (String(obj[key]).replace(/\s+/g, '').indexOf('rgba(0,0,0') > -1) {
			newObj[key] = obj[key]
				.replace(/\s+/g, '')
				.replace('rgba(0,0,0', 'rgba(255,255,255'); // copy value
		} else {
			newObj[key] = obj[key];
		}
	});

	return newObj;
}

let tempTheme = createMuiTheme({
	primary: {
		main: '#2B81E5',
	},
	secondary: {
		main: '#48d57d',
	},
});

const theme = createMuiTheme({
	palette: {
		getContrastText(color) {
			try {
				return tempTheme.palette.getContrastText(color);
			} catch (e) {
				return tempTheme.palette.getContrastText('#000');
			}
		},
		darkMode: getInverseColors(tempTheme.palette),
		primary: {
			main: '#2B81E5',
		},
		secondary: {
			main: '#48d57d',
			dark: '#2cbe63',
		},
		scores: {
			high: '#00c853',
			medium: '#ffd740',
			low: '#f44336',
		},
		background: {
			default: '#eeeeee',
		},
		mopinion: {
			main: '#303031',
			web: '#FEC000',
			sdk: '#8E54CC',
			email: '#39BFA9',
			dataset: '#a7ccd1ff',
			getSentimentColors,
		},
		survey: {
			default: {
				backgroundColor: '#ffffff',
				color: '#212121',
				colorSecondary: '#727272',
				borderColor: '#ddd',
				borderColorDark: '#b6b6b6',
				starInactive: '#ddd',
				starActive: '',
				errorColor: '#F44336',
				sentimentRange: ['#e51c23', '#ff5722', '#ffeb3b', '#8bc34a', '#259b24'],
			},
			'Snow White': {
				fontFamily: 'Open Sans',
				primary: {
					light: '#B3E5FC',
					main: '#03a9f4',
					dark: '#0288d1',
				},
				header: {
					backgroundColor: '#fff',
					color: '#212121',
				},
				accent: '#607D8B',
			},
			'Material Blue': {
				fontFamily: 'Open Sans',
				primary: {
					light: '#B3E5FC',
					main: '#03a9f4',
					dark: '#0288d1',
				},
				header: {
					backgroundColor: '#03a9f4',
					color: '#fff',
				},
				accent: '#607D8B',
			},
			'Indigo Dream': {
				fontFamily: 'Lora',
				primary: {
					light: '#C5CAE9',
					main: '#3F51B5',
					dark: '#303F9F',
				},
				header: {
					backgroundColor: '#3F51B5',
					color: '#fff',
				},
				accent: '#607D8B',
			},
			'Leafy Green': {
				fontFamily: 'Raleway',
				primary: {
					light: '#C8E6C9',
					main: '#4CAF50',
					dark: '#388E3C',
				},
				header: {
					backgroundColor: '#4CAF50',
					color: '#fff',
				},
				accent: '#FF5722',
			},
			'Pink Panther': {
				fontFamily: 'Lato',
				primary: {
					light: '#F8BBD0',
					main: '#E91E63',
					dark: '#C2185B',
				},
				header: {
					backgroundColor: '#E91E63',
					color: '#fff',
				},
				accent: '#CDDC39',
			},
			'Purple Rain': {
				fontFamily: 'Roboto',
				primary: {
					light: '#D1C4E9',
					main: '#673AB7',
					dark: '#512DA8',
				},
				header: {
					backgroundColor: '#673AB7',
					color: '#fff',
				},
				accent: '#607D8B',
			},
			Redtastic: {
				fontFamily: 'Oxygen',
				primary: {
					light: '#FFCDD2',
					main: '#F44336',
					dark: '#D32F2F',
				},
				header: {
					backgroundColor: '#F44336',
					color: '#fff',
				},
				accent: '#607D8B',
			},
		},
	},
	typography: {
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(', '),
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontSize: 13,
		h5: {
			fontWeight: 600,
		},
		// subtitle1: {
		//   fontWeight:600
		// }
	},
	mixins: {
		mainPadding: {
			padding: tempTheme.spacing(3),
			[tempTheme.breakpoints.down('sm')]: {
				padding: tempTheme.spacing(2),
			},
		},
		cancelMainPadding: {
			margin: -tempTheme.spacing(3),
			[tempTheme.breakpoints.down('sm')]: {
				margin: -tempTheme.spacing(2),
			},
		},
		toolbarProminent(cssProp = 'minHeight') {
			return {
				[`${tempTheme.breakpoints.up('xs')} and (orientation: landscape)`]: {
					[cssProp]: 128,
				},
				[tempTheme.breakpoints.up('sm')]: {
					[cssProp]: 128,
				},
				[cssProp]: 128,
			};
		},
		toolbarNormal(cssProp = 'minHeight') {
			return {
				[`${tempTheme.breakpoints.up('xs')} and (orientation: landscape)`]: {
					[cssProp]: 48,
				},
				[tempTheme.breakpoints.up('sm')]: {
					[cssProp]: 64,
				},
				[cssProp]: 56,
			};
		},
	},
	overrides: {
		MuiBadge: {
			badge: {
				// width: 16,
				// height: 16,
				// top:-8,
				// right:-8,
				fontSize: tempTheme.typography.pxToRem(10),
			},
		},
		MuiAppBar: {
			colorDefault: {
				backgroundColor: '#fff',
			},
		},
		MuiMenuItem: {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				[tempTheme.breakpoints.up('sm')]: {
					minHeight: 48,
				},
			},
		},
		MuiButton: {
			// outlinedPrimary: {
			// 	border: '1px solid rgba(0, 0, 0, 0.23)',
			// 	'&:hover': {
			// 		border: '1px solid rgba(0, 0, 0, 0.23)',
			// 	},
			// },
			// outlinedSecondary: {
			// 	border: '1px solid rgba(0, 0, 0, 0.23)',
			// 	'&:hover': {
			// 		border: '1px solid rgba(0, 0, 0, 0.23)',
			// 	},
			// },
		},
		MuiAlert: {
			filledInfo: {
				backgroundColor: '#2B81E5',
			},
		},
	},
});

try {
	window.___DEBUG_THEME = theme;
} catch (e) {}

export default theme;
